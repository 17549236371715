import useUSLDataFetch from "./useUSLDataFetch"

const useSiteDataFetch = () => {
    const { getUslSiteData } = useUSLDataFetch()

    const getSiteData = async (site, date) => {
        switch (site){
            case 'usLins':
                let data = getUslSiteData(date)
                return data
            default:
                return null
        }
    }

    return { getSiteData }
}

export default useSiteDataFetch