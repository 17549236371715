import React, { useState, useContext, useEffect } from 'react'
import MenuBtDivider from '../../components/ui/menuBtDivider';
import GlobalStateContext from '../../components/GlobalStateContext';
import BtCalcularBME from './btCalcularBME';
import PrintAllFlxs from './PrintAllFlxs'
import FileSaveAs from './FileSaveAs';
import FileUploader from './FileUploader';
import FileUploaderOld from './arquivo/FileUploaderOld';
import { Box, Grid2 } from '@mui/material';
// import BtAtingirMeta from './solver/btAtingirMeta';
import BtArquivo from './arquivo/btArquivo';
// import BtRTD from './rtd/btRTD';


function MenuBME() {
  const {area, bmeFileName, site} = useContext(GlobalStateContext)

    return (
        <Box>
            <Box p={1}
                sx={{
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-between', 
                }}>

                <Grid2  gap={3} container>
                    <BtCalcularBME/>
                    <BtArquivo/>
                    {/* <BtAtingirMeta/>
                    <BtRTD/> */}
                    
                </Grid2> 
        
                <Box sx={{fontWeight: 'bold', fontSize: '14px'}}>{bmeFileName}</Box>
            </Box>
        </Box>
       
    )
}

export default MenuBME
