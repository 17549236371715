import { TextField, InputAdornment } from '@mui/material';

export default function InputValueBox({ value, setValue, label, unit, min = null, max = null, disabled = false, increment = 1, width = '230px', limit = false }) {
    return (
            <TextField
                sx={{ width: width}}
                label={label}
                value={value}
                error={(min !== null && value < min) || (max !== null && value > max)}
                helperText={min !== null && value < min ? `Mínimo: ${min}` : max !== null && value > max ? `Máximo: ${max}` : ''}
                onChange={(e) => {
                    setValue(e.target.value)
                    }}
                disabled={disabled}
                type="number"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                    inputProps: { 
                        step: increment,
                        min: limit ? min : null,
                        max: limit ? max : null
                    }
                }}
            />
    )
}