import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import { TbFileImport } from "react-icons/tb";
import useSiteDataFetch from '../../ANALISES/Analise Producao/hooks/useSiteDataFetch';
import { transformData, getMtdValue } from '../../ANALISES/Analise Producao/dataFuncs/transformData';
import { getSiteTable } from '../../ANALISES/Analise Producao/tables/getSiteTable';
import LoadingDialog from '../../components/ui/LoadingDialog';
import SnackMessage from '../../components/ui/new/snackMessage';
import usePsHooks from '../../Hooks/psHooks';
import psCalcsUSL from '../../BME/Unidades/usLins/Plano Safra/psCalcsUSL';
import AnalyticsHooks from '../../Hooks/analyticsHooks';

import * as XLSX from 'xlsx';

export default function BtImportPsReal() {
  const { site, user } = useContext(GlobalStateContext)
  const {isSave, isNew, isSaveAs, isOpen, isEdit, isChart, psData, setPsData} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)
  const { getSiteData } = useSiteDataFetch()
  const [openLoading, setOpenLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [snackMessage, setSnackMessage] = useState({message: '', severity: 'success', open: false})
  const {updatePS} = usePsHooks()
  const {calcPS} = psCalcsUSL()
  const {newAction} = AnalyticsHooks()


  useEffect(() => {
    if(isOpen || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isEdit, isChart])


  async function handleImportPsReal(){
    
    setOpenLoading(true)
    try {
      let meses = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
      let temp = psData
      let year = psData[0].safra.slice(0, 2)
      let lastDays = getLastDaysOfMonths(year)
      console.log('temp', temp, year, lastDays)
      
      for (const date of lastDays) {
        let month = date.split('/')[1]
        let shortMonth = meses[month-1]
        let lastUpdate = date
        // console.log('lastUpdate', lastUpdate, lastDays, temp.filter(item => item.codigo === 'psLastUpdate')[0]['lastUpdate'])
        if (!isDateNewer(lastUpdate, temp.filter(item => item.codigo === 'psLastUpdate')[0]['lastUpdate'])){
          setLoadingMessage(`Carregando dados de ${date} estão atualizados...`)
          continue
        }

        setLoadingMessage(`Carregando dados de ${date}...`)
        let dados = await getSiteData(site, date)
        // console.log('dados', dados)
        
        if (dados.siteData.length === 0 || dados.error || dados.siteData.filter(item => item.mkmnr === 'ARTDIGM')[0]?.valor === 0){
          setLoadingMessage(`${date} não possui dados, carregando dados de ${getPreviousDay(date)}...`)
          dados = await getSiteData(site, getPreviousDay(date))
          lastUpdate = getPreviousDay(date)
        }
        setLoadingMessage('Organizando dados...')
        let transformedData = transformData(site, dados.siteData)
        let siteTable = await getSiteTable(site)
        
        // console.log('transformedData', transformedData, shortMonth)
        setLoadingMessage('Atualizando dados...')
        siteTable.map(item => {
          if (item.psCod){
            let valor = getMtdValue(item.bltCod, transformedData)
            
            let itemTemp = temp?.filter(i => i.codigo === item.psCod)
            
            if (itemTemp.length > 0){
              itemTemp[0][shortMonth] = valor
            }
          }
        })
        temp.filter(item => item.codigo === 'psLastUpdate')[0][shortMonth] = lastUpdate
      }
      let lastUpdate = getLastUpdateDate(temp)
      temp.filter(item => item.codigo === 'psLastUpdate')[0]['lastUpdate']= lastUpdate
      // console.log('temp', temp)
      
      let response = await updatePS(temp)
      setLoadingMessage('Recalculando plano...')
      let calc = await calcPS(response.dados, site)
      newAction('importPSReal', site, user.email || '')
      setPsData(calc)
      setOpenLoading(false)
      setSnackMessage({message: 'Dados atualizados com sucesso', severity: 'success', open: true})
    } catch (error) {
      console.log('error', error)
      setOpenLoading(false)
      setSnackMessage({message: 'Erro ao atualizar dados', severity: 'error', open: true})
    }
  }



    if (!isOpen && !isEdit){
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <MenuBtDivider/>
      <button className='psButtom' onClick={handleImportPsReal}
        disabled={isDisable}>
        <div className='psMenuButtom' >
        <TbFileImport  className='psMenuIcon' />
        <div className='psMenuText'>ATUALIZAR REAL</div></div>
      </button>
      <LoadingDialog open={openLoading} statusMessage={loadingMessage}/>
      <SnackMessage snackBar={snackMessage} setSnackBar={setSnackMessage}/>
    </div>
  )
}
}

function getLastUpdateDate(temp) {
  const meses = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  let dataMaisAtual = null
  
  for (const mes of meses) {
    const dataAtual = temp.filter(item => item.codigo === 'psLastUpdate')[0][mes]
    if (dataAtual) {
      if (!dataMaisAtual || isDateNewer(dataAtual, dataMaisAtual)) {
        dataMaisAtual = dataAtual
      }
    }
  }
  return dataMaisAtual
}

function isDateNewer(date1, date2) {
  const [dia1, mes1, ano1] = date1.split('/')
  const [dia2, mes2, ano2] = date2.split('/')
  
  const data1 = new Date(ano1, mes1 - 1, dia1)
  const data2 = new Date(ano2, mes2 - 1, dia2)
  
  return data1 > data2
}

function getPreviousDay(dateStr) {
  const [dia, mes, ano] = dateStr.split('/')
  const data = new Date(ano, mes - 1, dia)
  data.setDate(data.getDate() - 1)
  
  return data.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
}

function getLastDaysOfMonths(year) {
  const meses = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']
  const diasPorMes = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  const datas = []
  const hoje = new Date()
  
  // Ajusta o ano para o formato correto (20xx)
  const anoCompleto = year.length === 2 ? `20${year}` : year
  
  for (let i = 2; i < 12; i++) {
    const data = new Date(anoCompleto, i, diasPorMes[i])
    
    // Se a data for maior que hoje, para o loop
    if (data > hoje) {
      break
    }
    
    datas.push(data.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }))
  }
  
  return datas
}