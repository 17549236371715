const dados = [
    {secao: 'MATÉRIA PRIMA', name: 'Fibra % Cana', bltCod: 'FIBCNDGD', bmeCod: 'fibraCana', psCod: 'psFibraCana', ipt: true},
    {secao: 'MATÉRIA PRIMA', name: 'Brix % Cana', bltCod: 'BXCNDGD', bmeCod: 'brixCana'},
    {secao: 'MATÉRIA PRIMA', name: 'Pol % Cana', bltCod: 'POLCNDGD', bmeCod: 'polCana'},
    {secao: 'MATÉRIA PRIMA', name: 'Pureza', bltCod: 'PURCNDGD', bmeCod: 'purezaCana', ipt: true},
    // {secao: 'MATÉRIA PRIMA', name: 'AR % Cana', bltCod: '', bmeCod: 'arCana', ipt: true},
    // {secao: 'MATÉRIA PRIMA', name: 'ART % Cana', bltCod: '', bmeCod: 'artCana', psCod: 'psAtrDigestor', ipt: true},			
    // {secao: 'MATÉRIA PRIMA', name: 'ATR', bltCod: '', bmeCod: 'atrCanaPCTS', psCod: 'psAtrPcts'},
    // {secao: 'MATÉRIA PRIMA', name: 'Bagaço % Cana', bltCod: '',  bmeCod: 'bagacoCana', psCod: 'psBagacoCana'},

    {secao: 'UPTIME', name: 'Dias Safra', bltCod: 'DFABACUD', psCod: 'psDiasSafra'},
    // {secao: 'UPTIME', name: 'Uptime Geral', row: 14, col: 0, psCod: 'psUptimeGeral'},
    // {secao: 'UPTIME', name: 'Uptime Caldeiras', row: 25, col: 0, psCod: 'psUpTimeVapor'},

    // {secao: 'MOAGEM', name: 'Cana Moída Moenda A', row: 50, col: 0},
    // {secao: 'MOAGEM', name: 'Moagem Horária Moenda A', row: 52, col: 0, bmeCod: 'flowCanaM1'},
    // {secao: 'MOAGEM', name: 'Cana Moída Moenda B', row: 50, col: 5, n: 4},
    // {secao: 'MOAGEM', name: 'Moagem Horária Moenda B', row: 52, col: 5, n: 4},
    {secao: 'MOAGEM', name: 'Cana Moída', bltCod: 'KGMOIMTD', bmeCod: 'flowCanaDia', psCod: 'psMoagemDiaria'},
    {secao: 'MOAGEM', name: 'Moagem Horária', bltCod: 'KMOIHMTD',  bmeCod: 'flowCana', psCod: 'psTaxaMoagem', ipt: true},
    {secao: 'MOAGEM', name: 'Embebição % Fibra', bltCod: 'EM%FIMTD',  bmeCod: 'fibraEmbeb', psCod: '', ipt: true},
    {secao: 'MOAGEM', name: 'Embebição % Cana', bltCod: 'EM%CNMTD',  bmeCod: 'embCana', psCod: ''},
    {secao: 'MOAGEM', name: 'Eficiência Extração', bltCod: 'EXTATMTD',  bmeCod: 'efExtMoenda', ipt: true},

    {secao: 'MIX PRODUÇÃO', name: 'Açúcar', bltCod: 'MIXACUCD', bmeCod: 'mixAcucar', psCod: 'psMixAcucar'},
    {secao: 'MIX PRODUÇÃO', name: 'Etanol', bltCod: 'MIXETAND', bmeCod: 'mixEtanol', psCod: 'psMixEtanol'},

    // {secao: 'RENDIMENTOS', name: 'Kg Açúcar p/ Ton Cana', row: 14, col: 15, bmeCod: ''},
    // {secao: 'RENDIMENTOS', name: 'Lt Etanol p/ Ton Cana', row: 16, col: 10, bmeCod: ''},
    {secao: 'RENDIMENTOS', name: 'Rendimento Fermentação p/ Subp.', rbltCod: 'RFERSUBD', bmeCod: ''},
    {secao: 'RENDIMENTOS', name: 'Rendimento Fermentação', bltCod: 'RENDFERD', bmeCod: 'efFermentacao', ipt: true},
    {secao: 'RENDIMENTOS', name: 'Rendimento Destilação', bltCod: 'RENDESTD', bmeCod: 'efDestilaria', ipt: true},
    {secao: 'RENDIMENTOS', name: 'Rendimento Geral Destilaria', bltCod: 'REGEDESD', bmeCod: 'rgd', psCod: 'psRGD'},
    {secao: 'RENDIMENTOS', name: 'ART Recuperado Total', bltCod: 'RECUPEDD', bmeCod: 'efIndustrial', psCod: 'psEficienciaIndustrial'},
    {secao: 'RENDIMENTOS', name: 'Global Digestor', bltCod: 'GLODIGED', bmeCod: ''},
    {secao: 'RENDIMENTOS', name: 'Global Prensa', bltCod: 'GLOPREND', bmeCod: ''},
    // {secao: 'RENDIMENTOS', name: 'RTC', row: 33, col: 15, bmeCod: 'rtc', psCod: 'psRTC'},	
    
    {secao: 'PERDAS', name: 'Total', bltCod: 'PERDTOTD', bmeCod: 'perdaTotalArt', psCod: ''},
    {secao: 'PERDAS', name: 'Água Res Moendas', bltCod: 'PERDALCD', bmeCod: 'perdaArtLavagemCana', psCod: 'psPerdaLavavem', ipt: true},
    {secao: 'PERDAS', name: 'Extração', bltCod: 'PEREXTRD', bmeCod: 'perdaArtExtracao', psCod: 'psPerdaBagaco', ipt: true},
    {secao: 'PERDAS', name: 'Torta de Filtro', bltCod: 'ATTORD', bmeCod: 'perdaArtTorta', psCod: 'psPerdaTorta', ipt: true},
    {secao: 'PERDAS', name: 'Saída Evaporação', bltCod: 'PERSAEVD', bmeCod: 'perdaArtSaidaEvap', psCod: 'psPerdaArtSaidaEvap', ipt: true},
    {secao: 'PERDAS', name: 'Saída Vácuo', bltCod: 'PERSAVAD', bmeCod: 'perdaArtSaidaVacuo', psCod: 'psPerdaArtSaidaVacuo', ipt: true},
    {secao: 'PERDAS', name: 'Multijatos Filtros', bltCod: 'PERDAMJD', bmeCod: 'perdaArtMultijatoFabrica', psCod: 'psPerdaMultijato', ipt: true},
    {secao: 'PERDAS', name: 'Água Residual', bltCod: 'PERARESD', bmeCod: 'perdaArtAguaResiduaria', psCod: 'psPerdaResiduaria', ipt: true},
    {secao: 'PERDAS', name: 'Indeterminadas', bltCod: 'PERINDED', bmeCod: 'perdaArtIndeterminda', psCod: 'psPerdaIndeterminada', ipt: true},
    {secao: 'PERDAS', name: 'ART da Destilaria', bltCod: 'ARTDEST', bmeCod: 'perdaFermDest', psCod: 'psPerdaFermentacao', ipt: true},

    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Total Açúcar Branco', row: 88, col: 0, n: 4, bmeCod: '', psCod: 'psProdAcucarCristalSC'},
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar VVHP', row: 89, col: 1, bmeCod: ''},
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar VHP', row: 94, col: 5, bmeCod: '', psCod: 'psProdAcucarVHPSC'},
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Processo', row: 95, col: 0, bmeCod: '' },
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Prod. Total', row: 97, col: 5, bmeCod: 'flowAcucarScsdia', psCod: 'psTaxaEfProdAcucarScs'},
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Prod. + ΔProcesso', row: 0, col: 0, bmeCod: '', psCod: 'psTaxaEfProdAcucarScs'},
    // {secao: 'PRODUÇÃO AÇÚCAR', name: 'Sc.Ton.Moida', row: 99, col: 6, bmeCod: 'flowAcucarScsTc'},

    // {secao: 'PRODUÇÃO ETANOL', name: 'Produção Anidro', row: 74, col: 0, bmeCod: 'flowEtanolAnidroDia', psCod:'psProdEfEtanolAnidro', ipt: true},
    // {secao: 'PRODUÇÃO ETANOL', name: 'Produção Hidratado', row: 75, col: 0, bmeCod: 'flowEtanolHidDia', psCod:'psProdEfEtanolHidpTq'},
    // {secao: 'PRODUÇÃO ETANOL', name: 'Produção Total Etanol', row: 76, col: 0, bmeCod: 'flowEtanolHidAnidro', psCod:'psProdEtanolTotal'},
    // {secao: 'PRODUÇÃO ETANOL', name: 'Prod. Total Etanol + ΔProcesso', row: 0, col: 0, bmeCod: '', psCod: 'psProdEtanolTotal'},
    // {secao: 'PRODUÇÃO ETANOL', name: 'Produção Etanol 100%', row: 86, col: 0, bmeCod: 'flowVolEtanolProd100Dia', psCod:'psProdEfEtanol100'},
    // {secao: 'PRODUÇÃO ETANOL', name: 'Etanol no Processo', row: 91, col: 0, bmeCod: ''},

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Caldo Açúcar', row: 151, col: 0, bmeCod: 'brixCaldoAcucar'},
    // // {secao: 'CALDO AÇÚCAR', name: 'Pol Caldo Açúcar', row: 152, col: 0, bmeCod: ''},
    // // {secao: 'CALDO AÇÚCAR', name: 'Pureza Caldo Açúcar', row: 154, col: 0, bmeCod: ''},

    

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Caldo Claro Açúcar', row: 176, col: 0, bmeCod: 'brixCaldoClarifAcucar'},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pol Caldo Claro Açúcar', row: 178, col: 0, bmeCod: 'polCaldoClarifAcucar'},
    // // {secao: 'CALDO CLARIFICADO AÇÚCAR', name: 'Pureza Caldo Claro Açúcar', row: 178, col: 0, bmeCod: ''},

   

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Xarope', row: 221, col: 5, bmeCod: 'brixXarope', ipt: true},
    // // {secao: 'XAROPE', name: 'Pol Xarope', row: 222, col: 5, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Xarope', row: 223, col: 5, bmeCod: 'purezaXarope', ipt: true},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Xarope Fabrica', row: 225, col: 5, bmeCod: 'flowXaropeToFab'},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Xarope Destilaria', row: 226, col: 5, bmeCod: 'flowXaropeToFerm'},

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Magma', row: 164, col: 10, bmeCod: 'brixMagma'},
    // // {secao: 'MAGMA', name: 'Pol Magma', row: 166, col: 5, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Magma', row: 170, col: 10, bmeCod: 'purezaMagma', ipt: true},
    
    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Massa A', row: 176, col: 10, bmeCod: 'brixMassaA', ipt: true},
    // // {secao: 'MASSA A', name: 'Pol Massa A', row: 178, col: 10, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Massa A', row: 181, col: 10, bmeCod: 'purezaMassaA'},	
    
    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Massa B', row: 186, col: 10, bmeCod: 'brixMassaB', ipt: true},
    // // {secao: 'MASSA B', name: 'Pol Massa B', row: 188, col: 10, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Massa B', row: 190, col: 10, bmeCod: 'purezaMassaB'},
    
    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Mel Rico', row: 192, col: 10, bmeCod: 'brixMelARico', ipt: true},
    // // {secao: 'MEL RICO', name: 'Pol Mel Rico', row: 193, col: 2, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Rico', row: 196, col: 9, bmeCod: 'purezaMelARico', ipt: true},	

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Mel Pobre', row: 199, col: 5, bmeCod: 'brixMAPDiluido', ipt: true},
    // // {secao: 'MEL POBRE', name: 'Pol Mel Pobre', row: 200, col: 5, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Pobre', row: 202, col: 5, bmeCod: 'purezaMelAPobre', ipt: true},	

    // {secao: 'PROCESSO AÇÚCAR', name: 'Brix Mel Final', row: 207, col: 10, bmeCod: 'brixMelFinal', ipt: true},
    // // {secao: 'MEL FINAL', name: 'Pol Mel Final', row: 210, col: 4, bmeCod: ''},
    // {secao: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Final', row: 212, col: 10, bmeCod: 'purezaMelFinal', ipt: true},

    // {secao: 'PROCESSO ETANOL', name: 'Brix Caldo Etanol', row: 150, col: 0, bmeCod: 'brixCaldoEtanol'},
    // // {secao: 'CALDO ETANOL', name: 'Pol Caldo Etanol', row: 152, col: 5, bmeCod: ''},
    // // {secao: 'CALDO ETANOL', name: 'Pureza Caldo Etanol', row: 154, col: 5, bmeCod: ''},

    // {secao: 'PROCESSO ETANOL', name: 'Brix Caldo Claro Etanol', row: 176, col: 5, bmeCod: 'brixCaldoClarifEtanol'},
    // // {secao: 'CALDO CLARIFICADO ETANOL', name: 'Pol Caldo Claro Etanol', row: 178, col: 5, bmeCod: 'polCaldoClarifEtanol'},
    // // {secao: 'PROCESSO ETANOL', name: 'Pureza Caldo Claro Etanol', row: 181, col: 5, bmeCod: ''},

    // {secao: 'PROCESSO ETANOL', name: 'Brix Mosto', row: 269, col: 5, bmeCod: 'brixMosto', ipt: true},
    // // {secao: 'PROCESSO ETANOL', name: 'Pol Mosto', row: 272, col: 0, bmeCod: ''},
    // // {secao: 'PROCESSO ETANOL', name: 'Pureza Mosto', row: 274, col: 5, bmeCod: ''},
    // // {secao: 'FERMENTAÇÃO', name: 'Mel % Mosto', row: 296, col: 0, bmeCod: ''},
    // {secao: 'PROCESSO ETANOL', name: 'Temperatura Mosto', row: 298, col: 5, bmeCod: 'tempMostoResf', ipt: true},
    
    // {secao: 'PROCESSO ETANOL', name: 'Etanol % Vinho', row: 371, col: 0, bmeCod: 'glVinhoBoletim', ipt: true},
    // // {secao: 'VINHO', name: 'Etanol % Levedo', row: 375, col: 5, bmeCod: 'concEtanolVinhoBruto'},

    // {secao: 'ESTOQUE MEL', name: 'Mel Remanesc.', row: 74, col: 10, bmeCod: ''},
    // {secao: 'ESTOQUE MEL', name: 'Mel Produzido', row: 78, col: 0, bmeCod: ''},
    // {secao: 'ESTOQUE MEL', name: 'Mel Consumido', row: 80, col: 10, bmeCod: ''},
    // {secao: 'ESTOQUE MEL', name: 'Mel Estoque Total', row: 0, col: 0, bmeCod: ''},
    // {secao: 'ESTOQUE MEL', name: 'Mel Estoque p/ Produção', row: 0, col: 0, bmeCod: 'flowMelEstoque', ipt: true},
    // {secao: 'ESTOQUE MEL', name: 'Mel Produção p/ Estoque', row: 0, col: 0, bmeCod: 'flowMelpEstoque', ipt: true},
    
    // {secao: 'TEMPERATURAS', name: 'Temp. Água Embebição', row: 240, col: 10, bmeCod: 'tempEmbeb', ipt: true},
    // {secao: 'TEMPERATURAS', name: 'Temp. Caldo Aquecido Etanol', row: 228, col: 0, bmeCod: 'tempCaldoAquecEtanol', ipt: true},
    // {secao: 'TEMPERATURAS', name: 'Temp. Caldo Aquecido Açúcar', row: 233, col: 4, bmeCod: 'tempCaldoAquecAcucar', ipt: true},
    // {secao: 'TEMPERATURAS', name: 'Temp. Caldo Decantado Etanol', row: 226, col: 10, bmeCod: 'tempCaldoDecEtanol', ipt: true},
    // {secao: 'TEMPERATURAS', name: 'Temp. Caldo Decantado Açúcar', row: 243, col: 5, bmeCod: 'tempCaldoDecAcucar', ipt: true},

    // {secao: 'VAPOR E ENERGIA', name: 'Consumo Esp. Vapor', row: 26, col: 0, bmeCod: 'consVaporTC', psCod: 'psConsVaporTc'},
    // {secao: 'VAPOR E ENERGIA', name: 'Consumo Esp. Energia', row: 27, col: 0, bmeCod: 'consEspEnergiaTC', psCod: 'psPtConsumidaTC', ipt: true},

    // {secao: 'BAGAÇO', name: 'Umidade Bagaço', row: 135, col: 10, bmeCod: 'umidBagaco', ipt: true},
    // {secao: 'BAGAÇO', name: 'Brix Bagaço', row: 136, col: 10, bmeCod: ''},
    // {secao: 'BAGAÇO', name: 'Pol % Bagaço', row: 137, col: 10, bmeCod: 'polBagaco'},
    // {secao: 'BAGAÇO', name: 'Fibra % Bagaço', row: 138, col: 10, bmeCod: 'fibraBagaco', ipt: true},
    // {secao: 'BAGAÇO', name: 'Bagaço Produzido', row: 254, col: 0, bmeCod: 'flowBagaco', psCod: 'psProdBagacao'},
    // {secao: 'BAGAÇO', name: 'Bagaço Consumido', row: 80, col: 5, bmeCod: 'consTotalBagaco', psCod: 'psConsBagacao'},
    // {secao: 'BAGAÇO', name: 'Bagaço Cru Vendido', row: 81, col: 5, bmeCod: '', psCod: 'psVendaBagaco'},
    // {secao: 'BAGAÇO', name: 'Bagaço p/ Esoque', row: 83, col: 5, bmeCod: 'sobraBagaco', psCod: 'psSobraBagaco'},

    // {secao: 'TORTA DE FILTRO', name: 'Torta p/Ton.', row: 74, col: 5, bmeCod: 'prodTorta', ipt: true},
    // // {secao: 'TORTA DE FILTRO', name: 'Pol Torta Filtro', row: 232, col: 0, bmeCod: ''},
    // // {secao: 'TORTA DE FILTRO', name: 'Umidade Torta Filtro', row: 236, col: 0, bmeCod: ''},
    // {secao: 'TORTA DE FILTRO', name: 'Torta Produzida', row: 247, col: 0, bmeCod: 'flowTorta'},
							
]

module.exports = {
    dados,
}


// const dados = [
//     {secao: 'EFICIÊNCIAS', name: 'Dias Safra', row: 11, col: 0, psCod: 'psDiasSafra'},
//     {secao: 'EFICIÊNCIAS', name: 'Eficiência Moagem Total', row: 14, col: 0, psCod: 'psUptimeGeral'},
//     {secao: 'EFICIÊNCIAS', name: 'Eficiência Moagem Mecânica', row: 19, col: 0, },
//     {secao: 'EFICIÊNCIAS', name: 'Eficiência Fábrica Açúcar', row: 21, col: 0, },
//     {secao: 'EFICIÊNCIAS', name: 'Eficiência Destilaria', row: 23, col: 0, },
//     {secao: 'EFICIÊNCIAS', name: 'Eficiência Caldeiras', row: 25, col: 0, psCod: 'psUpTimeVapor'},
//     {secao: 'EFICIÊNCIAS', name: 'Consumo Esp. Vapor', row: 26, col: 0, bmeCod: 'consVaporTC', psCod: 'psConsVaporTc'},
//     {secao: 'EFICIÊNCIAS', name: 'Consumo Esp. Energia', row: 27, col: 0, bmeCod: 'consEspEnergiaTC', psCod: 'psPtConsumidaTC'},

//     {secao: 'MATÉRIA PRIMA', name: 'Umidade % Cana', row: 11, col: 5, bmeCod: '', psCod: ''},
//     {secao: 'MATÉRIA PRIMA', name: 'Fibra % Cana', row: 12, col: 5, bmeCod: 'fibraCana', psCod: 'psFibraCana', ipt: true},
//     {secao: 'MATÉRIA PRIMA', name: 'Brix % Cana', row: 13, col: 5, bmeCod: 'brixCana'},
//     {secao: 'MATÉRIA PRIMA', name: 'Pol % Cana', row: 14, col: 5, bmeCod: 'polCana'},
//     {secao: 'MATÉRIA PRIMA', name: 'Pureza MATÉRIA PRIMA', row: 16, col: 5, bmeCod: 'purezaCana', ipt: true},
//     {secao: 'MATÉRIA PRIMA', name: 'AR % Cana', row: 19, col: 5, bmeCod: 'arCana', ipt: true},
//     {secao: 'MATÉRIA PRIMA', name: 'ART % Cana', row: 21, col: 5, bmeCod: 'artCana', psCod: 'psAtrDigestor', ipt: true},			

//     {secao: 'CANA PRENSA', name: 'Pureza Cana Prensa', row: 16, col: 10},
//     {secao: 'CANA PRENSA', name: 'AR % Cana', row: 21, col: 10},
//     {secao: 'CANA PRENSA', name: 'ART % Cana', row: 23, col: 10},
//     {secao: 'CANA PRENSA', name: 'Fibra % Cana', row: 25, col: 10},
//     {secao: 'CANA PRENSA', name: 'ATR Cana Prensa', row: 26, col: 10, bmeCod: 'atrCanaPCTS', psCod: 'psAtrPcts'},

//     {secao: 'MOENDA A', name: 'Cana Moída Moenda A', row: 50, col: 0},
//     {secao: 'MOENDA A', name: 'Moagem Horária Moenda A', row: 52, col: 0, bmeCod: 'flowCanaM1'},

//     {secao: 'MOENDA B', name: 'Cana Moída Moenda B', row: 50, col: 5, n: 4},
//     {secao: 'MOENDA B', name: 'Moagem Horária Moenda B', row: 52, col: 5, n: 4},

//     {secao: 'MOENDA A+B', name: 'Cana Moída', row: 50, col: 9, n: 4, bmeCod: 'flowCanaDia', psCod: 'psMoagemDiaria'},
//     {secao: 'MOENDA A+B', name: 'Moagem Horária', row: 52, col: 9, n: 4,  bmeCod: 'flowCana', psCod: 'psTaxaMoagem', ipt: true},
//     {secao: 'MOENDA A+B', name: 'Embebição % Cana', row: 54, col: 9, n: 4,  bmeCod: 'embCana', psCod: ''},
//     {secao: 'MOENDA A+B', name: 'Embebição % Fibra', row: 56, col: 9, n: 4,  bmeCod: 'fibraEmbeb', psCod: '', ipt: true},
//     {secao: 'MOENDA A+B', name: 'Bagaço % Cana', row: 58, col: 9, n: 4,  bmeCod: 'bagacaoCana', psCod: 'psBagacoCana'},
//     {secao: 'MOENDA A+B', name: 'Extração em %AT', row: 59, col: 9, n: 4,  bmeCod: 'efExtMoenda', psCod: 'psEficExtracao', ipt: true},

//     {secao: 'RENDIMENTOS', name: 'Kg Açúcar p/ Ton', row: 14, col: 15, bmeCod: ''},
//     {secao: 'RENDIMENTOS', name: 'Lt Etanol p/ Ton', row: 16, col: 10, bmeCod: ''},
//     {secao: 'RENDIMENTOS', name: 'Rend.Fer. p/ Subp', row: 19, col: 15, bmeCod: ''},
//     {secao: 'RENDIMENTOS', name: 'Rend. Fermentação', row: 21, col: 15, bmeCod: 'efFermentacao'},
//     {secao: 'RENDIMENTOS', name: 'Rend. Destilação', row: 23, col: 15, bmeCod: 'efDestilaria'},
//     {secao: 'RENDIMENTOS', name: 'Rend. Geral Dest.', row: 25, col: 15, bmeCod: 'rgd'},
//     {secao: 'RENDIMENTOS', name: 'Recuperado Total', row: 27, col: 15, bmeCod: 'efIndustrial'},
//     {secao: 'RENDIMENTOS', name: 'Global Digestor', row: 28, col: 15, bmeCod: ''},
//     {secao: 'RENDIMENTOS', name: 'Global Prensa', row: 30, col: 10, bmeCod: ''},
//     {secao: 'RENDIMENTOS', name: 'RTC', row: 33, col: 15, bmeCod: 'rtc'},	
    
//     {secao: 'PERDAS', name: 'Total', row: 36, col: 4, bmeCod: 'perdaTotalArt'},
//     {secao: 'PERDAS', name: 'Água Res Moendas', row: 41, col: 0, n:4, bmeCod: 'perdaArtLavagemCana', ipt: true},
//     {secao: 'PERDAS', name: 'Extração', row: 43, col: 13, bmeCod: 'perdaArtExtracao', ipt: true},
//     {secao: 'PERDAS', name: 'Torta de Filtro', row: 44, col: 13, bmeCod: 'perdaArtTorta', ipt: true},
//     {secao: 'PERDAS', name: 'Saída Evaporação', row: 46, col: 0, bmeCod: ''},
//     {secao: 'PERDAS', name: 'Saída Vácuo', row: 48, col: 13, bmeCod: ''},
//     {secao: 'PERDAS', name: 'Multijatos Filtros', row: 49, col: 13, bmeCod: 'perdaArtMultijatoFabrica', ipt: true},
//     {secao: 'PERDAS', name: 'Água Residual', row: 50, col: 13, bmeCod: 'perdaArtAguaResiduaria', ipt: true},
//     {secao: 'PERDAS', name: 'Indeterminadas', row: 54, col: 13, bmeCod: 'perdaArtIndeterminda', ipt: true},
//     {secao: 'PERDAS', name: 'ART da Destilaria', row: 56, col: 13, bmeCod: 'perdaFermDest', ipt: true},

//     {secao: 'MIX PRODUÇÃO', name: 'Açúcar', row: 34, col: 0, bmeCod: 'mixAcucar'},
//     {secao: 'MIX PRODUÇÃO', name: 'Etanol', row: 35, col: 0, bmeCod: 'mixEtanol'},

//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Total Açúcar Branco', row: 88, col: 0, n: 4, bmeCod: ''},
//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar VVHP', row: 89, col: 1, bmeCod: ''},
//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar VHP', row: 94, col: 5, bmeCod: ''},
//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Processo', row: 95, col: 0, bmeCod: ''},
//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Prod. Total', row: 97, col: 5, bmeCod: 'flowAcucarScsdia'},
//     {secao: 'PRODUÇÃO AÇÚCAR', name: 'Sc.Ton.Moida', row: 99, col: 6, bmeCod: 'flowAcucarScsTc'},

//     {secao: 'PRODUÇÃO ETANOL', name: 'Anidro ANP', row: 74, col: 0, bmeCod: 'flowEtanolAnidroDia'},
//     {secao: 'PRODUÇÃO ETANOL', name: 'Hidratado', row: 75, col: 0, bmeCod: 'flowEtanolHidDia'},
//     {secao: 'PRODUÇÃO ETANOL', name: 'Total Etanol', row: 76, col: 0, bmeCod: 'flowEtanolHidAnidro'},
//     {secao: 'PRODUÇÃO ETANOL', name: 'Etanol Prod.100%', row: 86, col: 0, bmeCod: 'flowVolEtanolProd100Dia'},
//     {secao: 'PRODUÇÃO ETANOL', name: 'Etanol no Processo', row: 91, col: 0, bmeCod: ''},

//     {secao: 'INDICADORES', name: 'Torta p/Ton.', row: 74, col: 5, bmeCod: 'prodTorta'},
//     {secao: 'INDICADORES', name: 'Bagaço Consumido', row: 80, col: 5, bmeCod: ''},
//     {secao: 'INDICADORES', name: 'Bagaço Cru Vendido', row: 81, col: 5, bmeCod: ''},
//     {secao: 'INDICADORES', name: 'Estoque Bagaço', row: 83, col: 5, bmeCod: ''},

//     {secao: 'PRODUÇÃO MEL', name: 'Mel Remanesc.', row: 74, col: 10, bmeCod: ''},
//     {secao: 'PRODUÇÃO MEL', name: 'Mel Produzido', row: 78, col: 0, bmeCod: ''},
//     {secao: 'PRODUÇÃO MEL', name: 'Mel Consumido', row: 80, col: 10, bmeCod: ''},
//     {secao: 'PRODUÇÃO MEL', name: 'Mel Estoque Total', row: 81, col: 10, bmeCod: ''},

//     {secao: 'BAGAÇO', name: 'Umidade', row: 135, col: 10, bmeCod: 'umidBagaco', ipt: true},
//     {secao: 'BAGAÇO', name: 'Brix Bagaço', row: 136, col: 10, bmeCod: ''},
//     {secao: 'BAGAÇO', name: 'Pol % Bagaço', row: 137, col: 10, bmeCod: 'polBagaco'},
//     {secao: 'BAGAÇO', name: 'Fibra % Bagaço', row: 138, col: 10, bmeCod: 'fibraBagaco'},
//     {secao: 'BAGAÇO', name: 'Bagaço Produzido', row: 254, col: 0, bmeCod: 'flowBagaco'},

//     {secao: 'CALDO AÇÚCAR', name: 'Brix Caldo Açúcar', row: 151, col: 0, bmeCod: 'brixCaldoAcucar'},
//     {secao: 'CALDO AÇÚCAR', name: 'Pol Caldo Açúcar', row: 152, col: 0, bmeCod: ''},
//     {secao: 'CALDO AÇÚCAR', name: 'Pureza Caldo Açúcar', row: 154, col: 0, bmeCod: ''},

//     {secao: 'CALDO ETANOL', name: 'Brix Caldo Etanol', row: 150, col: 0, bmeCod: 'brixCaldoEtanol'},
//     {secao: 'CALDO ETANOL', name: 'Pol Caldo Etanol', row: 152, col: 5, bmeCod: ''},
//     {secao: 'CALDO ETANOL', name: 'Pureza Caldo Etanol', row: 154, col: 5, bmeCod: ''},

//     {secao: 'CALDO CLARIFICADO AÇÚCAR', name: 'Brix Caldo Claro Açúcar', row: 176, col: 0, bmeCod: 'brixCaldoClarifAcucar'},
//     {secao: 'CALDO CLARIFICADO AÇÚCAR', name: 'Pol Caldo Claro Açúcar', row: 178, col: 0, bmeCod: 'polCaldoClarifAcucar'},
//     {secao: 'CALDO CLARIFICADO AÇÚCAR', name: 'Pureza Caldo Claro Açúcar', row: 178, col: 0, bmeCod: ''},

//     {secao: 'CALDO CLARIFICADO ETANOL', name: 'Brix Caldo Claro Etanol', row: 176, col: 5, bmeCod: 'brixCaldoClarifEtanol'},
//     {secao: 'CALDO CLARIFICADO ETANOL', name: 'Pol Caldo Claro Etanol', row: 178, col: 5, bmeCod: 'polCaldoClarifEtanol'},
//     {secao: 'CALDO CLARIFICADO ETANOL', name: 'Pureza Caldo Claro Etanol', row: 181, col: 5, bmeCod: ''},

//     {secao: 'TORTA DE FILTRO', name: 'Pol Torta Filtro', row: 232, col: 0, bmeCod: ''},
//     {secao: 'TORTA DE FILTRO', name: 'Umidade Torta Filtro', row: 236, col: 0, bmeCod: ''},
//     {secao: 'TORTA DE FILTRO', name: 'Torta Produzida', row: 247, col: 0, bmeCod: 'flowTorta'},

//     {secao: 'XAROPE', name: 'Brix Xarope', row: 221, col: 5, bmeCod: 'brixXarope', ipt: true},
//     {secao: 'XAROPE', name: 'Pol Xarope', row: 222, col: 5, bmeCod: ''},
//     {secao: 'XAROPE', name: 'Pureza Xarope', row: 223, col: 5, bmeCod: 'purezaXarope', ipt: true},
//     {secao: 'XAROPE', name: 'Xarope Fabrica', row: 225, col: 5, bmeCod: 'flowXaropeToFab'},
//     {secao: 'XAROPE', name: 'Xarope Destilaria', row: 226, col: 5, bmeCod: 'flowXaropeToFerm'},

//     {secao: 'MAGMA', name: 'Brix Magma', row: 164, col: 10, bmeCod: 'brixMagma'},
//     {secao: 'MAGMA', name: 'Pol Magma', row: 166, col: 5, bmeCod: ''},
//     {secao: 'MAGMA', name: 'Pureza Magma', row: 170, col: 10, bmeCod: ''},
    
//     {secao: 'MASSA A', name: 'Brix Massa A', row: 176, col: 10, bmeCod: 'brixMassaA'},
//     {secao: 'MASSA A', name: 'Pol Massa A', row: 178, col: 10, bmeCod: ''},
//     {secao: 'MASSA A', name: 'Pureza Massa A', row: 181, col: 10, bmeCod: 'purezaMassaA'},	
    
//     {secao: 'MASSA B', name: 'Brix Massa B', row: 186, col: 10, bmeCod: 'brixMassaB'},
//     {secao: 'MASSA B', name: 'Pol Massa B', row: 188, col: 10, bmeCod: ''},
//     {secao: 'MASSA B', name: 'Pureza Massa B', row: 190, col: 10, bmeCod: 'purezaMassaB'},
    
//     {secao: 'MEL RICO', name: 'Brix Mel Rico', row: 192, col: 10, bmeCod: 'brixMelARico'},
//     {secao: 'MEL RICO', name: 'Pol Mel Rico', row: 193, col: 2, bmeCod: ''},
//     {secao: 'MEL RICO', name: 'Pureza Mel Rico', row: 196, col: 9, bmeCod: 'purezaMelARico'},	

//     {secao: 'MEL POBRE', name: 'Brix Mel Pobre', row: 199, col: 5, bmeCod: 'brixMAPDiluido'},
//     {secao: 'MEL POBRE', name: 'Pol Mel Pobre', row: 200, col: 5, bmeCod: ''},
//     {secao: 'MEL POBRE', name: 'Pureza Mel Pobre', row: 202, col: 5, bmeCod: 'purezaMAPDiluido'},	

//     {secao: 'MEL FINAL', name: 'Brix Mel Final', row: 207, col: 10, bmeCod: 'brixMelFinal2'},
//     {secao: 'MEL FINAL', name: 'Pol Mel Final', row: 210, col: 4, bmeCod: ''},
//     {secao: 'MEL FINAL', name: 'Pureza Mel Final', row: 212, col: 10, bmeCod: 'purezaMelFinal2'},
    
//     {secao: 'TEMPERATURAS', name: 'Temp.Saida Deca', row: 226, col: 10, bmeCod: 'tempCaldoDecEtanol'},
//     {secao: 'TEMPERATURAS', name: 'Temp.Aque.Des.Med', row: 228, col: 0, bmeCod: 'tempCaldoAquecEtanol'},
//     {secao: 'TEMPERATURAS', name: 'Temp.Aque.Fab.Med', row: 233, col: 4, bmeCod: 'tempCaldoAquecAcucar'},
//     {secao: 'TEMPERATURAS', name: 'Temp.Água Embe', row: 240, col: 10, bmeCod: 'tempEmbeb'},
//     {secao: 'TEMPERATURAS', name: 'Temp.Decant.Açúca', row: 243, col: 5, bmeCod: 'tempCaldoDecAcucar'},
    
//     {secao: 'FERMENTAÇÃO', name: 'Brix Mosto', row: 269, col: 5, bmeCod: 'brixMosto'},
//     {secao: 'FERMENTAÇÃO', name: 'Pol Mosto', row: 272, col: 0, bmeCod: ''},
//     {secao: 'FERMENTAÇÃO', name: 'Pureza Mosto', row: 274, col: 5, bmeCod: ''},
//     {secao: 'FERMENTAÇÃO', name: 'Mel % Mosto', row: 296, col: 0, bmeCod: ''},
//     {secao: 'FERMENTAÇÃO', name: 'Temperatura Mosto', row: 298, col: 5, bmeCod: 'tempMostoResf'},
    
//     {secao: 'VINHO', name: 'Etanol % Vinho', row: 371, col: 0, bmeCod: 'concCelVinhoCent'},
//     {secao: 'VINHO', name: 'Etanol % Levedo', row: 375, col: 5, bmeCod: 'concEtanolVinhoBruto'},
							
// ]

