import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Grid2, Tabs, Tab, Slide, Typography, IconButton, InputAdornment } from '@mui/material';
import { UseColors } from '../../../../components/ui/colors';
import PSContext from '../../../../PLANO SAFRA/opoio/PSContext';
import { listaRevs2 } from '../../../../PLANO SAFRA/listasPlanoSafra';
import PsHooks from '../../../../Hooks/psHooks';
import GlobalStateContext from '../../../../components/GlobalStateContext';
import AnalisesContext from '../../../../ANALISES/AnalisesContext';
import PrevXReal from './PrevXReal';
import BmeAnalisys from './BmeAnalisys';
import LoadingDialog from '../../../../components/ui/LoadingDialog';
import AnalyticsHooks from '../../../../Hooks/analyticsHooks';
import Boletim from './Boletins';
import { setBmeValuesFromReal, setBmeBoletimValuesFromReal, getDayProdValue, getDayAnalValue, convertDateToISO } from './funcs';
import BmeHooks from '../../../../Hooks/bmeHooks';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BmeBoletimAnalisys from './bmeBoletimAnalisys';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

export default function AnaliseProducao() {
  
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Iniciando...");
  const [tokenInfo, setTokenInfo] = useState({ token: null, expiry: null });
  const [tabIndex, setTabIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [mes, setMes] = useState(null);
  const {site, user, data} = useContext(GlobalStateContext)
  const {bltData, setBltData, bltProd, setBltProd, bltAna, setBltAna, tempPsData, setTempPsData, tolerancia, setTolerancia, showAllBmeBoletim, setShowAllBmeBoletim} = useContext(AnalisesContext)
  const [selectedDate, setSelectedDate] = useState(convertDateToISO(bltData) || null);
  const [crtPsr, setCrtPsr] = useState(null);
  const {openPS} = PsHooks()
  const {newAction} = AnalyticsHooks()
  const {calcBME, atingirMetaflowAcucarScsdiaPro} = BmeHooks()



    async function ImportPsr(){
      setLoading(true);
      setStatusMessage("Importando Plano Safra...");
      const formattedDate = new Date(selectedDate).toLocaleDateString("pt-BR");
      const meses = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
      const mesAbreviado = meses[new Date(formattedDate).getMonth()];
      setMes(mesAbreviado.toLowerCase())
      const rev = listaRevs2.find(item => item.includes(`_${mesAbreviado}`));
      let safra = `${new Date(formattedDate).getFullYear() - 2001}/${new Date(formattedDate).getFullYear() - 2000}`
      if (new Date(formattedDate).getMonth() > 7){
          safra = `${new Date(formattedDate).getFullYear() - 2000}/${new Date(formattedDate).getFullYear() - 1999}`
      }
      const ssr = `${site}-${safra}-${rev}`;
      setCrtPsr(`${safra} - ${rev}`)
      try {
        let tempPs = await openPS(ssr);
        setTempPsData(tempPs)
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
        setStatusMessage(`Erro ao buscar os dados: ${error}`);
      }
    };
      

      const handleTabChange = (_, newIndex) => {
        setPrevIndex(tabIndex); // Armazena o índice anterior
        setTabIndex(newIndex);
      };

      const getToken = async () => {
        if (tokenInfo.token && tokenInfo.expiry > Date.now()) {
          return tokenInfo.token;
        }

        try {
          setStatusMessage("Gerando Token...");
          const authResponse = await axios.get('https://app.grupojb.com.br/cs0241p/agricola/api/auth', {
              headers: {
                'username': 'PMSAPI',
                'password': 'IntegracaoPMS2025#'
              }
            });

          const newToken = authResponse.data.auth[0].access_token;
          const expiryTime = Date.now() + authResponse.data.auth[0].expires_in * 1000; // 1 hora (3600 segundos)

          setTokenInfo({ token: newToken, expiry: expiryTime });

          return newToken;
        } catch (error) {
          console.error('Erro ao obter token:', error);
          throw error;
        }
      };

  const handleSubmit = async () => {
    newAction('gerarAnalise', site, user.email)
    setLoading(true);
    setStatusMessage("Iniciando...");
    setBltProd(null)
    setBltAna(null)
    setCrtPsr(null)

    try {
      await ImportPsr()
      const token = await getToken();
      const dateObj = new Date(selectedDate);
      const formattedDate = dateObj.toLocaleDateString('pt-BR'); // Formato "DD/MM/YYYY"

      // Criando os dois request bodies
      const requestBody1 = {"sql": { "id": 1214 },
        "params": [
          { "name": "pn_relatorio", "value": 20 },
          { "name": "pn_safra", "value": 20 },
          { "name": "pd_data", "value": formattedDate }]
      };

      const requestBody2 = { "sql": { "id": 1214 },
        "params": [
          { "name": "pn_relatorio", "value": 21 },
          { "name": "pn_safra", "value": 21 },
          { "name": "pd_data", "value": formattedDate }]
      };

    setStatusMessage("Importando Boletins...");
      const [dataResponse1, dataResponse2] = await Promise.all([
        axios.post('https://app.grupojb.com.br/cs0241p/agricola/api/query', requestBody1, {
        headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' }
      }),
        axios.post('https://app.grupojb.com.br/cs0241p/agricola/api/query', requestBody2, {
        headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' }
      })
    ]);

      setBltData(formattedDate)
      setBltProd(dataResponse1.data.dataset);
      setBltAna(dataResponse2.data.dataset);
      setStatusMessage("Atualizando BME...");
      await setBmeBoletimValuesFromReal(dataResponse1.data.dataset, dataResponse2.data.dataset, data)
      await calcBME()
      let metaFlowAcucarScsdiaPro = getDayProdValue(3510, bltProd) + getDayProdValue(4010, bltProd) + getDayProdValue(4510, bltProd)
      let metaBrixCaldoFiltrado = getDayAnalValue(2170, bltAna)
      console.log(metaFlowAcucarScsdiaPro, metaBrixCaldoFiltrado)
      await atingirMetaflowAcucarScsdiaPro(metaFlowAcucarScsdiaPro, metaBrixCaldoFiltrado)
    } catch (error) {
      console.error('Erro ao chamar API:', error);
      setStatusMessage(`Erro ao chamar API: ${error}`);
      setBltProd(null);
      setBltAna(null);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = async () => {
    console.log(bltProd, bltAna)
  }

  const handleToleranciaChange = (event) => {
    const value = parseFloat(event.target.value)
    if (!isNaN(value) && value >= 0 && value <= 10) {
      setTolerancia(value)
    }
  }

  const incrementTolerancia = () => {
    setTolerancia(prev => Math.min(prev + 0.1, 10))
  }

  const decrementTolerancia = () => {
    setTolerancia(prev => Math.max(prev - 0.1, 0))
  }

  return (
    <Box p={2} width="100%" mx="auto" height="90%" display="flex" flexDirection="column" padding={0} paddingTop={0.5}>
      <Grid2 container spacing={2} alignItems="center" justifyContent="center"  >
        <Box display="flex" flexDirection="row" justifyContent="space-between" sx={{width: '100%', paddingLeft: 1, paddingRight: 1, }}>
          <Box display="flex" gap={2} alignItems="center" justifyContent="center" height="50px">
             <TextField sx={{minWidth: 200}} label="Selecione uma data" type="date"
                value={selectedDate} onChange={e  => setSelectedDate(e.target.value)} 
                InputLabelProps={{ shrink: true }}/>
            <Button variant="contained" color="primary" onClick={handleSubmit} 
              disabled={loading}>Gerar Análise
            </Button>
           {tabIndex === 0 && 
            <TextField
            label="Tolerância"
            value={tolerancia?.toFixed(1) || 2}
            onChange={handleToleranciaChange}
            size="small"
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                startAdornment: (
                    <InputAdornment position="start">
                        <IconButton 
                            onClick={decrementTolerancia}
                            size="small"
                            disabled={tolerancia <= 0}
                        >
                            <RemoveIcon fontSize="small" />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end">
                       %
                        <IconButton 
                            onClick={incrementTolerancia}
                            size="small"
                            disabled={tolerancia >= 10}
                        >
                            <AddIcon fontSize="small" />
                        </IconButton>
                       
                    </InputAdornment>
                ),
                inputProps: {
                    style: { 
                        textAlign: 'center',
                        width: '30px'
                    },
                    min: 0,
                    max: 10,
                    step: 0.1
                }
            }}
        />}
        {tabIndex === 0 && 
          <IconButton 
            onClick={() => setShowAllBmeBoletim(!showAllBmeBoletim)}
            color={showAllBmeBoletim ? "default" : "primary"}
            sx={{ 
              backgroundColor: showAllBmeBoletim ? 'transparent' : 'rgba(25, 118, 210, 0.08)',
              '&:hover': {
                backgroundColor: showAllBmeBoletim ? 'rgba(0, 0, 0, 0.04)' : 'rgba(25, 118, 210, 0.12)'
              }
            }}
          >
            {showAllBmeBoletim ? <FilterListOffIcon /> : <FilterListIcon />}
          </IconButton>
        }
          </Box>
          <Box display="flex" gap={2} alignItems="center" justifyContent="center" height="50px">
            {/* <Button variant="contained" color="primary" onClick={handlePrint} 
              disabled={loading || !bltData}>PRINT
            </Button> */}
            <Typography variant="h6" color="primary">
              {bltData}
            </Typography>
          </Box>
          
        </Box>
      </Grid2>
  
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh"}}>
      {/* Tabs para alternar entre as views */}
      <Tabs value={tabIndex}  onChange={handleTabChange} centered
      >
        <Tab label="ANÁLISE BME BOLETIM"/>
        {/* <Tab label="ANÁLISE BME"/> */}
        <Tab label="PLANO x REAL"/>
        
        <Tab label="BOLETINS"/>
      </Tabs>

      
        <>
          {/* Container fixo para evitar mudança brusca */}
          <Box mt={0} p={0} sx={{ minHeight: 100, overflow: "hidden", position: "relative"}}>
      {/* Criar animação de saída e entrada suave */}
      <Slide direction={tabIndex > prevIndex ? "left" : "right"} in={true} 
        timeout={500} mountOnEnter unmountOnExit key={tabIndex}>
        <Box>
          {tabIndex === 0 &&  <BmeBoletimAnalisys/> }
          {/* {tabIndex === 1 &&  <BmeAnalisys/> } */}
          {tabIndex === 1 &&  <PrevXReal/>}
          {tabIndex === 2 &&  <Boletim/>}
        </Box>
      </Slide>
    </Box>
    </>
   
    </Box>
    <LoadingDialog open={loading} statusMessage={statusMessage} />
    </Box>
  );
  
}






