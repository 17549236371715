import GlobalStateContext from '../../../components/GlobalStateContext';
import { Snackbar, Alert, MenuItem} from '@mui/material';
import { useContext, useState } from 'react';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import { getDefData } from '../../Unidades/Geral/getDefData';
import DataObjectIcon from '@mui/icons-material/DataObject';

const SetDefault = ({handleClose}) => {
  const {setData, user, site, setBmeFileName} = useContext(GlobalStateContext); 
  const {newAction} = AnalyticsHooks()
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');

  async function handleSetDefault(){
      newAction('setDefault', site, user.email || '')
      const defData = await getDefData(site)
      console.log('Def Data', defData)
      setData(defData)
      setBmeFileName('BME Default')
      
      // Primeiro mostra o Snackbar
      setSnackBar()
      // Depois fecha o menu
      setTimeout(() => {
        handleClose()
      }, 1000)
  }

  function setSnackBar(){
    setSnackMessage('BME Default definido com sucesso!')
    setSnackSeverity('success')
    setOpenSnackBar(true)
  }

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
     <MenuItem onClick={handleSetDefault} disableRipple sx={{width: '100%'}}>
          <DataObjectIcon />
          Set Default
        </MenuItem>
     
      <Snackbar 
        open={openSnackBar} 
        autoHideDuration={2000} 
        onClose={handleCloseSnackBar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity={snackSeverity} variant='filled'>{snackMessage}</Alert>
      </Snackbar>
    </>
  )
}

export default SetDefault;

