import { useContext } from 'react'
import { getStdPlanoSafra } from "../BME/Unidades/Geral/getStdPlanoSafra";
import GlobalStateContext from "../components/GlobalStateContext";
import useJBAHooks from '../BME/Unidades/usJbAlcoolquimica/realDataHooks'

const usePsHooks = () => {
  const {site, user} = useContext(GlobalStateContext)
  const {updateRealUsJbAlcoolquimica} = useJBAHooks()

    const newPS = async (ps, userEmail) => {
      try{
          const response  = await fetch(`${global.endPoint}/newps?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(ps)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const resumeAllPS = async (site) => {
        try{
            const response = await fetch(`${global.endPoint}/getallps?siteId=${site}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                },
              })
                if (!response.ok) {
                    throw new Error('Erro ao buscar dados da API')
                }
                let impData = await response.json();
                // console.log(impData)
                let tempData = {}
                impData.forEach(item => {
                    if (!tempData[item.safra]) {
                        tempData[item.safra] = {}
                    }
                    if (!tempData[item.safra][item.revisao]) {
                        tempData[item.safra][item.revisao] = {}
                    }
                    tempData[item.safra][item.revisao]['atualizadoEm'] = item.atualizadoEm
                    tempData[item.safra][item.revisao]['criadoEm'] = item.criadoEm
                    tempData[item.safra][item.revisao]['userEmail'] = item.userEmail
                })
                // console.log('All PS', tempData)
                return tempData
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
                return null
            }
    }

    const openPS = async (ssr) => {
      try{
          const response  = await fetch(`${global.endPoint}/getps?ssr=${ssr}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const updatePS = async (ps, userEmail = '') => {
        // console.log('Update PS', ps)
      try{
          const response  = await fetch(`${global.endPoint}/updateps?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(ps)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const deletePS = async (ssr) => {
        try{
            const response  = await fetch(`${global.endPoint}/delps?ssr=${ssr}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              }
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }else{
                console.log('Ps Deletado')
            }
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            }
      }


    const updateFullPS = async (fullPs, userEmail = '') => {
        console.log('Update PS', fullPs)
      try{
          const response  = await fetch(`${global.endPoint}/updateFullPs?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(fullPs)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

    const openFullPS = async (ssr) => {
        try{
            const response  = await fetch(`${global.endPoint}/getFullPs?ssr=${ssr}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }
            const jsonData = await response.json();
            return jsonData
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            return null
            }
      }

      async function checkIfRealExist() {
        console.time("Tempo de execução"); // Inicia o contador
        // Se não existe, cria e atualiza
        let resumePs = await resumeAllPS(site);
        
    
        console.log('Check Dates TRUE - Atualizando...');
    
        let psData = await getAllPS(site);
        let safras = Object.keys(resumePs).filter(safra => safra !== 'Estudo')  ;
        let update = false;
    
        for (const safra of safras) {
            console.log('Safra', safra)
            let psReal = '13_Realizado';
            let data = psData?.filter(item => item.safra === safra && item.revisao === psReal);
    
            if (data.length === 0) {
                let nPs = await criarPS(safra, psReal);
                update = true;
                await updateReal(safra)
                // await updtReal(psReal, safra, nPs);
            } else {
                await updateReal(safra)
                // await updtReal(psReal, safra, data);
            }
        }
    
        if (update) {
            resumePs = await resumeAllPS(site);
        }
    
        // Atualiza o horário de atualização apenas se houve alteração
        localStorage.setItem('realUpdate', new Date().toISOString());
        console.timeEnd("Tempo de execução");
       
       
      return resumePs;
    }

    async function updateReal(safra
    ){
      switch(site){
        case 'usJbAlcoolquimica':
          await updateRealUsJbAlcoolquimica(safra)
        default:
      }
    }

  //   async function updateRealUsJbAlcoolquimica(safra){
  //     let psReal = await openPS(`${site}-${safra}-13_Realizado`)
  //     let months = ['set', 'out', 'nov', 'dez', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago']
  //     for (const month in months) {
  //       let prevMonth = isPreviousMonth(safra, months[month])
  //       if (prevMonth.update && months[month] === 'fev'){
  //         console.log('Atualizar', months[month], prevMonth.lastDay)
  //         let blts = await getBltsJBA(prevMonth.lastDay)
  //         console.log('PSREAL', psReal)

  //       }
  //     }
  //     // console.log('PSREAL', safra, psReal)
  //   }

  //   function isPreviousMonth(safra, mes) {
  //     const meses = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
      
  //     // Obtém o índice do mês informado
  //     const mesIndex = meses.indexOf(mes);
  //     if (mesIndex === -1) {
  //         throw new Error('Mês inválido');
  //     }
  
  //     // Obtém os anos da safra
  //     const [anoInicio, anoFim] = safra.split('/').map(s => `20${s}`);
  
  //     // Determina o ano do mês informado
  //     const anoReferencia = mesIndex < 8 ? parseInt(anoFim) : parseInt(anoInicio);
  //     // Obtém o último dia do mês informado
  //     const ultimoDia = new Date(anoReferencia, mesIndex + 1, 0);
  
  //     // Obtém a data atual
  //     const hoje = new Date();
  //     const anoAtual = hoje.getFullYear();
  //     const mesAtual = hoje.getMonth(); // Janeiro é 0, Fevereiro é 1...
  
  //     // Cria um objeto Date para a data da safra informada
  //     const dataInformada = new Date(anoReferencia, mesIndex, 1);
  //     // console.log('DATAS', ultimoDia, hoje, ultimoDia < hoje)
  
  //     // Compara as datas
  //     return {update: ultimoDia < hoje, lastDay: ultimoDia}
  // }
  
  

    const getAllPS = async (site) => {
      try{
          const response = await fetch(`${global.endPoint}/getallps?siteId=${site}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              },
            })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API')
          }
          let impData = await response.json();
          return impData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
              return null
          }
  }

  async function criarPS(safra, rev){
    const stdPlanoVars = getStdPlanoSafra(site)
    let ps = []
    // let rev = selectedOption === 'opcao1' ? `PSR${selectedSafra.split('/')[0]}.${revName}` : revName
        for (const item of stdPlanoVars){
            // if (item.show !== false){
                let newItem = {
                    ssr: `${site}-${safra}-${rev}`,
                    siteId: site, 
                    safra: safra, 
                    revisao: rev, 
                    secao: item.grp, 
                    descricao: item.desc,
                    codigo: item.cod, 
                    unidade: item.unid,
                    jan: 0.0,
                    fev: 0.0,
                    mar: 0.0,
                    abr: 0.0,
                    mai: 0.0,
                    jun: 0.0,
                    jul: 0.0,
                    ago: 0.0,
                    set: 0.0,
                    out: 0.0,
                    nov: 0.0,
                    dez: 0.0,
                    acu: 0.0, 
                    jan2: 0.0,
                    fev2: 0.0,
                    mar2: 0.0,
                    acu2: 0.0, 
                    input: !item.ipt ? null : item.ipt}
                    ps.push(newItem)
            // }
        }
        // console.log('User', user.email)
        let nps = await newPS(ps, user.email)
        if (nps.existe){
            return null
        }else{
            return nps                
        }
        
    }
  
    return {newPS, resumeAllPS, openPS, updatePS,  deletePS, updateFullPS, openFullPS, checkIfRealExist};
  };

  export default usePsHooks;

