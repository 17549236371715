import React, { useContext } from 'react'
import LogoPMS from '../../assets/imgs/pmsLogo.png'
import LogoMasm from '../../assets/imgs/masmLogo.png'
import GlobalStateContext from '../GlobalStateContext'
import { getLogo } from '../../BME/Unidades/Geral/getLogo'


const Headlandscape = ({title, subtitle, fileName, page, pages, nomeField = 'Nome'}) => {
    const {bmeFileName, user, site } = useContext(GlobalStateContext)
    const vh = window.innerHeight
    const ideal = 727
    let nVH = ideal/vh

    const listWithoutMasm = ['usTeste1', 'usTeste2', 'usOuroeste']

    return (
        <div style={{height: '100px', width: '100%', borderBottom: '1px solid black', position: 'relative', color: 'black'}}>
            <div style={{display: 'flex', direction: 'row', justifyContent: 'center', alignItems: 'center', height: '65px', borderBottom: '1px solid black', boxSizing: 'border-box'}}>
                <div style={{
                    height: '100%', 
                    width: '25%',  
                    minWidth: '25%',  
                    borderRight: '1px solid black', 
                    boxSizing: 'border-box',
                    display: 'flex',          // Adiciona flexbox
                    justifyContent: 'center', // Centraliza horizontalmente
                    alignItems: 'center'      // Centraliza verticalmente (opcional)
                }}>
                     <img 
                        style={{
                            height: '90%', 
                            padding: '2px'
                        }} 
                        src={getLogo(site)} 
                        alt="" 
                    />
                </div>
                <div style={{width: '50%', boxSizing: 'border-box'}}>
                <div style={{fontWeight: 'bold', fontSize: '18px', alignContent: 'center', textAlign: 'center'}}>{title}</div>
                {subtitle && 
                <div style={{fontSize: '14px', alignContent: 'center', textAlign: 'center'}}>{subtitle}</div>}
                </div>
                
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '5px', width: '25%', height: '100%', borderLeft: '1px solid black', alignContent: 'center', boxSizing: 'border-box'}}>
                    <div style={{width: '10px'}}></div>
                    <img style={{height: '50%'}} src={LogoMasm} alt="" />
                    <div style={{width: '10px'}}></div>
                    <img style={{height: '70%'}} src={LogoPMS} alt="" />
                    <div style={{width: '10px'}}></div>
                   
                </div>
                
                </div>
            <div style={{display: 'flex', direction: 'row',  height: '35px', boxSizing: 'border-box'}}>
                <div style={{height: '100%', width: '25%', minWidth: '25%', alignContent: 'center', textAlign: 'left', paddingLeft: '3px', borderRight: '1px solid black', fontSize: '14px', boxSizing: 'border-box' }}>
                    {nomeField}{nomeField ? ':' : ''} {fileName}
                </div>
                <div style={{height: '100%', width: '25%', alignContent: 'center', textAlign: 'left', paddingLeft: '3px', borderRight: '1px solid black', fontSize: '14px', boxSizing: 'border-box' }}>
                    Unidade: {user.sites[site].name}
                </div>
                <div style={{height: '100%', width: 'calc(25% + 2px)', alignContent: 'center', textAlign: 'left', paddingLeft: '3px', borderRight: '1px solid black', fontSize: '14px', boxSizing: 'border-box'}}>
                    Usuário: {user.userName}
                </div>
                <div style={{height: '100%', width: '16.5%', alignContent: 'center', textAlign: 'left', paddingLeft: '3px', fontSize: '14px', boxSizing: 'border-box'}}>
                    Data: {getDate()}
                </div>
                <div style={{height: '100%', width: '8.5%', alignContent: 'center', textAlign: 'left', paddingLeft: '3px', borderLeft: '1px solid black', fontSize: '14px', boxSizing: 'border-box'}}>
                    Página: {page}/{pages}
                </div>
            </div>
         </div>
    )
  }

  export default Headlandscape

function getDate(){
    let data = new Date().toLocaleString().split(':')
    let data2 = `${data[0]}:${data[1]}`
    return data2
}