import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SegmentedControl from "../../components/ui/Segment";
import ModalUpdateValue from '../ui/ModalUpdateValue';


function ComboLine({cod, handleCountChange, seg = ''}) {
  const { setAlert, data, user, showUpdating, setShowUpdating, setData, site } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(parseInt(data[cod]?.valor))

  useEffect(() => {
    // if (!data[cod]){
      console.log('Cod', cod, seg)
    // }
  }, []);

  const controlRef = useRef();
  

  useEffect(() => {
    setSelectedValue1(`${parseInt(data[cod]?.valor)}`) 
  }, [cod, data]);

  const updateValue = async (val) => {
    console.log('Seg2', val, cod)
      setSelectedValue1(val)
      setShowUpdating(true)
        setData(prevData => ({
          ...prevData,
          [cod]: {
            ...prevData[cod],
            valor: val
          },
        }))
        setAlert(true)
        handleCountChange(cod)
        setShowUpdating(false)
  }

 

  const getSeg = () => {
    if (cod == 'glVinhoConsVapor'){
      return getSegVaporDest
    }else if (cod == 'vaporColAConv' || cod == 'vaporColAFleg'){
      return getSegV1VE
    }else if (cod == 'opRepoDesaerador'){
      return getSegDesmi
    }else if (cod == 'opVaporEtNeutro'){
      return getSegV1VE
    }else if (cod == 'opRegCaldoEtanol'){
      return getSegOpRegEtanolSON
    }
    
    if (seg === 'V2V1' || cod === 'vaporUtilizadoDifusor' || cod === 'vaporUtilizadoDifusorAqDir'){
      return getSegV2V1
    }
    

    if (cod === 'opVaporAqCCAcucar'){
      return getSegVEV2V1
    }

    if (cod === 'qtdeEfeitos' && site === 'usJbAlcoolquimica'){
      return getSeg345
    }

    if (cod === 'qtdeEfeitos'){
      return getSeg45
    }

    if (cod === 'opAquecVV2Etanol' && site === 'usCevasa'){
      return getSegOpAqEtanolCVS
    }

    let max = data[cod]?.limiteMax
    let q = 6
    if (max !== null){
      q = max
    }
  
    switch(q){
      case 2: return getSeg02
      case 3: return getSeg03
      case 4: return getSeg04
      case 5: return getSeg05
      case 6: return getSeg06
      default: return getSeg01
    }
  }

  const getSegVaporDest = [
    {label: "CALCULADO",  value: 0, ref: useRef()},
    {label: "BOLETIM", value: 1, ref: useRef()}
  ]

  const getSegOpRegEtanolSON = [
    {label: "AQ.DIRETO",  value: 0, ref: useRef()},
    {label: "REG.PLACA", value: 1, ref: useRef()},
    {label: "PARADO", value: 2, ref: useRef()}
  ]

  const getSegOpAqEtanolCVS = [
    {label: "DIRETO",  value: 0, ref: useRef()},
    {label: "INDIRETO", value: 1, ref: useRef()},
    {label: "PARADO", value: 2, ref: useRef()}
  ]

  const getSegDesmi = [
    {label: "DESMI",  value: 0, ref: useRef()},
    {label: "CVG", value: 1, ref: useRef()},
    {label: "DESMI+CVG", value: 2, ref: useRef()}
  ]

  const getSegV1VE = [
    {label: "VV1",  value: 0, ref: useRef()},
    {label: "VE", value: 1, ref: useRef()}
  ]

  const getSegV2V1 = [
    {label: "VV2",  value: 0, ref: useRef()},
    {label: "VV1", value: 1, ref: useRef()}
  ]

  const getSegVEV2V1 = [
    {label: "VV2",  value: 0, ref: useRef()},
    {label: "VV1", value: 1, ref: useRef()},
    {label: "VE", value: 2, ref: useRef()}
  ]

  const getSeg01 = [
    {label: "0",  value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()}
  ]
  const getSeg02 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()}
  ]
  const getSeg03 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3",value: 3,ref: useRef()}
  ]

  const getSeg04 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()}
  ]
  const getSeg05 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()},
    {label: "5", value: 5, ref: useRef()}
  ]
  const getSeg06 = [
    {label: "0", value: 0, ref: useRef()},
    {label: "1", value: 1, ref: useRef()},
    {label: "2", value: 2, ref: useRef()},
    {label: "3", value: 3, ref: useRef()},
    {label: "4", value: 4, ref: useRef()},
    {label: "5", value: 5, ref: useRef()},
    {label: "6", value: 6, ref: useRef()}
  ]

  const getSeg45 = [
    {label: "4",  value: 0, ref: useRef()},
    {label: "5", value: 1, ref: useRef()}
  ]

  const getSeg345 = [
    {label: "3",  value: 0, ref: useRef()},
    {label: "4", value: 1, ref: useRef()},
    {label: "5", value: 2, ref: useRef()}
  ]

  if (data[cod].hide !== true){
    return (
      <>
      <SegmentedControl
        descricao={seg !== '' ? '' : data[cod]?.descricao}
          name="group-1"
          callback={(val) => updateValue(val)}
          controlRef={controlRef}
          defaultIndex={selectedValue1}
          segments={getSeg()}
          w={seg !== '' && 0}
        />
        <ModalUpdateValue show={showUpdating}><p>Atualizando...</p></ModalUpdateValue>
      </>
    )
  }else{
    <></>
  }
}

export default ComboLine

