import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SwitchControl from "../../components/ui/Switch";
import { getAlertList } from '../../Funcoes/GetAletrsList'
import { stdValue } from '../../Resources/setNumber';


function IptLineCap({cod}) {
  const { setAlert, data, setlistAlerts, setData } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(stdValue(data[cod]?.valor));
  const [selectedValue2, setSelectedValue2] = useState(data[cod]?.op2 === null ? 0 : parseInt(data[cod]?.op2));
  const [prevV, setPrevV] = useState('')

  useEffect(() => {
    // console.log('Cap2', data[cod].op2, data)
    setSelectedValue1(`${parseInt(data[cod]?.valor)}`) 
    setSelectedValue2(`${data[cod]?.op2 === null ? 0 : parseInt(data[cod]?.op2)}`) 
  }, [ cod, data]);

  const handleAlert = (v) =>{
    setAlert(true)
}

  const handleIpt = (e) => {
    setSelectedValue1(e.target.value)
  }


  const updateValue = async (e) => {
    if (selectedValue1 === '' || !contemNumero(selectedValue1)){
        setSelectedValue1(prevV)
    }else{
      if (selectedValue1 !== prevV){
        setSelectedValue1(e.target.value)
        handleAlert(true)
        setData(prevData => ({
        ...prevData,
        [cod]: {
            ...prevData[cod],
            valor: parseFloat(selectedValue1),
        },
        }));
        setlistAlerts(getAlertList(data))
      }
    }
  }

  const handlePrevV = (e) => {
    setPrevV(e.target.value)
  }

  const updateValue2 = async (val) => {
    setSelectedValue1(val)
    setData(prevData => ({
    ...prevData,
    [cod]: {
        ...prevData[cod],
        op2: val,
    },
    }));
    setAlert(true)
}

  return (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
         <div className='inputLineCont'>
            <div className='line'>
                <div className='desc'>{data[cod]?.descricao}</div>
                    <input className='ipt' value={selectedValue1} id={cod[0]} onChange={handleIpt} onBlur={updateValue} onFocus={handlePrevV} type="text" />
                <div className='unit'>{data[cod]?.unidade}</div>
            </div>
    </div>    
    <SwitchControl
            descricao={""} name="group-1" callback={(val) => updateValue2(val)} controlRef={useRef()} 
            defaultIndex={selectedValue2} 
            segments={[
                {label: "0", value: 0, ref: useRef()},
                {label: "1", value: 1, ref: useRef()}
            ]}
            w={0}
    />
    </div>
    
  )
}

export default IptLineCap

function contemNumero(string) {
    return !isNaN(parseFloat(string)) && isFinite(string);
  }