import React, { useContext } from 'react';
import GlobalStateContext from '../../../components/GlobalStateContext';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import CryptoJS from 'crypto-js';
import { useState, useEffect } from 'react';
import { Button, ButtonGroup, Menu, Divider} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Grow, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Snackbar, Alert} from '@mui/material';
import { LuFiles } from "react-icons/lu";
import FileSaveAs from './saveAsFile';
import OpenFile from './openFile';
import ExportPdfFlxs from './exportPdfFlxs';
import SetDefault from './setDefaultFile';
import FileUploaderOld from './FileUploaderOld';

import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SaveAsIcon from '@mui/icons-material/SaveAs';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


const options = ['SALVAR COMO', 'ABRIR BME', 'EXPORTAR PDF', 'SET DEFAULT'];

export default function BtArquivo() {
    const {data, site, user, bmeFileName, setBmeFileName, setData} = useContext(GlobalStateContext)
    const {newAction} = AnalyticsHooks()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);


    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackMessage, setSnackMessage] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');


    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
      };



    const StyledMenu = styled((props) => (
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...props}
        />
      ))(({ theme }) => ({
        '& .MuiPaper-root': {
          borderRadius: 6,
          marginTop: theme.spacing(1),
          minWidth: 180,
          color: 'rgb(55, 65, 81)',
          boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          '& .MuiMenu-list': {
            padding: '4px 0',
          },
          '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
              fontSize: 18,
              color: theme.palette.text.secondary,
              marginRight: theme.spacing(1.5),
            },
            '&:active': {
              backgroundColor: alpha(
                theme.palette.primary.main,
                theme.palette.action.selectedOpacity,
              ),
            },
          },
          ...theme.applyStyles('dark', {
            color: theme.palette.grey[300],
          }),
        },
      }));

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    async function exportToJSON(useDownloadFallback = false) {
        setAnchorEl(null);
        const encryptedData = encryptData(data, site);
        const blob = new Blob([encryptedData], { type: 'application/plain' });
        const filename = `${bmeFileName === '' ? `bmeOUR_${new Date().toLocaleDateString('pt-BR').replace(/\//g, '-')}` : bmeFileName}.pms${user.sites[site].sigla}`;
      
        try {
          newAction('salvarComoBME', site, user.email || '');
      
          if (useDownloadFallback || !('showSaveFilePicker' in window)) {
            exportToDownloadsFile(filename, blob);
          } else {
            const fileHandle = await window.showSaveFilePicker({
              suggestedName: filename,
              types: [
                {
                  description: 'PMS Files',
                  accept: { 'application/json': ['.pms' + user.sites[site].sigla] },
                },
              ],
            });
      
            setBmeFileName(fileHandle.name.split('.').slice(0, -1).join('.'));
            const writableStream = await fileHandle.createWritable();
            await writableStream.write(blob);
            await writableStream.close();
          }
        } catch (error) {
          console.error('Erro ao salvar o arquivo:', error);
          // Como fallback em caso de erro, também podemos fazer o download direto
        //   exportToDownloadsFile(filename, blob);
        }
      }

      function exportToDownloadsFile(filename, contentBlob) {
        const url = URL.createObjectURL(contentBlob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
      
        

    const handleFileChange = (event) => {
        setAnchorEl(null);
        newAction('abrirBME', site, user.email || '')
        const fileInput = document.getElementById('fileInput');
        console.log('Start importing');
        const file = event.target.files[0];
    
        if (file) {
            // Verifica a extensão do arquivo
            const fileExtension = file.name.split('.').pop();
            
            if (fileExtension === 'pms'+user.sites[site].sigla) {
                const reader = new FileReader();
                reader.onload = async (e) => {
                    // const jsonContent = e.target.result;
                    // const jsonData = JSON.parse(jsonContent);
  
                    const encryptedContent = e.target.result; // Conteúdo do arquivo criptografado
                    let jsonData = decryptData(encryptedContent, site); 
    
                    if (jsonData) {
                      // console.log('Dados descriptografados:', jsonData);
                      
                      jsonData = await setExceptions(site, jsonData)
  
                      // Atualize seu estado ou banco de dados com os dados descriptografados
                      // await updateDB(jsonData);
                      updateData(jsonData)
                      global.lastFile = fileInput.value
                      const fileNameWithExtension = fileInput.value.split('\\').pop()
                      const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.')
                      // console.log('File Name:', fileName)
                      setBmeFileName(fileName)
                      fileInput.value = '';
                    } else {
                        alert('Arquivo foi alterado ou não pertence à unidade atual e não pode ser aberto.');
                        fileInput.value = ''
                    }
                };
                reader.readAsText(file);
                // setOpen(false)
            } else {
                // setOpen(false)
                alert('Por favor, selecione um arquivo com a extensão .pms');
            }
        }
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    function updateData(importedData) {
        let updatedData = { ...data };
        for (const key in updatedData) {
          if (updatedData.hasOwnProperty(key) && importedData.hasOwnProperty(key)) {
            updatedData[key] = {
              ...updatedData[key],
              valor: importedData[key].valor,
              limiteMin: importedData[key].limiteMin ?? data[key].limiteMin,
              limiteMax: importedData[key].limiteMax ?? data[key].limiteMax,
              alerta: importedData[key].alerta ?? data[key].alerta,
              op1: importedData[key].op1 ?? data[key].op1,
              op2: importedData[key].op2 ?? data[key].op2,
              hide: importedData[key].hide ?? data[key].hide,
            };
          }
        }
        setData(updatedData);
      }

    return (
        <div style={{overflow: 'hidden'}}>
          <Button
            id="demo-customized-button"
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            endIcon={<KeyboardArrowDownIcon />}
            startIcon={<InsertDriveFileIcon />}
            sx={{fontWeight: 'bold'}}
          >
            ARQUIVO
          </Button>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => exportToJSON(false)} disableRipple>
              <SaveAsIcon />
              Salvar como
            </MenuItem>
           
            <MenuItem  disableRipple
                component="label"
                htmlFor="fileInput">
                <input id="fileInput" type="file" accept={`.pms${user?.sites[site].sigla}`} onChange={handleFileChange} style={{ display: 'none'}} />
              <FileOpenIcon />
              Abrir BME
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={() => exportToJSON(true)} disableRipple>
              <FileDownloadIcon />
              Exportar BME
            </MenuItem>
           <ExportPdfFlxs handleClose={handleClose}/>
            <Divider sx={{ my: 0.5 }} />
           
            <SetDefault handleClose={handleClose}/>
            {site === 'usLins' &&
              <FileUploaderOld handleClose={handleClose}/>
            }
          </StyledMenu>
        </div>
      );

    // return (
    //     <React.Fragment>
    //         <ButtonGroup
    //             variant="outlined"
    //             ref={anchorRef}
    //             aria-label="Button group with a nested menu"
    //         >
    //             {selectedIndex === 0 ?
    //                 <FileSaveAs setOpen={setOpen} button={true} label='ARQUIVO'/> :
    //             selectedIndex === 1 ?
    //                 <OpenFile setOpen={setOpen} button={true} label='ARQUIVO'/> :
    //             selectedIndex === 2 ?
    //                 <ExportPdfFlxs setOpen={setOpen} button={true} label='ARQUIVO'/> :
    //             selectedIndex === 3 ?
    //                 <SetDefault setOpen={setOpen} button={true} label='ARQUIVO'/> :
    //                 <Button startIcon={<LuFiles />}>ARQUIVO</Button>
    //             }
                
    //             <Button
    //                 aria-controls={open ? 'split-button-menu' : undefined}
    //                 aria-expanded={open ? 'true' : undefined}
    //                 aria-haspopup="menu"
    //                 onClick={() => setOpen((prevOpen) => !prevOpen)}
    //             >
    //                 <ArrowDropDownIcon />
    //             </Button>
    //         </ButtonGroup>

    //         <Popper
    //             sx={{ zIndex: 1 }}
    //             open={open}
    //             anchorEl={anchorRef.current}
    //             role={undefined}
    //             transition
    //             placement="bottom-start"
    //         >
    //             {({ TransitionProps }) => (
    //                 <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
    //                     <Paper>
    //                         <ClickAwayListener onClickAway={() => setOpen(false)}>
    //                             <MenuList id="split-button-menu" autoFocusItem>
    //                                 {options.map((option, index) => (
    //                                     <MenuItem
    //                                         key={option}
    //                                         selected={index === selectedIndex}
    //                                         onClick={(event) => handleClick(event, index)}
    //                                         sx={{fontSize: '12px'}}>
    //                                         {
    //                                             option === 'ABRIR BME' ? <OpenFile setOpen={setOpen} /> :
    //                                             option === 'SALVAR COMO' ? <FileSaveAs setOpen={setOpen} /> :
    //                                             option === 'EXPORTAR PDF' ? <ExportPdfFlxs setOpen={setOpen} /> :
    //                                             option === 'SET DEFAULT' ? <SetDefault setOpen={setOpen} /> :
    //                                             option
    //                                         }
    //                                     </MenuItem>
    //                                 ))}
    //                             </MenuList>
    //                         </ClickAwayListener>
    //                     </Paper>
    //                 </Grow>
    //             )}
    //         </Popper>
    //         <Snackbar 
    //     open={openSnackBar} 
    //     autoHideDuration={3000} 
    //     onClose={handleCloseSnackBar}
    //     >
    //     <Alert severity={snackSeverity}>{snackMessage}</Alert>
    //   </Snackbar>
            
    //     </React.Fragment>
    // );
}

function encryptData(data, site) {
    const secretKey = 'my-secret-key'+site; // Chave secreta para criptografia (mantenha-a segura)
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
    return encryptedData;
}

function decryptData(encryptedData, site) {
    const secretKey = 'my-secret-key'+site; // A mesma chave secreta usada na criptografia
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        // Verifica se a descriptografia resultou em uma string válida JSON
        if (decryptedData) {
            return JSON.parse(decryptedData);
        } else {
            // alert('Arquivo não compatível com planta atual!')
            return null //throw new Error('Descriptografado: o conteúdo está vazio ou inválido.');
        }
    } catch (error) {
        console.error('Erro na descriptografia:', error.message);
        // alert('Erro ao abrir o arquivo: ' + error.message);
        // Aqui você pode decidir como lidar com o erro: retornar um valor padrão, lançar o erro novamente, etc.
        return null; // ou outro valor padrão que você queira usar
    }
}

async function setExceptions(site, data){
    let tempData = {...data}
    if (site === 'usOuroeste'){
      tempData['capResfMosto1'].op2 = data['opResfMosto1'].valor
      tempData['capResfMosto2'].op2 = data['opResfMosto2'].valor
    }
    return tempData
  }




