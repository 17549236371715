import React, { useContext, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { getStdPlanoSafra } from '../../../BME/Unidades/Geral/getStdPlanoSafra';
import GlobalStateContext from '../../../components/GlobalStateContext';
import { stdValue } from '../../../Resources/setNumber';
import { Circle } from '@mui/icons-material';
import AnalisesContext from '../../AnalisesContext';
import { getBltValue, getMtdValue } from '../dataFuncs/transformData';
import { getSiteTable } from '../tables/getSiteTable';

const PrevXReal = () => {
    const {site} = useContext(GlobalStateContext)
    const {date, tempPsData, setTempPsData, siteData} = useContext(AnalisesContext)
    const [groupedData, setGroupedData] = useState(null)
    const stdPlanoVars = getStdPlanoSafra(site)
    const [mes, setMes] = useState(null);
    const [siteTable, setSiteTable] = useState(null)
    const COLS = ['PLANO DIA', 'REAL DIA', 'DESVIO DIA', 'PLANO MÊS', 'REAL MÊS', 'DESVIO MÊS'];
    const monthlyVars = ['psDiasSafra', 'psMoagemTotal', 'psArtEntrado', 'psArtRecuperado', 'psProdUnieqs', 'psProdAcucarTotal', 'psProdAcucarTotalSC',
        'psProdAcucarVHP', 'psProdAcucarVHPSC', 'psProdAcucarCristal', 'psProdAcucarCristalSC', 'psProdTotalEtanol100', 'psProdEtanol100MelTerc',
        'psProdEtanolTotalHid', 'psProdEtanolAnidro', 'psProdEtanolNeutro', 'psProdEtanolDest', 'psProdEtanolTotal', 'psFlowMelTerc', 'psEnergiaGerada',
        'psEnergiaExportada', 'psEnergiaConsumida', 'psProdVapor', 'psProdBagacao', 'psConsBagacao', 'psSobraBagaco', 'psVendaBagaco', 'psProdAcucarDemeraraSC',
        'psVaporTgCond', 'psVaporRebaixado'
    ]

    const diasSafra = () =>{
      try{
        return tempPsData.filter(i => i.codigo === 'psDiasSafra')[0][mes] || 1

      }catch{
        return 1
      }
    }

    const diasMTD = () => {
        try {
            let daysInMonth = getDaysInMonth(date);          
            let currentDay = parseInt(date.split('/')[0]);
            let daysSafra = diasSafra();                     
            let diasPassados = currentDay - (daysInMonth - daysSafra);
            return Math.max(diasPassados, 0);
        } catch {
            return 0;
        }
    }

    useEffect(() => {
      const loadData = async () => {
        // console.log('loadData', tempPsData)
        if (!tempPsData && site) {
          const savedTempPsData = localStorage.getItem(`tempPsData-${site}`)
          if (savedTempPsData) {
            const parsedData = JSON.parse(savedTempPsData)
            setTempPsData(parsedData)
            const newGroupedData = GetGroupedData(parsedData, stdPlanoVars)
            setGroupedData(newGroupedData)
          }
        } else if (tempPsData) {
          const newGroupedData = GetGroupedData(tempPsData, stdPlanoVars)
          console.log('newGroupedData', newGroupedData)
          setGroupedData(newGroupedData)
          setSiteTable(await getSiteTable(site))
        }
        const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        const mesAbreviado = months[new Date(revertDataFormat(date)).getMonth()];
        // console.log('mesAbreviado', mesAbreviado, date, new Date(revertDataFormat(date)).getMonth())
        setMes(mesAbreviado.toLowerCase())
      }

      loadData()

    }, []) // sem dependências, roda apenas uma vez ao montar

    function revertDataFormat(dateString) {
      const [day, month, year] = dateString?.split("/").map(Number); // Divide e converte os valores
      return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`;
  }

    if (!tempPsData) return <h3>Plano Safra não foi importado</h3>
    if (!groupedData) return <h3>Carregando dados...</h3>

    return (
         <TableContainer component={Paper} sx={{marginTop: '2px',  width: "100%", overflowX: "auto", maxHeight: 'calc(89vh - 100px)' }}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell sx={{color: 'white', backgroundColor: 'primary.main',    fontWeight: "bold", padding: '1px', fontSize: '12px', paddingLeft: '10px', textAlign: 'left', minWidth: 120  , border: 0 }}>DESCRIÇÃO</TableCell>
                <TableCell sx={{color: 'white', backgroundColor: 'primary.main',    fontWeight: "bold", padding: '1px' , fontSize: '12px', textAlign: 'left', border: 0 }}>UNID.</TableCell>
                {COLS.map((col, idx) => (
                  <TableCell key={col} sx={{ color: 'white', backgroundColor: 'primary.main', textAlign: "center", fontWeight: "bold" , padding: '1px', fontSize: '12px', border: 0 }}>
                    {col}
                  </TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedData)
              .filter(([secao, itens]) => secao !== 'settings')
              .map(([secao, itens]) => (
                <React.Fragment key={secao}>
                  {/* Cabeçalho da seção */}
                  <TableRow>
                    <TableCell colSpan={17} sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", padding: '1px', paddingLeft: '10px', fontSize: '12px', position: 'sticky', top: '26px' }}>
                      {secao}
                    </TableCell>
                  </TableRow>
                  {/* Dados da seção */}
                  {itens.map((item, index) => {
                    return(
                    <TableRow key={`${secao}-${index}`}>
                      <TableCell sx={{ padding: '1px', fontSize: '12px', textAlign: 'left', paddingLeft: '10px', }}>{item.descricao}</TableCell>
                      <TableCell  sx={{ padding: '1px', fontSize: '12px' , textAlign: 'left' }}>{item.unidade}</TableCell>
                      {COLS.map((col, idx) => {
                        // let siteTable = getSiteTable(site)
                        // console.log('siteTable', siteTable)
                        let bltCod = siteTable?.find(i => i.psCod === item.codigo)?.bltCod
                        let realValue = bltCod ? getBltValue(bltCod, siteData) : 0
                        let planValue = monthlyVars.includes(item.codigo) ? item[mes]/diasSafra() : item[mes] ? item[mes] : 0
                        let realMtd = bltCod ? getMtdValue(bltCod, siteData) : 0
                        let planMtd = monthlyVars.includes(item.codigo) ? item[mes]*diasMTD()/diasSafra() : item[mes] ? item[mes] : 0
                        // console.log('item', item, mes, item[mes])
                        return (
                        <TableCell key={`${secao}-${index}-${col}`} sx={{  textAlign: "center", minWidth: 55, padding: '1px', fontSize: '11px', 
                        backgroundColor: `${idx < 3 ? '#f7f7f7' : 'white'}` }}>
                          {col === 'PLANO DIA' && stdValue(planValue)}
                          {col === 'REAL DIA' && stdValue(realValue)}
                          {idx === 2 && <Desvio realValue={realValue} planValue={planValue} cod={item.codigo}/>}
                          {idx === 3 && stdValue(planMtd)}
                          {idx === 4 && stdValue(realMtd)}
                          {idx === 5 && <Desvio realValue={realMtd} planValue={planMtd} cod={item.codigo}/>}
                        </TableCell>
                      )})}
                     
                    </TableRow>
                  )})}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
};

export default PrevXReal;


function GetGroupedData(psData, stdPlanoVars) {
  let dontShowList = ['psEstoqueInicialBag', 'psEstoqueFinalBag', 'psProdUnieqs', 'psProdUnieqsTc', 'psProdAcucarTotal', 'psProdAcucarDemerara', 
    'psMixProdAcucarDemerara', 'psMixProdAcucarVHP', 'psProdAcucarVHP', 'psProdAcucarCristal', 'psTaxaEfProdAcucar',
   'psProdEfEtanol100', 'psProdEfEtanol100MelTerc', 'psProdEfEtanolHidpTq', 'psProdEfEtanolAnidro', 'psProdEfEtanolNeutro', 'psProdEfEtanolDest',
  'psFlowEfMelTerc', 'psPtGerEnergia', 'psPtExportada', 'psPtConsumidaInd', 'psUpTimeVapor', 'psProdEfVapor', 'psConsVaporTc', 'psProdEfBagacao',
  'psConsEfBagacao', 'psSobraBagaco', 'psAjusteEstoqueBag', 'psProdVinhacapEtanol'
]
    if (psData){
        const groupedItems = stdPlanoVars.reduce((acc, item) => {
          if (item.grp !== 'DIAS NÃO EFETIVOS'){
            if (!acc[item.grp]) {
                acc[item.grp] = [];
            }
            let psItem = psData.filter(row => row.codigo === item.cod)
  
            if (item.show === false || dontShowList.includes(item.cod)){
            }else if(psItem.length > 0){
                psItem[0].descricao = item.desc
                psItem[0].unidade = item.unid
                acc[item.grp].push(psItem[0])
            }else{
                let rev = psData[0].revisao
                let safra = psData[0].safra
                let ssr = psData[0].ssr
                let siteId = psData[0].siteId
  
                let blankItem = {abr: 0, acu: 0, ago: 0, dez: 0, fev: 0, jan: 0, jul: 0, jun: 0, mai: 0, mar: 0, nov: 0, out: 0, set: 0,
                ssr: ssr, input: item.ipt ? item.ipt : null, safra: safra, secao: item.grp, codigo: item.cod, siteId: siteId, revisao: rev,
                unidade: item.unid, descricao: item.desc}
                acc[item.grp].push(blankItem)
            }
          }
          return acc;
      }, {});
    //   console.log('group', groupedItems)
        return groupedItems
    }
    return null    
} 

const execptVars = ['psMixEtanol', 'psCanaEtanol', 'psPtConsumidaTC', 'psEnergiaConsumida', 'psConsVaporTcTotal', 'psVaporRebaixado']

function getDesvio(realValue, planValue, cod){
  try{
    if (isNaN(realValue) || isNaN(planValue)){
      return null
    }
    let desv = (realValue - planValue)
    const percent = () => {
      const real = realValue;
    
      if (planValue === 0 && real > 0) {
        return 100;
      } else if (planValue > 0 && real === 0) {
        return -100;
      } else if (planValue === 0 && real === 0) {
        return 0;
      } else if (planValue !== 0) {
        return ((real - planValue) * 100) / planValue;
      } else {
        return 0; 
      }
    };
    let status = desv > 0 ? 'green' : 'red'
    if (desv === 0){
      status = "gray"
    }
    if (execptVars.includes(cod)){
        status = desv > 0 ? 'red' : 'green'
    }
    return {valor: stdValue(desv), percent: stdValue(percent()), status: status}
  }catch{
    return null
  }
}



const Desvio = ({realValue, planValue, cod}) => {
  let desv = getDesvio(realValue, planValue, cod)
  if (!desv){
    return (<div>-</div>)
  }else{
    return(
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '60%', textAlign: 'right'}}>{`${desv.valor} (${desv.percent}%)`}</div>
        <div style={{width: '40%'}}>
          <SemaforoKPI status={desv.status}/>
        </div>
        
      </div>
    )
  }
  
}


const SemaforoKPI = ({ status }) => {
  return <Circle style={{ color: status, fontSize: 16}} />;
};

function getDaysInMonth(dateString) {
  // Separa dia, mês e ano
  const [dia, mes, ano] = dateString.split('/');
  
  // Cria uma data com o primeiro dia do próximo mês
  // O mês em JS é 0-based, por isso usamos mes-1 para o mês atual
  // e 0 para o dia (que será o último dia do mês anterior)
  const ultimoDia = new Date(ano, mes, 0);
  
  // Retorna o dia (que será o último dia do mês)
  return ultimoDia.getDate();
}
