import React, { useState, useEffect, useContext } from 'react';
import useSiteDataFetch from '../hooks/useSiteDataFetch';
import { transformData, setRealToBme } from '../dataFuncs/transformData';
import { getSiteTable } from '../tables/getSiteTable';
import { Button, TextField, Box, Tabs, Tab, Slide, Typography, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import RealDataxBme from './realDataxBme';
import PsHooks from '../../../Hooks/psHooks';
import GlobalStateContext from '../../../components/GlobalStateContext';
import AnalisesContext from '../../AnalisesContext';
import PrevXReal from './realDataxPlano';
import LoadingDialog from '../../../components/ui/LoadingDialog';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import { convertDateToISO } from '../../../Resources/dateFuncs';
import usePsHooks from '../../../Hooks/psHooks';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import InputValueBox from '../../../components/ui/new/inputValueBox';
import IconButton from '../../../components/ui/new/iconButton';
import SnackMessage from '../../../components/ui/new/snackMessage';
import useBMEHooks from '../../../Hooks/bmeHooks';

export default function AnaliseProducao() {

  const { getSiteData } = useSiteDataFetch()
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("Iniciando...");
  const [tabIndex, setTabIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const {site, user, data, setData} = useContext(GlobalStateContext)
  const {date, setDate, siteData, setSiteData, setTempPsData, tolerancia, setTolerancia, showAllBmeBoletim, setShowAllBmeBoletim} = useContext(AnalisesContext)
  const [selectedDate, setSelectedDate] = useState(date ? convertDateToISO(date) : null);
  const {openPS} = PsHooks()
  const {newAction} = AnalyticsHooks()
  const {resumeAllPS} = usePsHooks()
  const [planoReferencia, setPlanoReferencia] = useState('');
  const [listaRevs, setListaRevs] = useState(['']);
  const [snackBar, setSnackBar] = useState({open: false, message: '', severity: 'success'})
  const {calcBME} = useBMEHooks()

  useEffect(() => {
    if (!selectedDate) {
      const ontem = new Date();
      ontem.setDate(ontem.getDate() - 1);
      const ano = ontem.getFullYear();
      const mes = String(ontem.getMonth() + 1).padStart(2, '0');
      const dia = String(ontem.getDate()).padStart(2, '0');
      setSelectedDate(`${ano}-${mes}-${dia}`);
  }
  }, [])

  useEffect(() => {
    async function getPlanos(){
      if (selectedDate && 
          selectedDate.length === 10 && 
          /^\d{4}-\d{2}-\d{2}$/.test(selectedDate) &&
          new Date(selectedDate).getFullYear() >= 2000 &&
          new Date(selectedDate).getFullYear() <= 2100) {
            
        let planos = await resumeAllPS(site)
        
        if (planos) {
          let selectedYear = new Date(selectedDate).getFullYear() - 2000
          let selectedSafra = Object.keys(planos).filter(item => item.split('/')[0].includes(`${selectedYear}`))
          
          if (selectedSafra.length > 0) {
            let selectRevs = Object.keys(planos[selectedSafra[0]])?.filter(item => item !== '13_Realizado')
            let optPlano = selectRevs.map(item => `${selectedYear}/${selectedYear + 1} - ${item}`)
            setListaRevs(optPlano)
            setPlanoReferencia(optPlano[0])
            return
          }
        }
        
        setListaRevs([])
        setPlanoReferencia('')
      } else {
        setListaRevs([])
        setPlanoReferencia('')
      }
    }
    getPlanos()
  }, [selectedDate])

  const handleTabChange = (_, newIndex) => {
    setPrevIndex(tabIndex); // Armazena o índice anterior
    setTabIndex(newIndex);
  };


  const gerarAnalise = async () => {
    newAction('gerarAnalise', site, user.email)
    setLoading(true);
    setStatusMessage("Carregando dados do Plano Safra...");
    try {
      const dateObj = new Date(selectedDate);
      const formattedDate = dateObj.toLocaleDateString('pt-BR'); // Formato "DD/MM/YYYY"
      await ImportPsr()
      setStatusMessage("Carregando dados de produção...");
      const dados = await getSiteData(site, formattedDate)

      if (!dados.siteData) {
        throw new Error(dados.error || 'Erro ao buscar dados');
      }
      setStatusMessage("Organizando dados...")
      let transformedData = transformData(site, dados.siteData)
      setSiteData(transformedData)
      setStatusMessage("Atualizando BME...")
      let siteTable = await getSiteTable(site)
      let temp = await setRealToBme(siteData, siteTable, data)
      setData(temp)
      

      setStatusMessage("Calculando BME...")
      await calcBME()

      setLoading(false)
      setSnackBar({...snackBar, message: 'Dados carregados com sucesso', severity: 'success', open: true})
      setDate(formattedDate)
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setLoading(false)
      setSnackBar({...snackBar, message: 'Erro ao buscar dados', severity: 'error', open: true})
    }
  };

  async function ImportPsr(){
    setStatusMessage("Importando Plano Safra...");
    let ssr = `${site}-${planoReferencia.split(' - ')[0].replace(' - ', '-')}-${planoReferencia.split(' - ')[1]}`
    let tempPs = await openPS(ssr);
    setTempPsData(tempPs)
  }

  const tabs = [
    { label: "REAL x BME", content: <RealDataxBme/>  },
    { label: "REAL x PLANO", content: <PrevXReal /> },
    // { label: "BOLETINS", content: <Boletim /> }
  ].filter(Boolean);

  return (
    <Box display="flex" flexDirection="column" width="100%">

      {/* MENU */}
      <Box display="flex" flexDirection="row"  justifyContent="space-between" marginInline={1}>
          <Box display="flex" gap={3} alignItems="center" justifyContent="center" height="50px" p={1}>
              <Box display="flex" gap={1} alignItems="center" justifyContent="center">
                <TextField sx={{minWidth: 160}} label="Selecione uma data" type="date"
                  value={selectedDate} onChange={e  => setSelectedDate(e.target.value)} 
                  InputLabelProps={{ shrink: true }}/>
                  <FormControl sx={{ m: 1, minWidth: 200 }}>
                      <InputLabel id="plano-referencia-label">Plano Referência</InputLabel>
                      <Select
                        labelId="plano-referencia-label"
                        id="plano-referencia"
                        value={planoReferencia}
                        label="Plano Referência"
                        onChange={e  => setPlanoReferencia(e.target.value)}
                      >
                        {listaRevs.map((item, index) => (
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                  </FormControl>
                <Button variant="outlined" color="primary" onClick={gerarAnalise} disabled={loading}>Gerar Análise</Button>
              </Box>
              
              
          </Box>
          <Box display="flex" gap={3} alignItems="center" justifyContent="center" height="50px" p={1}>
            {tabIndex === 0 && 
                  <>
                    <InputValueBox 
                        value={tolerancia} setValue={setTolerancia} 
                        label="Tolerância" unit='%' min={0} max={10} 
                        increment={1} width='100px' limit={true}/>
                    <IconButton 
                        activeIcon={<FilterListIcon/>} inactiveIcon={<FilterListOffIcon/>} 
                        setActive={setShowAllBmeBoletim} active={showAllBmeBoletim}/>
                  </>
                }
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'primary.main', p: 1, fontSize: '14px', fontWeight: 'bold'}}>
              {date}
            </Box>
          </Box>
          
      </Box>

      {/* TABS */}
      <Box sx={{ display: "flex", flexDirection: "column", height: "100vh"}}>
      <Tabs value={tabIndex}  onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>

      {/* TABS CONTENT */}
      <Box mt={0} p={0} sx={{ minHeight: 100, overflow: "hidden", position: "relative"}}>
          <Slide direction={tabIndex > prevIndex ? "left" : "right"} in={true} 
            timeout={500} mountOnEnter unmountOnExit key={tabIndex}>
              <Box>
                {tabs[tabIndex].content}
              </Box>
          </Slide>
      </Box>
    </Box>
    <LoadingDialog open={loading} statusMessage={statusMessage} />
    <SnackMessage snackBar={snackBar} setSnackBar={setSnackBar} />
    </Box>
  );
  
}






