import React, { useRef, useState, useContext } from 'react';
import { Box, Alert, Snackbar, Typography, Button, MenuItem } from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import LoadingDialog from '../../../components/ui/LoadingDialog';
import Listas from '../../../components/Conteudo/Listas'
import GlobalStateContext from '../../../components/GlobalStateContext';
import { FaRegFilePdf } from "react-icons/fa6";
import Fluxograma from '../../../components/Conteudo/Fluxograma';
import Headlandscape from '../../../components/ui/HeadLandscape';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import { LuFiles } from "react-icons/lu";

const ExportPDF = ({handleClose}) => {
  const divRefs = useRef([]);
  const {site, user, setPrinting, bmeFileName, isIntelup } = useContext(GlobalStateContext)
  const {newAction} = AnalyticsHooks()
  const [openLoading, setOpenLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState('success');

  const [print, setPrint] = useState(false)

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };


  async function exportPDF(){
    setPrint(true)
    setLoadingMessage('Carregamento Elementos...')
    setOpenLoading(true)
    newAction('expPDFBME', site, user.email || '')

    await new Promise(resolve => setTimeout(resolve, 1000));

    if (divRefs.current.length === 0) {
        console.error("Nenhum elemento foi referenciado para exportação.");
        setOpenLoading(false);
        setSnackMessage('Nenhum elemento foi referenciado para exportação.')
        setSnackSeverity('error')
        setOpenSnackBar(true)
        return;
    }

    setLoadingMessage('Gerando PDF...')
   
    const pdf = new jsPDF('p', 'mm', 'a4', true);
    
    let promiseChain = Promise.resolve();

    divRefs.current.forEach((div, index) => {
      // setProgress(divRefs.current.length/index)
      promiseChain = promiseChain.then(async () => {
        const canvas = await html2canvas(div, 
          {scale: 2,
          width: index === 0 ? 1080 : 1072,
          height: index === 0 ? 1700 : 796,
          });
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 277; // largura em mm para A4
        const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;
        if (index > 0) {
          pdf.addPage("a4", "l");
          // imgWidth = 190;
          // imgHeight = 277;
          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          console.log('Canvas', index, canvas.width, canvas.height)
        }else{
          pdf.addImage(imgData, 'PNG', 10, 10, 190, 277);
          console.log('Canvas', index, canvas.width, canvas.height)
        }
        
      });
    });

    promiseChain.then(() => {
      pdf.save(`${bmeFileName || `bme-${site}-${new Date().toLocaleString()}`}.pdf`);
      setSnackMessage('PDF gerado com sucesso!')
      setSnackSeverity('success')
      setOpenSnackBar(true)
      setOpenLoading(false)
      setPrint(false)
      handleClose()
      // setOpen(false)
    }).catch(err => {
      console.error('Erro ao gerar PDF:', err);
      setSnackMessage('Erro ao gerar PDF!')
      setSnackSeverity('error')
      setOpenSnackBar(true)
      setOpenLoading(false)
      setPrint(false)
      handleClose()
      // setOpen(false)
    });
  
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const vh = window.innerHeight
  const ideal = 727
  let nVH = isIntelup ? 0.8 : 1

  return (
    <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>

<div style={{ position: 'absolute', left: '-9999px', display: print ? 'block' : 'none' }}>
        <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='RESUMO PRODUÇÃO'  fileName={bmeFileName} page='1' pages='12'/>
            <div style={{ width: '100%',  marginTop: '5px', marginBottom: '300px', position: 'relative', lineHeight: '1.0'}}>
                <Listas print={true}/>
             </div>
      </div>
    
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: 'transparent', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EXTRAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='2' pages='12'/>
            <div style={{height: '87%', width: '90%', margin: 'auto',  lineHeight: `${1}`}}>
                <Fluxograma print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO FÁBRICA' subtitle='Fluxograma' fileName={bmeFileName} page='3' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
            <Fluxograma a={'tratAcucar'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TRATAMENTO DESTILARIA' subtitle='Fluxograma' fileName={bmeFileName} page='4' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'tratEtanol'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FILTRAÇÃO LODO' subtitle='Fluxograma' fileName={bmeFileName} page='5' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'filtLodo'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='EVAPORAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='6' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'evaporacao'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FERMENTAÇÃO' subtitle='Fluxograma' fileName={bmeFileName} page='7' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'fermentacao'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='DESTILARIA' subtitle='Fluxograma' fileName={bmeFileName} page='8' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'destilaria'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='FÁBRICA DE AÇÚCAR' subtitle='Fluxograma' fileName={bmeFileName} page='9' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'fabrica'} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='VAPOR E ENERGIA' subtitle='Fluxograma' fileName={bmeFileName} page='10' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'vapor'} print={true}/>
             </div>
      </div>
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='TORRES RESFRIAMENTO' subtitle='Fluxograma' fileName={bmeFileName} page='11' pages='12'/>
            <div style={{height: '87%', width: '96%', margin: 'auto', position: 'relative', scale: `${1}`,  lineHeight: `${1}`}}>
              <Fluxograma a={'flxTorres'} print={true}/>
             </div>
      </div>
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='BALANÇO HÍDRICO' subtitle='Fluxograma' fileName={bmeFileName} page='12' pages='12'/>
            <div style={{height: '630px', width: '1000px', marginLeft: '30px', marginRight: '40px', marginTop: '20px', position: 'relative', scale: `${1}`,  lineHeight: `${1}` }}>
              <Fluxograma a={'balHidrico'} print={true}/>
             </div>
      </div>
       </div>
       

       
       <MenuItem onClick={exportPDF} disableRipple sx={{width: '100%'}}>
          <FaRegFilePdf style={{marginLeft: '3px', marginRight: '10px'}}/>
          Exportar PDF
        </MenuItem>
     
          {/* {button ?
          <Button
            onClick={exportPDF} 
            startIcon={<LuFiles />}
            sx={{cursor: 'pointer', fontSize: '12px', fontWeight: 'bold'}}
            >
            {label}
          </Button>
          :
          <Typography 
            onClick={exportPDF} 
            sx={{cursor: 'pointer', fontSize: '12px'}}
            >
            {label}
          </Typography>
          } */}

          <LoadingDialog open={openLoading} statusMessage={loadingMessage}/>
          <Snackbar open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar}>
                <Alert
                onClose={handleCloseSnackBar}
                severity={snackSeverity}
                variant="filled"
                sx={{ width: '100%' }}
                >
                {snackMessage}
                </Alert>
            </Snackbar>
   </Box>
  );
};

export default ExportPDF;


