let BltsXBmeVars = [
    {grp: 'MATÉRIA PRIMA', bmeCod: 'artCana', blt: 'ANAL', linha: 1022, iptBme: true, iptBmeBol: true},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'purezaCana', blt: 'ANAL', linha: 990, show:false},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'arCana', blt: 'ANAL', linha: 1020, iptBme: true, iptBmeBol: true},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'atrCanaPCTS', blt: 'ANAL', linha: 1024},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'fibraCana', blt: 'ANAL', linha: 1000, iptBme: true, iptBmeBol: true},
    // {grp: 'MATÉRIA PRIMA', bmeCod: 'pccCana', blt: 'ANAL', linha: 1080, iptBme: true},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'brixCana', blt: 'ANAL', linha: 970, iptBme: true, iptBmeBol: true},
    {grp: 'MATÉRIA PRIMA', bmeCod: 'polCana', blt: 'ANAL', linha: 1010},

    
    {grp: 'MOAGEM', bmeCod: 'flowCana', blt: 'PROD', linha: 760, iptBme: true, iptBmeBol: true},
    {grp: 'MOAGEM', bmeCod: 'horasEfetivas', blt: 'PROD', linha: 750, iptBme: true, iptBmeBol: true},
    {grp: 'MOAGEM', bmeCod: 'flowCanaDia', blt: 'PROD', linha: 705, iptBme: true, iptBmeBol: true},
    {grp: 'MOAGEM', bmeCod: 'canaMoidaFabrica', blt: 'PROD', linha: 710},
    {grp: 'MOAGEM', bmeCod: 'canaMoidaEtanol', blt: 'PROD', linha: 715},

    {grp: 'MOAGEM', bmeCod: 'polBagaco', blt: 'PROD', linha: 6030, showBmeBol: false, iptBme: true, iptBmeBol: true},
    {grp: 'MOAGEM', bmeCod: 'umidBagaco', blt: 'PROD', linha: 6040, showBmeBol: false, iptBme: true, iptBmeBol: true},
    {grp: 'MOAGEM', bmeCod: 'embCana', blt: 'PROD', linha: 6050, iptBme: true, iptBmeBol: true},

    {grp: 'MOAGEM', bmeCod: 'brixCaldo1T', blt: 'ANAL', linha: 1120},
    {grp: 'MOAGEM', bmeCod: 'polCaldo1T', blt: 'ANAL', linha: 1130, showBmeBol: false, show: false},
    {grp: 'MOAGEM', bmeCod: 'purezaCaldo1T', blt: 'ANAL', linha: 1140, showBmeBol: false, show: false},

    {grp: 'MOAGEM', bmeCod: 'brixCaldo2T', blt: 'ANAL', linha: 1190},
    {grp: 'MOAGEM', bmeCod: 'polCaldo2T', blt: 'ANAL', linha: 1200, showBmeBol: false, show: false},
    {grp: 'MOAGEM', bmeCod: 'purezaCaldo2T', blt: 'ANAL', linha: 1210, showBmeBol: false, show: false},

    {grp: 'EFICIÊNCIA', bmeCod: 'efExtMoenda', blt: 'PROD', linha: 5545, iptBmeBol: true},
    {grp: 'EFICIÊNCIA', bmeCod: 'efExtPol', blt: 'PROD', linha: 5540},
    {grp: 'EFICIÊNCIA', bmeCod: 'rtc', blt: 'PROD', linha: 5580},

    {grp: 'PRODUÇÃO', bmeCod: 'flowAcucarScsdia', blt: 'PROD', linha: 3510},
    {grp: 'PRODUÇÃO', bmeCod: 'flowAcucarScsdiaPro', blt: 'PROD', linha: 3510},
    {grp: 'PRODUÇÃO', bmeCod: 'flowEtanolAnidroDia', blt: 'PROD', linha: 1010},
    {grp: 'PRODUÇÃO', bmeCod: 'flowEtanolDestDia', blt: 'PROD', linha: 5010},
    {grp: 'PRODUÇÃO', bmeCod: 'flowEtanolHidDia', blt: 'PROD', linha: 2015},
    {grp: 'PRODUÇÃO', bmeCod: 'flowEtanolNeutroDia', blt: 'PROD', linha: 3020},
    {grp: 'PRODUÇÃO', bmeCod: 'flowEtanolTotal', blt: 'PROD', linha: 5510},
    {grp: 'PRODUÇÃO', bmeCod: 'expEnergiaDia', blt: 'PROD', linha: 8560},

    
    {grp: 'VARIAÇÃO PROCESSO', bmeCod: 'processoFabOntem', blt: 'PROD', linha: 925, iptBme: true, iptBmeBol: true},
    {grp: 'VARIAÇÃO PROCESSO', bmeCod: 'processoFabHoje', blt: 'PROD', linha: 930, iptBme: true, iptBmeBol: true},
    {grp: 'VARIAÇÃO PROCESSO', bmeCod: 'processoDestilariaOntem', blt: 'PROD', linha: 825},
    {grp: 'VARIAÇÃO PROCESSO', bmeCod: 'processoDestilariaHoje', blt: 'PROD', linha: 830},

    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'brixCaldoClarifAcucar', blt: 'ANAL', linha: 2040},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'polCaldoClarifAcucar', blt: 'ANAL', linha: 2050, showBmeBol: false},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'purezaCaldoClarifAcucar', blt: 'ANAL', linha: 2060, showBmeBol: false},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'artCaldoClarifAcucar', blt: 'ANAL', linha: 2070, showBmeBol: false},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'brixCaldoFiltrado', blt: 'ANAL', linha: 2170},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'polCaldoFiltrado', blt: 'ANAL', linha: 2180, show: false, showBmeBol: false},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'purezaCaldoFiltrado', blt: 'ANAL', linha: 2190, show: false, showBmeBol: false},
    {grp: 'TRATAMENTO DE CALDO ÁÇÚCAR', bmeCod: 'grausBaume', blt: 'ANAL', linha: 2300, showBmeBol: false, iptBmeBol: true, iptBme: true},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixXarope', blt: 'ANAL', linha: 2420, iptBmeBol: true},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polXarope', blt: 'ANAL', linha: 2430, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaXarope', blt: 'ANAL', linha: 2440, iptBmeBol: true},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMassaA', blt: 'ANAL', linha: 2460, iptBmeBol: true},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMassaA', blt: 'ANAL', linha: 2470, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMassaA', blt: 'ANAL', linha: 2480},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'concCristaisMassaA', blt: 'ANAL', linha: 2481},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMassaB', blt: 'ANAL', linha: 2500, iptBmeBol: true},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMassaB', blt: 'ANAL', linha: 2510, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMassaB', blt: 'ANAL', linha: 2520},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'concCristaisMassaB', blt: 'ANAL', linha: 2521},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMelARico', blt: 'ANAL', linha: 2540, iptBmeBol: true, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMelARico', blt: 'ANAL', linha: 2550, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMelARico', blt: 'ANAL', linha: 2560, iptBmeBol: true, showBmeBol: false},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMelAPobre', blt: 'ANAL', linha: 2580, iptBmeBol: true, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMelAPobre', blt: 'ANAL', linha: 2590, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMelAPobre', blt: 'ANAL', linha: 2600, iptBmeBol: true, showBmeBol: false},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMagma', blt: 'ANAL', linha: 2620, iptBmeBol: true, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMagma', blt: 'ANAL', linha: 2630, showBmeBol: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMagma', blt: 'ANAL', linha: 2640, iptBmeBol: true, showBmeBol: false},

    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'brixMelFinal', blt: 'ANAL', linha: 2660, iptBmeBol: true},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'polMelFinal', blt: 'ANAL', linha: 2670, showBmeBol: false, show: false},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'purezaMelFinal', blt: 'ANAL', linha: 2680, iptBmeBol: true},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'artMelFinal', blt: 'ANAL', linha: 2681, showBmeBol: false},

   
    
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'rendFabricaAcucarTC', blt: 'PROD', linha: 5565},
    {grp: 'FÁBRICA AÇÚCAR', bmeCod: 'recupSJM', blt: 'PROD', linha: 5585},



    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelFinal2', blt: 'PROD', linha: 6510},
    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelTerceirosComprado', blt: 'PROD', linha: 6515, show: false, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelTerceiros', blt: 'PROD', linha: 6535, iptBmeBol: true},
    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelpEstoque', blt: 'PROD', linha: 10003,  show: false, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelEstoque', blt: 'PROD', linha: 10004,  show: false, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'flowMelConsumido', blt: 'PROD', linha: 10002},

    {grp: 'FERMENTAÇÃO', bmeCod: 'brixMosto', blt: 'ANAL', linha: 1340, iptBmeBol: true},
    {grp: 'FERMENTAÇÃO', bmeCod: 'polMosto', blt: 'ANAL', linha: 1341, show: false, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'purezaMosto', blt: 'ANAL', linha: 1342, show: false, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'artMosto', blt: 'ANAL', linha: 1350, showBmeBol: false},

    {grp: 'FERMENTAÇÃO', bmeCod: 'concEtanolVinhoBruto', blt: 'ANAL', linha: 1470,  showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'concCelVinhoCent', blt: 'ANAL', linha: 1480, showBmeBol: false, show: false},

    {grp: 'FERMENTAÇÃO', bmeCod: 'concCelCreme', blt: 'ANAL', linha: 1520, iptBmeBol: true, showBmeBol: false},
    {grp: 'FERMENTAÇÃO', bmeCod: 'concCelFermTratato', blt: 'ANAL', linha: 1590, showBmeBol: false, show: false},

    {grp: 'DESTILARIA', bmeCod: 'horasEfetivasDestilaria', blt: 'PROD', linha: 810, show: false, showBmeBol: false},
    {grp: 'DESTILARIA', bmeCod: 'flowEtanolTotalCana', blt: 'PROD', linha: 5525, fator: 0.001},
    {grp: 'DESTILARIA', bmeCod: 'flowEtanolTotalMelTerc', blt: 'PROD', linha: 5530, fator: 0.001},

    {grp: 'DESTILARIA', bmeCod: 'flowEtanolTotalCanapTcm', blt: 'PROD', linha: 5535},
    {grp: 'DESTILARIA', bmeCod: 'efFermentacao', blt: 'PROD', linha: 5555, iptBmeBol: true},
    {grp: 'DESTILARIA', bmeCod: 'efDestilaria', blt: 'PROD', linha: 5560},
    {grp: 'DESTILARIA', bmeCod: 'glEtanolHidratado', blt: 'ANAL', linha: 1750, showBmeBol: false},
    {grp: 'DESTILARIA', bmeCod: 'glEtanolAnidro', blt: 'ANAL', linha: 1850, showBmeBol: false},
    {grp: 'DESTILARIA', bmeCod: 'glEtanolNeutro', blt: 'ANAL', linha: 1900, showBmeBol: false},
    {grp: 'DESTILARIA', bmeCod: 'glEtanolDestilado', blt: 'ANAL', linha: 1940, showBmeBol: false},
    {grp: 'DESTILARIA', bmeCod: 'mixEtanolAnidro', blt: 'PROD', linha: 0, showBmeBol: false, iptBmeBol: true, show: false},
    {grp: 'DESTILARIA', bmeCod: 'mixEtanolNeutro', blt: 'PROD', linha: 0, showBmeBol: false, iptBmeBol: true, show: false},
    {grp: 'DESTILARIA', bmeCod: 'mixEtanolDest', blt: 'PROD', linha: 0, showBmeBol: false, iptBmeBol: true, show: false},

    {grp: 'ENERGIA', bmeCod: 'energiaGerada', blt: 'PROD', linha: 8530},
    {grp: 'ENERGIA', bmeCod: 'consEnergiaDia', blt: 'PROD', linha: 8570},
    {grp: 'ENERGIA', bmeCod: 'consEspEnergiaTC', blt: 'PROD', linha: 10001},

    {grp: 'VAPOR', bmeCod: 'gerVaporVACaldeiras', blt: 'PROD', linha: 9570, sum: 9530},
    {grp: 'VAPOR', bmeCod: 'consVaporVATgCdVA', blt: 'PROD', linha: 9530},
    {grp: 'VAPOR', bmeCod: 'consVaporVARebVaVm', blt: 'PROD', linha: 9560},
    {grp: 'VAPOR', bmeCod: 'consVaporTC', blt: 'PROD', linha: 9580},
   
    {grp: 'OUTROS', subGrp: 'TORTA', bmeCod: 'flowTorta', blt: 'PROD', linha: 7090, showBmeBol: false},
    {grp: 'OUTROS', subGrp: 'TORTA', bmeCod: 'prodTorta', blt: 'PROD', linha: 7100, iptBme: true, iptBmeBol: true},
    
]

module.exports = {
    BltsXBmeVars
}