import React, { useState, useContext, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab } from "@mui/material";
import { stdValue2 } from '../../../../Resources/setNumber';
import AnalisesContext from '../../../../ANALISES/AnalisesContext';
import GlobalStateContext from '../../../../components/GlobalStateContext';

const Boletim = () => {
  const {bltProd, bltAna, setBltProd, setBltAna} = useContext(AnalisesContext)
  const {site} = useContext(GlobalStateContext)
  const [tabIndex, setTabIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(0);
  const [organizedData, setOrganizedData] = useState({});

  const linesSecBltProd = [10, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 5500, 6000, 6500, 7000, 
    7500, 8000, 8500, 9000, 9090, 9500, 10000];

  const linesSecBltAna = [960, 1030, 1110, 1180, 1250, 1290, 1330, 1390, 1450, 1500, 1550, 1590, 1670, 1730, 1780, 1830, 1880, 1930, 2010, 2020, 2030, 
    2080, 2120, 2160, 2200, 2240, 2290, 2400, 2410, 2450, 2490, 2530, 2570, 2610, 2650, 2690, 2730, 2770, 2830, 2900, 3000, 3010, 
    3030, 3060, 3085, 3090, 3105, 3120, 3135];

  // Organiza os dados em seções
  const organizeData = (data, secs) => {
    if (!data) return {};
    let sections = {};
    let currentSection = "";
  
    data.forEach((item) => {
      if (secs.includes(item.LINHA)) {
        currentSection = item.DESCRICAO;
        sections[currentSection] = [];
      } else {
        if (currentSection) {
          sections[currentSection].push(item);
        }
      }
    });
  
    return sections;
  };

  // Buscar dados do localStorage na montagem do componente
  useEffect(() => {
    if (site) {
      if (!bltProd) {
        const savedBltProd = localStorage.getItem(`bltProd-${site}`);
        if (savedBltProd) {
          console.log('Carregando bltProd do localStorage');
          setBltProd(JSON.parse(savedBltProd));
        }
      }
      
      if (!bltAna) {
        const savedBltAna = localStorage.getItem(`bltAna-${site}`);
        if (savedBltAna) {
          console.log('Carregando bltAna do localStorage');
          setBltAna(JSON.parse(savedBltAna));
        }
      }
    }
  }, [site, setBltProd, setBltAna]);

  // Atualizar dados organizados quando mudar tab ou dados
  useEffect(() => {
    const data = tabIndex === 0 ? bltProd : bltAna;
    const secs = tabIndex === 0 ? linesSecBltProd : linesSecBltAna;
    const orgData = organizeData(data, secs);
    setOrganizedData(orgData);
  }, [tabIndex, bltProd, bltAna]);

  const handleTabChange = (_, newIndex) => {
    setPrevIndex(tabIndex);
    setTabIndex(newIndex);
  };

  return (
    bltProd && bltAna ? (
      <>
        <Tabs value={tabIndex} onChange={handleTabChange} centered>
          <Tab label="BOLETIM PRODUÇÃO" sx={{ maxWidth: 200 }}/>
          <Tab label="BOLETIM ANÁLISES" sx={{ maxWidth: 200 }}/>
        </Tabs>

        <TableContainer component={Paper} sx={{ maxHeight: "calc(82vh - 100px)", overflowY: "auto", margin: "auto", mt: 2 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow sx={{ backgroundColor: "#e0e0e0"}}>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", paddingLeft: '10px', lineHeight: "2" }}>Descrição</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Unidade</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Dia</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Semana</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Mês</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Ano</TableCell>
                <TableCell sx={{ fontWeight: "bold", padding: "1px", lineHeight: "2" }}>Safra Anterior</TableCell>
              </TableRow>
            </TableHead>
            
            <TableBody>
              {Object.entries(organizedData).map(([section, items]) => (
                <React.Fragment key={section}>
                  <TableRow>
                    <TableCell colSpan={7} sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", padding: '1px', paddingLeft: '10px', fontSize: '12px', position: 'sticky', top: '30px'}}>
                      {section}
                    </TableCell>
                  </TableRow>

                  {items.map((item, index) => (
                    <TableRow key={index} sx={{height: '20px', minHeight: '20px'}}>
                      <TableCell sx={{ padding: "1px", paddingLeft: '10px', lineHeight: "1", fontSize: '12px' }}>{item.DESCRICAO}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.UNIDADE || "-"}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.DIA !== undefined ? stdValue2(item.DIA) : "-"}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.SEMANA !== undefined ? stdValue2(item.SEMANA) : "-"}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.MES !== undefined ? stdValue2(item.MES) : "-"}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.ANO !== undefined ? stdValue2(item.ANO) : "-"}</TableCell>
                      <TableCell sx={{ padding: "1px", lineHeight: "1", fontSize: '12px' }}>{item.SAFRAANTERIOR !== undefined ? stdValue2(item.SAFRAANTERIOR) : "-"}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : null
  );
};

export default Boletim;