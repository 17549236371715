import TratAcFlx from './fluxogramas/tratFabrica7Web.png'
import TratEtFlx from './fluxogramas/tratEtanol8Web.png'
import TratEtFlx2 from './fluxogramas/tratEtanol2CVS.png'
import FiltLodoFlx from './fluxogramas/filtFlx.png'
import EvapFlx from './fluxogramas/evaporacao8Web.png'
import EvapFlx2 from './fluxogramas/evaporacao84fWeb.png'
import FermFlx from './fluxogramas/fermentacao8Web.png'
import DestFlx from './fluxogramas/destilaria8Web.png'
import flotFlx from './fluxogramas/flotadorWeb.png'
import FabFlx from './fluxogramas/fabrica2MWeb.png'
import bhFlx from './fluxogramas/BH8Web.png'
import vapFlx from './fluxogramas/vapor8Web.png'
import extracaoFlx from './fluxogramas/difusor8.1Web.png'
import blank from './fluxogramas/blank.png'

export function getUsCevasaFlxsData(area, data){
    switch (area){
      case 'tratAcucar': return tratAcucar
      case 'tratEtanol': return tratEtanol(data)
      case 'filtLodo': return filtLodo
      case 'evaporacao': 
      if (data['qtdeEfeitos'].valor === 1){
        console.log('qtdeEfeitos', data['qtdeEfeitos'].valor)
        return evaporacao
       }else{
        console.log('n qtdeEfeitos', data['qtdeEfeitos'].valor)
        return evaporacao4
       }
        
      case 'fermentacao': return fermentacao
      case 'destilaria': return destilaria
      case 'flotador': return flotador
      case 'fabrica': return fabrica
      case 'vapor': return vapor
      case 'balHidrico': return bh
      case 'blank': return Blank
      default: return extracao
      
    }
  }

const Blank = {
  imagem: blank,
  flows: [
    {flow: '', props: ['flowVinhaca'], x: 29 , y: 3, align: 'left', valign: 'flex-start'
    }
  ]
}
 
const extracao = {
  imagem: extracaoFlx,
  flows: [
    {flow: 'Cana', props: ['flowCana','polCana', 'purezaCana', 'fibraCana', 'arCana', 'artCana', 'brixCana', 'artMassaCana', 'tempCana'], x: 3, y: 16, align: 'left', valign: 'flex-start'},
    {flow: 'VV1', props: ['consVV1TotalDifusor'], x: 116, y: 1.6, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1AquecDirDifusor'], x: 84.5, y: 2.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1BorbDifusor'], x: 72, y: 2.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1AquecDifusor'], x: 57, y: 1.8, align: 'left', valign: 'flex-start'},

    {flow: 'VV2', props: ['consVV2TotalDifusor'], x: 116, y: 5.7, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2AquecDirDifusor'], x: 87, y: 6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2BorbDifusor'], x: 74.5, y: 6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2AquecDifusor'], x: 57, y: 5.7, align: 'left', valign: 'flex-start'},

    {flow: 'CDVG', props: ['flowCondVGAqDifusor'], x: 3, y: 10.5, align: 'left', valign: 'flex-end'},

    {flow: '', props: ['flowCaldoEscaldante', 'tempCaldoEscaldante'], x: 67.5, y: 24, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqDifusor'], x: 46.5, y: 13, align: 'left', valign: 'flex-start'},

    {flow: '', props: ['flowEmbAqDirDifusor', 'tempEmbeb'], x: 84.5, y: 12, align: 'left', valign: 'flex-start'},

    {flow: 'Embebição', props: ['flowEmbeb','fibraEmbeb', 'tempEmbeb'], x: 116, y: 11.6, align: 'right', valign: 'flex-start'},
    {flow: 'Bagacilho', props: ['flowBagacilho'], x: 116, y: 35.3, align: 'right', valign: 'flex-end'},
    {flow: 'Bagaço', props: ['flowBagacoToBoilers'], x: 116, y: 28.7, align: 'right', valign: 'flex-end'},
    {flow: 'Limp. Peneira', props: ['consCondVGLimpPeneira'], x: 37.5, y: 33.7, align: 'left', valign: 'flex-start'},
    {flow: 'Lav. Corrente', props: ['consAguaBrutaLavCorrDifusor'], x: 80, y: 25, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowBagaco', 'fibraBagaco', 'polBagaco', 'umidBagaco', 'artBagaco', 'artMassaBagaco'], x: 106, y: 31, align: 'left', valign: 'flex-end'},
    // {flow: 'Caldo 1ºT', props: ['flowCaldo1T', 'brixCaldo1T', 'artMassaCaldo1T', 'tempCaldo1T'], x: 26.5, y: 27.4, align: 'right', valign: 'flex-end'},
    {flow: 'Caldo Misto', props: ['flowCaldoMisto', 'brixCaldoMisto', 'artMassaCaldoMisto', 'tempCaldoMisto'], x: 61.6, y: 42.6, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Açúcar', props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'artMassaCaldoAcucar', 'tempCaldoAcucar'], x: 3, y: 57, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Etanol', props: ['flowCaldoEtanol', 'brixCaldoEtanol', 'artMassaCaldoEtanol', 'tempCaldoEtanol'], x: 3, y: 68, align: 'left', valign: 'flex-end'},
    {flow: 'P.Lav. Cana', props: ['perdaArtLavagemCana', 'perdaMassaArtLavagemCana'], x: 21.5, y: 22, align: 'left', valign: 'flex-end'},
    {flow: 'P. Água Res.', props: ['perdaArtAguaResiduaria', 'perdaMassaArtAguaResiduaria'], x: 21, y: 50.6, align: 'left', valign: 'flex-end'},
    {flow: 'P.Inteterm.', props: ['perdaArtIndeterminda', 'perdaMassaArtIndeterminda'], x: 21, y:41.5, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoSecToAcucar'], x: 71, y: 54, align: 'center', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoPrimToEtanol'], x: 71, y: 57.8, align: 'center', valign: 'flex-end'},
    {flow: 'VM', props: ['flowVaporVMM1'], x: 3, y: 74.4, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['gerVaporVEM1', 'tempVaporVEM1'], x: 116, y: 85, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMPic1M1'], x: 46.5, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMPic1M1', 'tempVaporVEPic1M1'], x: 49, y: 85, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMDesfM1'], x: 70.3, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMDesfM1', 'tempVaporVEDesfM1'], x: 73, y: 85, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVaporVMTerM1'], x: 95.2, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVaporVMTerM1', 'tempVaporVETerM1'], x: 98, y: 85, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Filtrado', props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'], x: 116, y: 56.3, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoFiltToAcucar'], x: 56.8, y: 50, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoFiltToEtanol'], x: 73, y: 50, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoMistoToAcucar'], x: 53.5, y: 46, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoMistoToEtanol'], x: 69.8, y: 46, align: 'left', valign: 'flex-end'},
  ]
}

const extracao2 = {
  imagem: extracaoFlx,
  flows: [
    {flow: 'Cana', props: ['flowCana','polCana', 'purezaCana', 'fibraCana', 'arCana', 'artCana', 'brixCana', 'artMassaCana', 'tempCana'], x: 3, y: 16, align: 'left', valign: 'flex-start'},
    {flow: 'VV1', props: ['consVV1TotalDifusor'], x: 116, y: 2, align: 'right', valign: 'flex-start'},

    {flow: '', props: ['consVaporVV1AquecDirDifusor'], x: 84.5, y: 3, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1BorbDifusor'], x: 72, y: 3, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1AquecDifusor'], x: 53.5, y: 3, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['gerCondVGAqVV1Difusor'], x: 3, y: 9, align: 'left', valign: 'flex-end'},

    {flow: '', props: ['flowCaldoEscaldante', 'tempCaldoEscaldante'], x: 67.5, y: 24, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqDifusor'], x: 46.5, y: 12.5, align: 'left', valign: 'flex-start'},

    {flow: '', props: ['flowEmbAqDirDifusor', 'tempEmbeb'], x: 84.5, y: 12, align: 'left', valign: 'flex-start'},

    {flow: 'Embebição', props: ['flowEmbeb','fibraEmbeb', 'tempEmbeb'], x: 116, y: 10.2, align: 'right', valign: 'flex-start'},
    {flow: 'Bagacilho', props: ['flowBagacilho'], x: 116, y: 35.3, align: 'right', valign: 'flex-end'},
    {flow: 'Bagaço', props: ['flowBagacoToBoilers'], x: 116, y: 28.7, align: 'right', valign: 'flex-end'},
    {flow: 'Limp. Peneira', props: ['consCondVGLimpPeneira'], x: 37.5, y: 33.7, align: 'left', valign: 'flex-start'},
    {flow: 'Lav. Corrente', props: ['consAguaBrutaLavCorrDifusor'], x: 80, y: 25, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowBagaco', 'fibraBagaco', 'polBagaco', 'umidBagaco', 'artBagaco', 'artMassaBagaco'], x: 106, y: 31, align: 'left', valign: 'flex-end'},
    // {flow: 'Caldo 1ºT', props: ['flowCaldo1T', 'brixCaldo1T', 'artMassaCaldo1T', 'tempCaldo1T'], x: 26.5, y: 27.4, align: 'right', valign: 'flex-end'},
    {flow: 'Caldo Misto', props: ['flowCaldoMisto', 'brixCaldoMisto', 'artMassaCaldoMisto', 'tempCaldoMisto'], x: 61.6, y: 42.6, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Açúcar', props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'artMassaCaldoAcucar', 'tempCaldoAcucar'], x: 3, y: 57, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Etanol', props: ['flowCaldoEtanol', 'brixCaldoEtanol', 'artMassaCaldoEtanol', 'tempCaldoEtanol'], x: 3, y: 68, align: 'left', valign: 'flex-end'},
    {flow: 'P.Lav. Cana', props: ['perdaArtLavagemCana', 'perdaMassaArtLavagemCana'], x: 21.5, y: 22, align: 'left', valign: 'flex-end'},
    {flow: 'P. Água Res.', props: ['perdaArtAguaResiduaria', 'perdaMassaArtAguaResiduaria'], x: 21, y: 50.6, align: 'left', valign: 'flex-end'},
    {flow: 'P.Inteterm.', props: ['perdaArtIndeterminda', 'perdaMassaArtIndeterminda'], x: 21, y:41.5, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoSecToAcucar'], x: 71, y: 54, align: 'center', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoPrimToEtanol'], x: 71, y: 57.8, align: 'center', valign: 'flex-end'},
    {flow: 'VM', props: ['flowVaporVMM1'], x: 3, y: 74.4, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['gerVaporVEM1', 'tempVaporVEM1'], x: 116, y: 85, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMPic1M1'], x: 46.5, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMPic1M1', 'tempVaporVEPic1M1'], x: 49, y: 85, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMDesfM1'], x: 70.3, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consVaporVMDesfM1', 'tempVaporVEDesfM1'], x: 73, y: 85, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVaporVMTerM1'], x: 95.2, y: 73.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVaporVMTerM1', 'tempVaporVETerM1'], x: 98, y: 85, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Filtrado', props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'], x: 116, y: 56.3, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoFiltToAcucar'], x: 56.8, y: 50, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoFiltToEtanol'], x: 73, y: 50, align: 'left', valign: 'flex-end'},
  ]
}

const tratAcucar = {
  imagem: TratAcFlx,
  flows: [
    // {flow: 'Mosto', props: ['flowMosto', 'tempMosto'], x: 28.8 , y: 3, align: 'left', valign: 'flex-start'},
    // {flow: 'Mosto Reg.', props: ['flowMosto', 'tempMostoReg'], x: 19, y: 23.5, align: 'left', valign: 'flex-start'},
    // {flow: '', props: ['tempOutCaldoRegCaldoMosto'], x: 30.5 , y: 15.5, align: 'left', valign: 'flex-start'},
    {flow: 'Vinhaça', props: ['flowVinhaca', 'tempVinhaca'], x: 40.2 , y: 3, align: 'left', valign: 'flex-start'},
    {flow: 'Vinhaça Reg.', props: ['flowVinhaca', 'tempVinhacaReg'], x: 30.7, y: 23.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutCaldoRegCaldoVinhaca'], x: 45, y: 15.5, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG Reg.', props: ['flowCondVegetal', 'tempCondReg'], x: 59.5, y: 23.5, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['flowCondVegetal', 'tempCondVG'], x: 70, y: 3, align: 'left', valign: 'flex-start'},

    {flow: 'Cond. Veg.', props: ['gerCondVGAqVFLAcucar'], x: 86, y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: 'VFL', props: ['consVaporVFLAqAcucar'], x: 95.5, y: 3, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqVFLAcucar'], x: 100, y: 13.2, align: 'left', valign: 'flex-start'},

    {flow: '', props: ['tempOutCaldoRegCaldoCond'], x: 74, y: 15.5, align: 'left', valign: 'flex-start'},
    // {flow: 'Borra Flotador', props: ['flowAguaDoceAndBorrapDos', 'brixAguaDoceAndBorrapDos', 'tempAguaDoceAndBorrapDos'], x: 117.5 , y: 41.5, align: 'right', valign: 'flex-end'},
    {flow: 'Leite de Cal', props: ['flowLeiteCalAcucar'], x: 117.5 , y: 47, align: 'right', valign: 'flex-start'},

    {flow: 'VV3', props: ['consVaporVV3AqAcucar'], x: 95.4, y: 32.3, align: 'left', valign: 'flex-start'},
    {flow: 'Cond. Veg.', props: ['gerCondVGAqVV3Acucar'], x: 86, y: 53.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqVV3Acucar'], x: 71, y: 42.8, align: 'center', valign: 'flex-start'},
    
    {flow: 'VV2', props: ['consVaporVV2AqAcucar'], x: 69, y: 32.3, align: 'left', valign: 'flex-start'},
    {flow: 'Cond. Veg.', props: ['gerCondVGAqVV2Acucar'], x: 60, y: 53.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqVV2Acucar'], x: 42, y: 42.8, align: 'center', valign: 'flex-start'},
    {flow: 'VV1', props: ['consVaporVV1AqAcucar'], x: 40, y: 32.3, align: 'left', valign: 'flex-start'},
    {flow: 'Cond. Veg.', props: ['gerCondVGAqVV1Acucar'], x: 30.4, y: 53.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempOutAqVV1Acucar'], x: 17, y: 42.8, align: 'center', valign: 'flex-start'},
    {flow: 'Sol. Polimero', props: ['flowAguaPolimeroAcucar'], x: 1.8, y: 77, align: 'left', valign: 'flex-end'},
    {flow: 'Vapor Flash', props: ['flowVapFlashAcucar'], x: 12, y: 61.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoDecAcucar', 'brixCaldoDecAcucar', 'tempCaldoDecAcucar'], x: 60, y: 70, align: 'left', valign: 'flex-end'},
    {flow: 'VV1', props: ['consVaporVV1AqCCAcucar'], x: 116, y: 60, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['gerCondVGAqCCAcucar'], x: 106.5, y: 83, align: 'left', valign: 'flex-end'},
    {flow: 'Caldo Açúcar', props: ['flowCaldoAcucar', 'brixCaldoAcucar', 'tempCaldoAcucar', 'artMassaCaldoAcucar'], x: 1.5, y: 13, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldoSulfAcucar', 'brixCaldoSulfAcucar', 'tempCaldoSulfAcucar'], x:115, y: 25, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldoAquecAcucar', 'brixCaldoAquecAcucar', 'tempCaldoAquecAcucar'], x: 6, y: 52, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldoDosAcucar', 'brixCaldoDosAcucar', 'tempCaldoDosAcucar', 'artMassaCaldoDosAcucar'], x: 96.5, y: 43, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoFlashAcucar', 'brixCaldoFlashAcucar', 'tempCaldoFlashAcucar'], x: 22, y: 70.5, align: 'center', valign: 'flex-end'},
    {flow: 'Caldo Clarificado', props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'artCaldoClarifAcucar', 'tempCaldoClarifAqAcucar', 'artMassaCaldoClarifAcucar',],
      x: 117.5, y: 73, align: 'right', valign: 'flex-start'},
    {flow: 'Lodo Açúcar', props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'], x: 1.7, y: 85, align: 'left', valign: 'flex-end'},
    
    {flow: 'C. Pré-Evap. Etanol', props: ['flowCPEEtanolToAcucar', 'brixSaidaPreEvapEtanol', 'tempCPEEtanolReg', 'artMassaCPEEtanolToAcucar'], x: 117.5, y: 41.5, align: 'right', valign: 'flex-end'},
    // {flow: 'CC Etanol', props: ['flowCCEtanolToAc', 'brixCCEtanolToAc', 'tempCCEtanolToAc', 'artMassaCCEtanolToAc'], x: 81, y: 85.5, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoClarifAcucar', 'brixCaldoClarifAcucar', 'tempCaldoClarifAcucar'], x: 90, y: 70.5, align: 'center', valign: 'flex-end'
    // }
  ]
}

function tratEtanol(data){
  return {
    imagem: data['opAquecVV2Etanol'].valor === 0 ? TratEtFlx2 : TratEtFlx,
    flows: [
    {flow: 'Caldo Etanol', props: ['flowCaldoEtanol','brixCaldoEtanol','tempCaldoEtanol','artMassaCaldoEtanol'], x: 3, y: 10.5, align: 'left', valign: 'flex-end'},
    // {flow: 'VFL', props: ['flowFlashTqCCEtanol'], x: 67.5, y: 7, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldoRegEtanol', 'brixCaldoRegEtanol', 'tempCaldoRegEtanol'], x: 99, y: 29, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoAquecEtanol','brixCaldoAquecEtanol','tempOutAqVV2Etanol'], x: 60, y: 38.5, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['tempOutAqVV1Etanol'], x: 20, y: 38.5, align: 'center', valign: 'flex-end'},

    {flow: 'VV2', props: ['consVaporVV2AqEtanol'], x: data['opAquecVV2Etanol'].valor === 0 ? 82.5 : 90.2, y: 28, align: 'left', valign: 'flex-start'},

    {flow: data['opAquecVV2Etanol'].valor !== 0 ? 'Cond. Veg.' : '', props: data['opAquecVV2Etanol'].valor !== 0 ? ['gerCondVGAqVV2Etanol'] : [], x: 80.8, y: 50.5, align: 'left', valign: 'flex-start'},
    {flow: 'VV1', props: ['consVaporVV1AqEtanol'], x: 55.3, y: 28, align: 'left', valign: 'flex-start'},
    {flow: 'Cond. Veg.', props: ['gerCondVGAqVV1Etanol'], x: 45.6, y: 50.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldoAquecEtanol','brixCaldoAquecEtanol','tempCaldoAquecEtanol'], x: 12.5, y: 49, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldoFlashEtanol','brixCaldoFlashEtanol','tempCaldoFlashEtanol'], x: 40, y: 67.2, align: 'center', valign: 'flex-end'},
    {flow: 'Caldo Etanol p/ Pré', props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol', 'polCaldoClarifEtanol', 'artCaldoClarifEtanol', 'tempCaldoClarifEtanol', 'artMassaCaldoClarifEtanol'], x: 116, y: 69.5, align: 'right', valign: 'flex-start'},
    {flow: 'Lodo Etanol', props: ['flowLodoEtanol','brixLodoEtanol','artMassaLodoEtanol'], x: 3, y: 84, align: 'left', valign: 'flex-end'},
    {flow: 'Sol. Polimero', props: ['flowAguaPolimeroEtanol'], x: 3, y: 74, align: 'left', valign: 'flex-end'},
    {flow: 'Vapor Flash', props: ['flowVapFlashEtanol'], x: 21.6, y: 58.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldoDecEtanol', 'brixCaldoDecEtanol', 'tempCaldoDecEtanol'], x: 80, y: 67.2, align: 'center', valign: 'flex-end'},
    {flow: 'C. Pré-Evap. Etanol', props: ['flowSaidaPreEvapEtanol', 'brixSaidaPreEvapEtanol', 'tempCaldoSaidaPreEvapEtanol'], x: 3, y: 23, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowSaidaPreEvapEtanol', 'brixSaidaPreEvapEtanol', 'tempCPEEtanolReg'], x: 75, y: 10.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['tempCaldoRegEtanol'], x: 75, y: 15.5, align: 'left', valign: 'flex-end'},
    {flow: 'p/ Dosagem Açúcar', props: ['flowCPEEtanolToAcucar', 'brixSaidaPreEvapEtanol', 'tempCPEEtanolReg', 'artMassaCPEEtanolToAcucar'], x: 116, y: 10.5, align: 'right', valign: 'flex-end'},
    {flow: 'p/ Mosto', props: ['flowCaldoToFerm', 'brixCaldoToFerm', 'tempCaldoToFerm', 'artMassaCaldoToFerm'], x: 116, y: 22.3, align: 'right', valign: 'flex-start'},
    ]
  }
}


const filtLodo = {
  imagem: FiltLodoFlx,
  flows: [
    {flow: 'Bagacilho', props: ['flowBagacilho'], x: 115.5 , y: 37.8, align: 'right', valign: 'flex-end'},
    {flow: 'Água Emb.', props: ['flowAguaEmbebicaoFiltroRot'], x: 115.5, y: 52.4, align: 'right', valign: 'flex-end'},
    {flow: 'Água Emb.', props: ['flowAguaEmbebicaoFiltroPre'], x: 3, y: 42.8, align: 'left', valign: 'flex-end'},
    {flow: 'Água Lav.', props: ['flowAguaLavTelasFiltroPre'], x: 3, y: 51, align: 'left', valign: 'flex-end'},
    {flow: 'Águas Resid.', props: ['flowAguaLavTelasFiltroPre'], x: 3, y: 58.5, align: 'left', valign: 'flex-end'},
    {flow: 'Lodo Etanol', props: ['flowLodoEtanol', 'brixLodoEtanol', 'artMassaLodoEtanol'], x: 115.5, y: 6.6, align: 'right', valign: 'flex-start'},
    {flow: 'Lodo Açúcar', props: ['flowLodoAcucar', 'brixLodoAcucar', 'artMassaLodoAcucar'], x: 3, y: 6.6, align: 'left', valign: 'flex-start'},
    {flow: 'Caldo Filtrado', props: ['flowCaldoFiltrado', 'brixCaldoFiltrado', 'tempCaldoFiltrado', 'artMassaCaldoFiltrado'], x: 3, y: 72.5, align: 'left', valign: 'flex-end'},
    {flow: 'Torta', props: ['flowTorta','artTorta', 'artMassaTorta'], x: 3, y: 81.6, align: 'left', valign: 'flex-end'},
    {flow: 'Lodo', props: ['flowLodo','brixLodo', 'artMassaLodo'], x: 68, y: 6.6, align: 'left', valign: 'flex-start'
    }
  ]
}

const evaporacao = {
  imagem: EvapFlx,
  flows: [
    {flow: 'Sangria VV1', props: ['sangriaVV1'], x: 39 , y: 7, align: 'left', valign: 'flex-start'},
    {flow: 'Sangria VV2', props: ['sangriaVV2'], x: 61.2 , y: 7, align: 'left', valign: 'flex-start'},
    {flow: 'Sangria VV3', props: ['sangriaVV3'], x: 81.4 , y: 7, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV1'], x: 31.5 , y: 24, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV2'], x: 51.5 , y: 22, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV3'], x: 71.5 , y: 22, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV4'], x: 90.5 , y: 22, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV5'], x: 109 , y: 22, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldo1EfAc', 'brixCaldo1EfAc'], x: 31 , y: 48, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldo2Ef', 'brixCaldo2EfAc'], x: 50 , y: 48, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldo3Ef', 'brixCaldo3EfAc'], x: 69.5 , y: 48, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['flowCaldo4Ef', 'brixCaldo4EfAc'], x: 88.5 , y: 48, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['flowXarope', 'brixXarope'], x: 107.5 , y: 48, align: 'left', valign: 'flex-end'},
    {flow: 'Xarope p/ Fáb.', props: ['flowXaropeToFab','brixXarope'], x: 116, y: 48, align: 'right', valign: 'flex-end'},
    {flow: 'Xarope p/ Ferm.', props: ['flowXaropeToFerm','brixXarope'], x: 116, y: 58.5, align: 'right', valign: 'flex-end'},
    {flow: 'Vapor Escape', props: ['consVaporVEEvapAc'], x: 3, y: 32, align: 'left', valign: 'flex-start'},
    {flow: 'Cond. Escape', props: ['gerCondVEEvapAc'], x: 116, y: 72, align: 'right', valign: 'flex-end'},
    {flow: 'CDVG', props: ['gerCondVGEvapAc'], x: 116, y: 39.5, align: 'right', valign: 'flex-end'},
    {flow: 'CDV1', props: ['gerCondVG1EvapAc'], x: 116, y: 62.5, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['taxaEvapPreEvapAcucar', 'areaOpPreEvapAcucar'], x: 17.5, y: 20.5, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap2EfEvapAcucar', 'areaOp2EfEvapAcucar'], x: 40, y: 20.5, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap3EfEvapAcucar', 'areaOp3EfEvapAcucar'], x: 59.5, y: 20.5, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap4EfEvapAcucar', 'areaOp4EfEvapAcucar'], x: 79.5, y: 20.5, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap5EfEvapAcucar', 'areaOp5EfEvapAcucar'], x: 98.5, y: 20.5, align: 'center', valign: 'flex-end'},
    {flow: 'Caldo Cl. Açúcar', props: ['flowCaldoClarifAcucar','brixCaldoClarifAcucar','artCaldoClarifAcucar','tempCaldoClarifAqAcucar','artMassaCaldoClarifAcucar'], x: 3, y: 48, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaEvapTorreFab'], x: 116, y: 17.4, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaQuenteEvapTorreFab'], x: 116, y: 31.4, align: 'right', valign: 'flex-start'},

    {flow: 'Caldo Etanol', props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol', 'polCaldoClarifEtanol', 'artCaldoClarifEtanol', 'tempCaldoClarifEtanol', 'artMassaCaldoClarifEtanol'], x: 3, y: 82.5, align: 'left', valign: 'flex-end'},
    {flow: 'Vapor Escape', props: ['consVaporVEPreEvapEtanol'], x: 3, y: 68.5, align: 'left', valign: 'flex-end'},
    {flow: 'C. Pré-Evap. Etanol', props: ['flowSaidaPreEvapEtanol', 'brixSaidaPreEvapEtanol', 'tempCaldoSaidaPreEvapEtanol'], x: 116, y: 82.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVV1PreEvapEtanol'], x: 31.5 , y: 58.2, align: 'left', valign: 'flex-start'},

    {flow: '', props: ['taxaEvapPreEvapEtanol', 'areaOpPreEvapEtanol'], x: 17.5, y: 55, align: 'center', valign: 'flex-end'},
  ]
}

// setValue(user, 'flowEntPreEvapEtanol', flowEntPreEt)
// setValue(user, 'flowSaidaPreEvapEtanol', flowCaldoSaidaPreEt)
// setValue(user, 'flowVV1PreEvapEtanol', flowVV1PreEt)
// setValue(user, 'consVaporVEPreEvapEtanol', consVEPreEt)

// setValue(user, 'tempCaldoSaidaPreEvapEtanol', tempVV1)
// setValue(user, 'taxaEvapPreEvapEtanol', taxaEvapPreEvapEtanol)
// setValue(user, 'flowVV1PreEvapEtanol', flowVV1PreEt)

const evaporacao4 = {
  imagem: EvapFlx2,
  flows: [
    {flow: 'Sangria VV1', props: ['sangriaVV1'], x: 48.4 , y: 5.5, align: 'left', valign: 'flex-start'},
    {flow: 'Sangria VV2', props: ['sangriaVV2'], x: 71.8 , y: 5.5, align: 'left', valign: 'flex-start'},
    {flow: 'Sangria VV3', props: ['sangriaVV3'], x: 92.4 , y: 5.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV1'], x: 39 , y: 23, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV2'], x: 63 , y: 21, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV3'], x: 83 , y: 21, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVV4'], x: 105 , y: 21, align: 'left', valign: 'flex-start'},
    // {flow: '', props: ['flowVV5'], x: 109 , y: 25, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowCaldo1EfAc', 'brixCaldo1EfAc'], x: 42 , y: 47, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldo2Ef', 'brixCaldo2EfAc'], x: 61 , y: 47, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowCaldo3Ef', 'brixCaldo3EfAc'], x: 80.5 , y: 47, align: 'center', valign: 'flex-end'},
    // {flow: '', props: ['flowCaldo4Ef', 'brixCaldo4EfAc'], x: 88.5 , y: 51, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['flowXarope', 'brixXarope'], x: 99 , y: 47, align: 'left', valign: 'flex-end'},
    {flow: 'Xarope p/ Fáb.', props: ['flowXaropeToFab','brixXarope'], x: 116, y: 47, align: 'right', valign: 'flex-end'},
    {flow: 'Xarope p/ Ferm.', props: ['flowXaropeToFerm','brixXarope'], x: 116, y: 61.5, align: 'right', valign: 'flex-end'},
    {flow: 'Vapor Escape', props: ['consVaporVEEvapAc'], x: 3, y: 32.7, align: 'left', valign: 'flex-end'},
    {flow: 'Cond. Escape', props: ['gerCondVEEvapAc'], x: 116, y: 73, align: 'right', valign: 'flex-end'},
    {flow: 'CDVG', props: ['gerCondVGEvapAc'], x: 116, y: 38.2, align: 'right', valign: 'flex-end'},
    // {flow: 'CDV1', props: ['gerCondVG1EvapAc'], x: 116, y: 70.2, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['taxaEvapPreEvapAcucar', 'areaOpPreEvapAcucar'], x: 24.5, y: 19, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap2EfEvapAcucar', 'areaOp2EfEvapAcucar'], x: 51, y: 19, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap3EfEvapAcucar', 'areaOp3EfEvapAcucar'], x: 70, y: 19, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['taxaEvap4EfEvapAcucar', 'areaOp4EfEvapAcucar'], x: 90, y: 19, align: 'center', valign: 'flex-end'},
    // {flow: '', props: ['taxaEvap5EfEvapAcucar', 'areaOp5EfEvapAcucar'], x: 98.5, y: 23.5, align: 'center', valign: 'flex-end'},
    {flow: 'Caldo Cl. Açúcar', props: ['flowCaldoClarifAcucar','brixCaldoClarifAcucar','artCaldoClarifAcucar','tempCaldoClarifAqAcucar','artMassaCaldoClarifAcucar'], x: 3, y: 46.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaEvapTorreFab'], x: 116, y: 16.2, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaQuenteEvapTorreFab'], x: 116, y: 27.6, align: 'right', valign: 'flex-start'},

    {flow: 'Caldo Etanol', props: ['flowCaldoClarifEtanol','brixCaldoClarifEtanol', 'polCaldoClarifEtanol', 'artCaldoClarifEtanol', 'tempCaldoClarifEtanol', 'artMassaCaldoClarifEtanol'], x: 3, y: 83.5, align: 'left', valign: 'flex-end'},
    {flow: 'Vapor Escape', props: ['consVaporVEPreEvapEtanol'], x: 3, y: 69.2, align: 'left', valign: 'flex-end'},
    {flow: 'C. Pré-Evap. Etanol', props: ['flowSaidaPreEvapEtanol', 'brixSaidaPreEvapEtanol', 'tempCaldoSaidaPreEvapEtanol'], x: 116, y: 83.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVV1PreEvapEtanol'], x: 40 , y: 59.4, align: 'left', valign: 'flex-start'},

    {flow: '', props: ['taxaEvapPreEvapEtanol', 'areaOpPreEvapEtanol'], x: 23.5, y: 55.5, align: 'center', valign: 'flex-end'},
  ]
}

const fermentacao = {
  imagem: FermFlx,
  flows: [
    {flow: 'Xarope', props: ['flowXaropeToFerm', 'brixXarope', 'tempXarope'], x: 116 , y: 3.5, align: 'right', valign: 'flex-start'},
    {flow: 'Caldo', props: ['flowCaldoToFerm', 'brixCaldoToFerm', 'tempCaldoToFerm'], x: 3, y: 3.5, align: 'left', valign: 'flex-start'},
    {flow: 'Mel Final', props: ['flowMelFinal2', 'brixMelFinal2', 'tempMelFinal'], x: 3, y: 13.5, align: 'left', valign: 'flex-start'},
    {flow: 'Água Diluição', props: ['flowAguaDilMosto', 'tempAguaDilMosto'], x: 116, y: 13.7, align: 'right', valign: 'flex-start'},
    // {flow: 'Caldo 3º Efeito', props: ['flowSangriaCaldo3Ef', 'brixCaldo3EfAc', 'tempSangriaCaldo3Ef'], x: 116, y: 20.5, align: 'right', valign: 'flex-start'},
    {flow: 'Mosto', props: ['flowMosto', 'brixMosto', 'tempMosto', 'artMassaMosto'], x: 67.8, y: 25, align: 'left', valign: 'flex-start'},
    {flow: 'Mosto Resf.', props: ['flowMosto', 'tempMostoResf'], x: 67.8, y: 42, align: 'left', valign: 'flex-start'},
    {flow: 'Vinho Bruto', props: ['flowVinhoBruto', 'concEtanolVinhoBruto', 'concCelVinhoBruto'], x: 67.8, y: 62.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVinhoCent','flowVolVinhoCent','concEtanolVinhoBruto','concCelVinhoCent'], x: 78, y: 75, align: 'left', valign: 'flex-start'},
    {flow: 'Vinho', props: ['flowVinhoToDest', 'flowVolVinhoToDest','concEtanolVinhoToDest'], x: 116, y: 75, align: 'right', valign: 'flex-start'},
    {flow: 'Creme', props: ['flowCreme','concCelCreme'], x: 52.5, y: 73, align: 'left', valign: 'flex-end'},
    {flow: 'Ferm. Trat.', props: ['flowfermTratado','concEtanolFermTratado','concCelFermTratato'], x: 46.5, y: 57, align: 'left', valign: 'flex-start'},
    {flow: 'CO2', props: ['flowCO2'], x: 40, y: 36, align: 'left', valign: 'flex-start'},
    {flow: 'Água', props: ['flowAguaLavCO2'], x: 3, y: 36, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowAguaLavCO2'], x: 14, y: 60, align: 'right', valign: 'flex-end'},
    {flow: 'Água', props: ['flowAguaComplemDilFerm'], x: 3, y: 73, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratDiluicaoFerm'], x:25.3, y: 73, align: 'right', valign: 'flex-end'},
    {flow: 'Sangria', props: ['flowVolSangriaLev'], x: 3, y: 81, align: 'left', valign: 'flex-end'},

    // {flow: '', props: ['flowCaldoAcucar', 'tempCaldoAcucar'], x: 116, y: 29, align: 'right', valign: 'flex-start'},
    // {flow: '', props: ['flowCaldoAcucar', 'tempOutCaldoRegCaldoMosto'], x: 116, y: 34, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaResfMosto', 'tempAguaEntResfMosto'], x: 116, y: 31, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaResfMosto', 'tempAguaSaiResfMosto'], x: 116, y: 40, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaTorreFerm', 'tempAguaFriaTorreFerm'], x: 116, y: 48, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaTorreFerm', 'tempAguaQuenteTorreFerm'], x: 116, y: 57, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowMelpEstoque', 'artMassaMelpEstoque'], x: 30.4, y: 14, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowMelpFerm', 'artMassaMelpFerm'], x: 48.5, y: 11.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowMelEstoque', 'brixMelEstoque', 'artMassaMelEstoque'], x: 43, y: 19.5, align: 'right', valign: 'flex-end'},
  ]
}

const destilaria = {
  imagem: DestFlx,
  flows: [
    {flow: 'Água', props: ['flowAguaTorreDestilatia', 'tempAguaQuenteTorreFerm'], x: 3 , y: 6, align: 'left', valign: 'flex-start'},
    {flow: 'Vinho', props: ['flowVolVinhoToDest', 'concEtanolVinhoToDest'], x: 3, y: 73, align: 'left', valign: 'flex-end'},
    {flow: 'Vinhaça', props: ['flowVinhaca', 'concEtanolVinhaca', 'tempVinhaca'], x: 3, y: 82, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowEtanol'], x: 66, y: 24.3, align: 'left', valign: 'flex-start'},
    {flow: 'VV1', props: ['flowVaporVV1Destilaria'], x: 116, y: 63, align: 'right', valign: 'flex-start'},
    {flow: 'VE', props: ['flowVaporVEDestilaria'], x: 116, y: 69, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVaporTotalColA'], x: 42, y: 55.3, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporTotalColB'], x: 65.4, y: 49.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowVaporVV1IndColA'], x: 94.5, y: 66, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV1DirColA'], x: 72, y: 66, align: 'left', valign: 'flex-end'},
    {flow: 'Flegmaça', props: ['flowFlegmaca', 'concEtanolFlegmaca'], x: 116, y: 82, align: 'right', valign: 'flex-end'},
    {flow: 'Flegma', props: ['flowFlegma', 'concEtanolFlegma'], x: 40, y: 42, align: 'right', valign: 'flex-end'},
    {flow: 'CDVG', props: ['flowRetCondColA'], x: 116, y: 74, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowAguaTorrePeneriraMol'], x: 98, y: 10, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowEtanolProdToHid'], x: 64.2, y: 35, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowEtanolHidToPenMol'], x: 77, y: 24.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowEtanolAnidro', 'flowEtanolAnidroDia'], x: 118.6, y: 41.7, align: 'center', valign: 'flex-start'},
    {flow: '', props: ['flowEtanolProdToHid', 'flowEtanolHidDia'], x: 118.6, y: 54.2, align: 'center', valign: 'flex-start'},
    {flow: 'VM', props: ['consVaporV10Desidratacao'], x: 116, y: 19.5, align: 'right', valign: 'flex-start'},
    {flow: 'VE', props: ['consVaporVEPenMol'], x: 116, y: 26.5, align: 'right', valign: 'flex-start'},
  ]
}

const flotador = {
  imagem: flotFlx,
  flows: [
    {flow: 'Xarope', props: ['flowXaropeToFab', 'brixXarope', 'purezaXarope', 'artMassaXaropeToFab'], x: 3 , y: 56.5, align: 'left', valign: 'flex-start'},
    {flow: 'Xarope Flotado', props: ['flowXaropeFlotado', 'brixXaropeFlotado', 'purezaXaropeFlotado', 'artMassaXaropeToFab', 'tempXaropeAquec'], x: 116 , y: 22.4, align: 'right', valign: 'flex-start'},
    {flow: 'VV1', props: ['consVaporVV1AquecXarope'], x: 30.5 , y: 43.5, align: 'right', valign: 'flex-start'},
    {flow: 'CDVG', props: ['gerCondVGAqVV1Xarope'], x: 56 , y: 64, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['tempXaropeAquec'], x: 93 , y: 46.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowBorraFlotador', 'brixBorraFlotador'], x: 70 , y: 35.5, align: 'left', valign: 'flex-end'},
    {flow: 'Água Doce', props: ['flowAguaDoceBorra', 'brixAguaDoceBorra'], x: 3 , y: 22.4, align: 'left', valign: 'flex-start'},
    {flow: 'p/ Tanque Dosagem', props: ['flowAguaDoceAndBorrapDos', 'brixAguaDoceAndBorrapDos', 'tempAguaDoceAndBorrapDos'], x: 3 , y: 37.5, align: 'left', valign: 'flex-start'},
  ]
}

const fabrica = {
  imagem: FabFlx,
  flows: [
    {flow: 'VV1', props: ['consVapTotalVV1Cozimento'], x: 3 , y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: 'VV2', props: ['consVapTotalVV2Cozimento'], x: 3 , y: 31.4, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1CozedorMassaA'], x: 11.2 , y: 26, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2CozedorMassaA'], x: 30.2 , y: 26, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2CozedorMassaB'], x: 93, y: 26, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1CozedorMassaB'], x: 74, y: 26, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['gerCondVGCozedorMassaA'], x: 48.2, y: 26, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['gerCondVGCozedorMassaB'], x: 111, y: 26, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['gerCDVGFabrica'], x: 116, y: 28.5, align: 'right', valign: 'flex-start'},
    {flow: 'CDVG', props: ['consCondVGMelAPobre'], x: 56.3, y: 41.5, align: 'left', valign: 'flex-end'},
    {flow: 'CDVG', props: ['consCondVGMelARico'], x: 25, y: 41.7, align: 'left', valign: 'flex-end'},
    {flow: 'CDVG', props: ['consCondVGCintrifMassaA'], x: 29.7, y: 56, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['consCondVGMagma'], x: 82.8, y: 68.3, align: 'right', valign: 'flex-end'},
    {flow: 'CDVG', props: ['consCondVGCintrifMassaB'], x: 92.5, y: 56, align: 'left', valign: 'flex-start'},
    {flow: 'CDVG', props: ['consCondVGMelBRico'], x: 87.8, y: 41.7, align: 'left', valign: 'flex-end'},
    // { 
    //   flow: 'VE Limp.',
    //   props: ['consVaporVELimpCozMA'],
    //   x: 44.8, y: 20.7, align: 'right', valign: 'flex-end'
    // },
    // { 
    //   flow: 'VE Limp.',
    //   props: ['consVaporVELimpCozMB'],
    //   x: 107.5, y: 20.7, align: 'right', valign: 'flex-end'
    // },
    {flow: 'Xarope', props: ['flowXaropeFlotado', 'brixXarope', 'purezaXarope', 'artMassaXaropeToFab'], x: 3, y: 3.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowXaropeToMassaA'], x: 19.8, y: 13.4, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowXaropeToMassaB'], x: 82.6, y: 13.4, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowMARDiluido', 'brixMARDiluido', 'purezaMelARico'], x: 2.5, y: 35, align: 'right', valign: 'flex-start'},
    {flow: 'Mel A Rico', props: ['flowMelARico', 'brixMelARico', 'purezaMelARico'], x: 22, y: 47, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowMAPDiluido', 'brixMAPDiluido', 'purezaMAPDiluido'], x: 59, y: 35, align: 'right', valign: 'flex-start'},
    {flow: 'Mel B Rico', props: ['flowMelBRico', 'brixMelBRico', 'purezaMelBRico'], x: 85, y: 47, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['flowMelBRicoDil', 'brixMelBRicoDil', 'purezaMelBRico'], x: 78, y: 35, align: 'left', valign: 'flex-start'},
    {flow: 'Açúcar', props: ['flowAcucar', 'flowAcucarScsdia', 'brixAcucar', 'purezaAcucar', 'artMassaAcucar'], x: 3, y: 83.3, align: 'left', valign: 'flex-end'},
    {flow: 'Mel Final', props: ['flowMelFinal2', 'brixMelFinal2', 'purezaMelFinal2', 'artMassaMelFinal2'], x: 116, y: 47, align: 'right', valign: 'flex-start'},
    
    {flow: 'Açúcar B', props: ['flowAcucarB',  'brixAcucarB', 'purezaAcucarB'], x: 103.7, y: 60, align: 'left', valign: 'flex-end'},
    {flow: 'Mel A Pobre', props: ['flowMelAPobre', 'brixMelAPobre', 'purezaMelAPobre'], x: 43, y: 47, align: 'left', valign: 'flex-start'},
    {flow: 'Massa A', props: ['flowMassaA', 'brixMassaA', 'purezaMassaA'], x: 40.7, y: 35, align: 'left', valign: 'flex-start'},
    {flow: 'Massa B', props: ['flowMassaB', 'brixMassaB', 'purezaMassaB'], x: 103.7, y: 35, align: 'left', valign: 'flex-start'},
    // { 
    //   flow: '',
    //   props: ['flowAlimMassaA', 'brixAlimMassaA', 'purezaAlimMassaA'],
    //   x: 8.4, y: 20, align: 'right', valign: 'flex-start'
    // },
    // { 
    //   flow: '',
    //   props: ['flowAlimMassaB', 'brixAlimMassaB', 'purezaAlimMassaB'],
    //   x: 42.3, y: 20, align: 'right', valign: 'flex-start'
    // },
    {flow: 'Magma', props: ['flowMagma', 'brixMagma', 'purezaMagma'], x: 103.7, y: 76, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['consVaporVESecadorAcucar'], x: 3, y: 69, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaMassaBTorreFab'], x: 113, y: 6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaQuenteMassaBTorreFab'], x: 113, y: 13.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaMassaATorreFab'], x: 50.5, y: 6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaQuenteMassaATorreFab'], x: 50.5, y: 13.5, align: 'right', valign: 'flex-end'
    }
  ]
}

const vapor = {
  imagem: vapFlx,
  flows: [
    {flow: 'VA', props: ['pressureVA', 'tempVaporVA'], x: 3, y: 2.2, align: 'left', valign: 'flex-start'},
    {flow: 'VM', props: ['pressureVM', 'tempVaporVM'], x: 3, y: 24.6, align: 'left', valign: 'flex-start'},
    {flow: 'VE', props: ['pressureVE', 'tempVaporVE'], x: 3, y: 47, align: 'left', valign: 'flex-start'},
    {flow: 'VV1', props: ['pressureVV1'], x: 3, y: 60.2, align: 'left', valign: 'flex-start'},
    {flow: 'VV2', props: ['pressureVV2'], x: 3, y: 70.3, align: 'left', valign: 'flex-start'},
    {flow: 'VV3', props: ['pressureVV3'], x: 3, y: 80.4, align: 'left', valign: 'flex-start'},
    //BAGACO
    {flow: 'Bagaço', props: ['flowBagacoToBoilers'], x: 3 , y: 11.8, align: 'left', valign: 'flex-start'},
    {flow: 'Sobra', props: ['sobraBagaco'], x: 3, y: 41, align: 'left', valign: 'flex-end'},
    //CALDEIRA VA
    {flow: '', props: ['gerVaporVACaldeiras', 'pressureVA', 'tempVaporVA'], x: 28.4, y: 2.6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consCondDesCaldeirasVA', 'tempAguaAlimCaldeirasVA'], x: 28.4, y: 12, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consBagCaldeirasVA'], x: 5.5, y: 18.6, align: 'right', valign: 'flex-end'},
    {flow: '',  props: ['flowBlowDownCaldVA'], x: 17.5, y: 22.2, align: 'left', valign: 'flex-end'},
    //CALDEIRA VM
    {flow: '', props: ['gerVaporVMCaldeiras', 'pressureVM', 'tempVaporVM'], x: 28.4, y: 24.6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consCondDesCaldeirasVM', 'tempAguaAlimCaldeirasVM'], x: 28.4, y: 34.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consBagCaldeirasVM'], x: 5.5, y: 41, align: 'right', valign: 'flex-end'},
    {flow: '',  props: ['flowBlowDownCaldVM'], x: 17.5, y: 44.7, align: 'left', valign: 'flex-end'},
    //TG VA
    {flow: '', props: ['consVaporVATgCpVA', 'pressInTgCpVA', 'tempInTgCpVA'], x: 54.2, y: 2.6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['gerVaporVETgCpVA', 'pressEscTgCpVA', 'tempEscTgCpVA'], x: 49.5, y: 16, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['ptTgCpVA'], x: 59.5, y: 10.6, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['eficIsoTgCpVA'], x: 43.6, y: 11.3, align: 'center', valign: 'flex-end'},
    //TG VM
    {flow: '', props: ['consVaporVMTgCpVM', 'pressInTgCpVM', 'tempInTgCpVM'], x: 60.5, y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['gerVaporVETgCpVM', 'pressEscTgCpVM', 'tempEscTgCpVM'], x: 56, y: 38, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['ptTgCpVM'], x: 65.8, y: 33, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['eficIsoTgCpVM'], x: 50, y: 33.8, align: 'center', valign: 'flex-end'},
    //SECADOR
    {flow: '', props: ['consVaporVESecadorAcucar'], x: 96, y: 48, align: 'left', valign: 'flex-start'},
    //VE
    {flow: '', props: ['consVaporTotalPreEvap'], x: 76.8, y: 48, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVEDesaerador'], x: 51.3, y: 48, align: 'left', valign: 'flex-start'},
    {flow: 'Alívio', props: ['consVaporVEAlivio'], x: 120.5, y: 41.5, align: 'left', valign: 'flex-start'},
    // {flow: '', props: ['flowVaporVEDestilaria'], x: 96.4, y: 35, align: 'left', valign: 'flex-start'},
    // {flow: '', props: ['consVaporVEPenMol'], x: 77, y: 35, align: 'left', valign: 'flex-start'},
    //VV1
    {flow: '', props: ['flowVV1Total'], x: 76.8, y: 56.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVV1TotalDifusor'], x: 15.7, y: 61, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV1Aquecimento'], x: 28.7, y: 61, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV1Destilaria'], x: 48, y: 61, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV12Efeito'], x: 67.5, y: 61, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1CozedorMassaA'], x: 86.8, y: 61, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV1CozedorMassaB'], x: 106.2, y: 61, align: 'left', valign: 'flex-start'},
    {flow: 'Alívio', props: ['consVaporVV1Alivio'], x: 120.5, y: 55, align: 'left', valign: 'flex-start'},
    //VV2
    // {flow: '', props: ['flowVV2'], x: 67.5, y: 58, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVV2TotalDifusor'], x: 15.7, y: 71, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV2Aquecimento'], x: 38.5, y: 71, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV23Efeito'], x: 57.8, y: 71, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2CozedorMassaA'], x: 77.2, y: 71, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporVV2CozedorMassaB'], x: 96.4, y: 71, align: 'left', valign: 'flex-start'},
    {flow: 'Alívio', props: ['consVaporVV2Alivio'], x: 120.5, y: 65, align: 'left', valign: 'flex-start'},
    //VV3
    // {flow: '', props: ['flowVV3'], x: 57.5, y: 71, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV3Aquecimento'], x: 48, y: 81, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVV34Efeito'], x: 86.8, y: 81, align: 'left', valign: 'flex-start'},
    //PERDAS
    {flow: 'Perdas', props: ['consVaporVAPerdas'], x: 116, y: 4.1, align: 'right', valign: 'flex-start'},
    {flow: 'Perdas', props: ['consVaporVMPerdas'], x: 116, y: 26.5, align: 'right', valign: 'flex-start'},
    {flow: 'Perdas', props: ['consVaporVEPerdas'], x: 116, y: 49, align: 'right', valign: 'flex-start'},
    {flow: 'Perdas', props: ['consVaporVV1Perdas'], x: 116, y: 62.5, align: 'right', valign: 'flex-start'},
    {flow: 'Perdas', props: ['consVaporVV2Perdas'], x: 116, y: 72.5, align: 'right', valign: 'flex-start'},
    {flow: 'Perdas', props: ['consVaporVV3Perdas'], x: 116, y: 82.5, align: 'right', valign: 'flex-start'},
    //DESSUPERs
    {flow: '', props: ['gerVaporVEDessupVE'], x: 19, y: 49, align: 'left', valign: 'flex-start'},
    //Acionamentos
    {flow: '', props: ['flowVaporVEAcVm'], x: 80.5, y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowVaporVEAcVm', 'pressureVE', 'tempVaporVEAcion'], x: 76, y: 38, align: 'left', valign: 'flex-start'},
    //DESIDRATACAO
    {flow: '', props: ['consVaporVMDesidratacao'], x: 109.5, y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consVaporV10Desidratacao'], x: 109.5, y: 31.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['consCondVEDessupDesid'], x: 101, y: 27.8, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['consVaporVEPenMol'], x: 104.7, y: 43.5, align: 'left', valign: 'flex-start'},
    //DESAERADOR
    {flow: '', props: ['flowAguaDesaerador', 'tempDesaerador'], x: 29, y: 49, align: 'right', valign: 'flex-start'},
    {flow: '', props: ['gerCondVEProcesso', 'tempRetCondVEDesaerador'], x: 52, y: 49.3, align: 'right', valign: 'flex-start'},
    {flow: 'CDVG1', props: ['consCondVGDesaerador', 'tempCondVGDesaerador'], x: 53.5, y: 57.5, align: 'right', valign: 'flex-end'},
    {flow: 'Desmi',  props: ['consAguaDesmiDesaerador', 'tempsAguaDesmiDesaerador'], x: 21, y: 57.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCondDessupFromDes'], x: 37, y: 42, align: 'left', valign: 'flex-end'},
    //REBAIXADORA VM TO VE
    {flow: '', props: ['consVaporVMRebVmVe'], x: 92, y: 25.5, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowAguaDesRebVmVe'], x: 84, y: 40, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerVaporVERebVmVe'], x: 92, y: 43.5, align: 'left', valign: 'flex-start'},
    //REBAIXADORA VA TO VM
    {flow: '', props: ['consVaporVARebVaVm'], x: 93.5, y: 2.6, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowAguaDesRebVaVm'], x: 85, y: 17.6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerVaporVMRebVaVm'], x: 93.5, y: 21.5, align: 'left', valign: 'flex-start'},
  ]
}

const bh = {
  imagem: bhFlx,
  flows: [
    //GER CDVG
    {flow: '', props: ['gerCondVGAqVFLAcucar'], x: 117, y: 6.1, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV3Acucar'], x: 117, y: 10.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Acucar'], x: 117, y: 15.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Acucar'], x: 117, y: 20.1, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqCCAcucar'], x: 117, y: 24.7, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV2Etanol'], x: 117, y: 29.4, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Etanol'], x: 117, y: 34, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGEvapAc'], x: 117, y: 38.4, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCDVGFabrica'], x: 117, y: 43.3, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVGAqVV1Xarope'], x: 117, y: 48, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCondVGAqDifusor'], x: 117, y: 52.5, align: 'left', valign: 'flex-end'},

//     //CONS COND 
    {flow: '', props: ['flowCondVegetal', 'tempCondVG'], x: 108, y: 27, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCondVegetal', 'tempCondReg'], x: 102.5, y: 32, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['gerCondVG1EvapAc'], x: 95.4, y: 12, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['gerCondVEColA'], x: 95.8, y: 14.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerCondVEProcesso'], x: 75, y: 10, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowSobraCDVGTqCaldeira'], x: 95.4, y: 22, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCondContaminado'], x: 95.4, y: 47.9, align: 'left', valign: 'flex-end'},
//     //DESAERADOR
    {flow: '', props: ['consCondVGDesaerador'], x: 78, y: 18.3, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['consAguaDesmiDesaerador'], x: 52, y: 19.4, align: 'right', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaDesaerador'], x: 75, y: 22, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowCondDessupFromDes'], x: 68, y: 24.7, align: 'right', valign: 'flex-end'},  
    {flow: '', props: ['flowAlimCaldTotal'], x: 75, y: 29, align: 'left', valign: 'flex-end'},
    {flow: 'VE', props: ['consVaporVEDesaerador'], x: 68.5, y: 14.6, align: 'right', valign: 'flex-end'},    
    {flow: '', props: ['gerVaporTotal'], x: 68, y: 34.8, align: 'right', valign: 'flex-end'}, 

//     //TORRES DESTILARIA
    {flow: '', props: ['flowAguaResfMosto'], x: 27.5, y: 48.5, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaTorreFerm'], x: 27.5, y: 53.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaTotalTorreDest', 'tempAguaFriaTorreFerm'], x: 33.2, y: 48.5, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaTotalTorreDest', 'tempAguaQuenteTotalTorreDest'], x: -3.5, y: 48.5, align: 'right', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaTorreFerm', 'tempAguaQuenteTorreFerm'], x: 23, y: 58, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['consAguaBrutaRepTorreDest'], x: 3.5, y: 43, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreDest'], x: 27.4, y: 41, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaEvapTorreDest'], x: 2.2, y: 41, align: 'right', valign: 'flex-end'},         
    
//     //TORRE FABRICA
    {flow: '', props: ['flowAguaTorreFab', 'tempAguaFriaTorreFab'], x: 33.2, y: 72.5, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaQuenteTorreFab', 'tempAguaQuenteTorreFab'], x: -3.5, y: 72.5, align: 'right', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaEvapTorreFab'], x: 27.5, y: 72.5, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaMassaATorreFab'], x: 27.5, y: 77.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaMassaBTorreFab'], x: 27.5, y: 82.2, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaQuenteEvapTorreFab'], x: 2, y: 72.5, align: 'right', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaQuenteMassaATorreFab'], x: 2, y: 77.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaQuenteMassaBTorreFab'], x: 2, y: 82.2, align: 'right', valign: 'flex-end'},  
    {flow: '', props: ['consAguaBrutaRepTorreFab'], x: 3.5, y: 66.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreFab'], x: 27.4, y: 64.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaEvapTorreFab'], x: 2.2, y: 64.5, align: 'right', valign: 'flex-end'},      
    
//     //TORRE MANCAIS
    {flow: '', props: ['flowAguaTorreMancais', 'tempAguaQuenteTorreMancais'], x: 33.2, y: 33.5, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowAguaTorreMancais', 'tempAguaFriaTorreMancais'], x: -3.5, y: 33.5, align: 'right', valign: 'flex-end'}, 
    {flow: '', props: ['flowPerdaArrasteTorreMancais'], x: 27.4, y: 26.2, align: 'left', valign: 'flex-end'}, 
    {flow: '', props: ['flowPerdaEvapTorreMancais'], x: 2.2, y: 26.2, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaRepTorreMancais'], x: 3.5, y: 28.2, align: 'left', valign: 'flex-end'},
    
//     //AGUA TRATADA
    {flow: '', props: ['flowAguaComplemDilFerm'], x: 35, y: 6, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavCO2'], x: 35, y: 10.7, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepPolimeto'], x: 35, y: 15.4, align: 'right', valign: 'flex-end'},
    // {flow: '', props: ['consAguaTratLevedura'], x: 35, y: 20.1, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratAbrandador'], x: 35, y: 20.1, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratLimpEvap'], x: 35, y: 24.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratEvap'], x: 35, y: 29.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratGeral'], x: 35, y: 34, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['consAguaTratPrepMosto'], x: 35, y: 38.8, align: 'right', valign: 'flex-end'},
    
//     //AGUA BRUTA
    {flow: '', props: ['flowAguaBrutaTotal'], x: 2.5, y: 5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaETA'], x: 3.5, y: 10.8, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaGeral'], x: 3.5, y: 15.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consAguaBrutaEmb'], x: 3.5, y: 20, align: 'left', valign: 'flex-end'},
    
//     //FLEGMACA
    
    
//     //VINHACA
    {flow: '', props: ['flowVinhaca', 'tempVinhacaReg'], x: 91, y: 76, align: 'center', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaArrasteTorreVinhaca'], x: 110, y: 73, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowPerdaEvapTorreVinhaca'], x: 90.5, y: 73, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowVinhacaFria', 'tempVinhacaFriaTorreVinhaca'], x: 112, y: 76, align: 'center', valign: 'flex-end'},
    
//     //GER AGUA RESIDUARIA
    {flow: '', props: ['gerAguaResPurgasTorreDest'], x: 25, y: 43, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreFab'], x: 25, y: 66.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResPurgasTorreMancais'], x: 25, y: 28.3, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResETA'], x: 25, y: 10.8, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResLimpEvap'], x: 52, y: 29.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeral'], x: 52, y: 34, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeralAguaBruta'], x: 25, y: 15.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResUsoGeralCDCont'], x: 95.4, y: 65.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResSobraCondCont'], x: 77.2, y: 65.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResDescCaldeira'], x: 59.2, y: 65.5, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResFiltroLodo'], x: 116, y: 65.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['gerAguaResAbrandador'], x: 52, y: 23.2, align: 'right', valign: 'flex-end'},
    {flow: '', props: ['flowAguaResParaLavoura'], x: 127.2, y: 72, align: 'left', valign: 'flex-start'},
    {flow: '', props: ['flowFlegmaca'], x: 41, y: 65.5, align: 'right', valign: 'flex-end'},

//     //COND VEGEAL CONTAMINADO
    {flow: '', props: ['consCondContUsoGeral'], x: 95.4, y: 59, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContFiltroLodo'], x: 116, y: 59, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCDVGFabrica'], x: 126.4, y: 59, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContPrepLeiteCal'], x: 85, y: 59, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['consCondContEmbebicao'], x: 105.8, y: 59, align: 'left', valign: 'flex-end'},
    // {flow: '', props: ['consCondContDilMosto'], x: 63, y: 56.5, align: 'left', valign: 'flex-end'},
    
//     //SISTEMA LAVADOR DE GASES
    {flow: '', props: ['consAguaResRepSistFuligem'], x: 54.8, y: 72, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaLavadorGases'], x: 73, y: 77.5, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaEvapLavadorGases'], x: 73, y: 70.3, align: 'left', valign: 'flex-end'},
    {flow: '', props: ['flowAguaFuligem'], x: 43.6, y: 84.7, align: 'right', valign: 'flex-end'},
    
//     //LAVOURA
    {flow: '', props: ['flowTotalToLavoura'], x: 127.2, y: 80, align: 'left', valign: 'flex-start'},
  ]
}