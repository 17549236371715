import { Snackbar, Alert } from '@mui/material';

const SnackMessage = ({snackBar, setSnackBar}) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackBar({...snackBar, open: false});
      };

    return (
        <Snackbar open={snackBar.open} autoHideDuration={3000} onClose={handleClose}>
            <Alert 
                severity={snackBar.severity}
                variant="filled"
                sx={{ width: '100%' }}
                onClose={handleClose}
                >
                {snackBar.message}
            </Alert>
        </Snackbar>
    )
}

export default SnackMessage