import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography, CircularProgress } from "@mui/material";

const LoadingDialog = ({ open, statusMessage }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      {/* <DialogTitle>Processando...</DialogTitle> */}
      <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "20px" }}>
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: "20px", textAlign: "center" }}>
          {statusMessage}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
