import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';

export default function UserData() {
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [siteId, setSiteId] = useState('');
    const [userId, setUserId] = useState('');
    const [sites, setSites] = useState([]);
    const [users, setUsers] = useState([]);

    const { getAnltcsData } = AnalyticsHooks()

    // const colorPalette = ['#4CAF50', '#2196F3', '#FFC107', '#FF5722', '#9C27B0', '#00BCD4', '#8BC34A']; // Cores fixas
    const colorPalette = [
      '#4CAF50', // Verde
      '#2196F3', // Azul
      '#FFC107', // Amarelo
      '#FF5722', // Laranja
      '#9C27B0', // Roxo
      '#00BCD4', // Azul Claro
      '#8BC34A', // Verde Claro
      '#E91E63', // Rosa
      '#795548', // Marrom
      '#607D8B', // Azul Acinzentado
      '#FF9800', // Laranja Claro
      '#673AB7', // Roxo Escuro
      '#3F51B5', // Azul Médio
      '#F44336', // Vermelho
      '#009688'  // Verde Água
    ];
    
  
    useEffect(() => {
      const fetchSites = async () => {
        const response = await getAnltcsData('', '', '', '');
        
        if (response && response.sites) {
        console.log('response', response.sites)
          setSites(response.sites);
        }
      };
      fetchSites();
    }, []);
  
    useEffect(() => {
       
      if (siteId) {
        
        const fetchUsers = async () => {
          const response = await getAnltcsData('', '', siteId, '');
          if (response && response.usuarios) {
            let usuarios = ["Todos", ...response.usuarios] 
            console.log('fetchUsers', usuarios)
            setUsers(usuarios);
          }
        };
        fetchUsers();
      }
    }, [siteId]);

    useEffect(() => {
        if (siteId && userId && sites.length > 0 && users.length > 0 && startDate && endDate) {
            console.log('fetchData', siteId, userId)
          fetchData();
        }
      }, [siteId, userId, startDate, endDate]);
      
  
    const fetchData = async () => {
      const response = await getAnltcsData(startDate, endDate, siteId, userId);
      if (response && response.dados) {
        setData(response.dados);
      }
    };
  
    const processChartData = () => {
      const groupedData = {};
  
      data.forEach(item => {
        const date =  new Date(item.criadoEm).toLocaleDateString("pt-BR", {day: "2-digit", month: "2-digit", year: "2-digit"});
        if (!groupedData[date]) groupedData[date] = {};
        if (!groupedData[date][item.acao]) groupedData[date][item.acao] = 0;
        groupedData[date][item.acao]++;
      });
  
      return Object.entries(groupedData).map(([date, actions]) => ({
        date,
        ...actions,
      }));
    };
  
    return (
      <Box p={2}>
        <Box display="flex" gap={2} mb={2} >
          <TextField
          sx={{minWidth: 200}}
            label="Data Início"
            type="date"
            value={startDate}
            onChange={e => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
          sx={{minWidth: 200}}
            label="Data Fim"
            type="date"
            value={endDate}
            onChange={e => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <FormControl variant="outlined" fullWidth sx={{ minWidth: 200 }}>
            <InputLabel id="site-label" sx={{ top: -10 }}>Site</InputLabel>
            <Select labelId="site-label"  value={siteId} onChange={e => setSiteId(e.target.value)}
                label="Site"  
               >
              {/* <MenuItem value="">Todos</MenuItem> */}
              {sites.map(site => (
                <MenuItem key={site} value={site}>{site}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth sx={{ minWidth: 200 }}>
                <InputLabel id="user-label" sx={{ top: -10 }}>Usuário</InputLabel>
                <Select
                          labelId="user-label"       // Conecta o InputLabel ao Select
                            value={userId}
                             onChange={e => setUserId(e.target.value)}
                         label="Usuário"            // Garante que o rótulo funcione com a borda
                    >
  
             {users.map(user => (
                <MenuItem key={user} value={user}>
                     {user}
                </MenuItem>
             ))}
                 </Select>
        </FormControl>

          {/* <Button variant="contained" onClick={fetchData}>Buscar</Button> */}
        </Box>
  
        <ResponsiveContainer width="100%" height={window.innerHeight * 0.7}>
          <BarChart data={processChartData()} maxBarSize={50}>
            <XAxis dataKey="date" tick={{ fontSize: 11 }}/>
            <YAxis tick={{ fontSize: 11 }}/>
            <Tooltip />
            <Legend />
            {Array.from(new Set(data.map(item => item.acao))).map((acao, index) => (
              <Bar key={acao} dataKey={acao} stackId="a" fill={colorPalette[index % colorPalette.length]} // Aplica cores fixas
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    );
  }
  
