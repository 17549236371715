// GlobalStateContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getDefData } from '../BME/Unidades/Geral/getDefData';
import LZString from 'lz-string'

// Criando o contexto
export const GlobalStateContext = createContext();

// Provedor de contexto
export const GlobalStateProvider = ({ children }) => {
  const [isAlertOn, setAlert] = useState(true);
  const [site, setSite] = useState(null);
  const [data, setData] = useState(() => {
    try {
      const compressedData = localStorage.getItem(`BmeData${site}`);
      if (compressedData) {
        const decompressedData = LZString.decompress(compressedData);
        return decompressedData ? JSON.parse(decompressedData) : getDefData(site);
      }
      return getDefData(site);
    } catch (error) {
      console.error('Erro ao carregar dados do localStorage:', error);
      return getDefData(site);
    }
  });

  const [area, setArea] = useState('resumoProd');
  const [menu, setMenu] = useState('bme');
  const [screen, setScreen] = useState('fluxos');
  const [listAlerts, setlistAlerts] = useState(null);
  const [user, setUser] = useState(null);
  
  const [isPrinting, setPrinting] = useState(false);
  const [bmeFileName, setBmeFileName] = useState('');
  const [showUpdating, setShowUpdating] = useState(false);

  // Salvar data no localStorage quando mudar
  useEffect(() => {
    if (data && site) {
      try {
        const compressedData = LZString.compress(JSON.stringify(data));
        localStorage.setItem(`BmeData${site}`, compressedData);
      } catch (error) {
        console.error('Erro ao salvar dados no localStorage:', error);
      }
    }
  }, [data, site]);

  // Atualizar data quando site mudar
  useEffect(() => {
    if (site) {
      try {
        const compressedData = localStorage.getItem(`BmeData${site}`);
        if (compressedData) {
          const decompressedData = LZString.decompress(compressedData);
          setData(decompressedData ? JSON.parse(decompressedData) : getDefData(site));
        } else {
          setData(getDefData(site));
        }
      } catch (error) {
        console.error('Erro ao carregar dados do localStorage:', error);
        setData(getDefData(site));
      }
    }
  }, [site]);

  return (
    <GlobalStateContext.Provider value={{ 
      isAlertOn, setAlert, 
      data, setData,
      area, setArea,
      menu, setMenu,
      screen, setScreen, 
      listAlerts, setlistAlerts, 
      user, setUser, site, setSite, bmeFileName, setBmeFileName,
      isPrinting, setPrinting, showUpdating, setShowUpdating}}>
      {children}
    </GlobalStateContext.Provider>
  )
}

export default GlobalStateContext;