import React, { useContext, useState, useRef, useEffect} from 'react'

import '../css/planoSafra.css';
import GlobalStateContext from '../components/GlobalStateContext'
import PSContext from './opoio/PSContext';
import MenuPlanoSafra from './menuPlanoSafra';
import { UseColors } from '../components/ui/colors';
// import { stdPlanoVars } from './stdPlanoSafra'
import { getStdPlanoSafra } from '../BME/Unidades/Geral/getStdPlanoSafra';
import SubMenuNew from './subMenus/subMenuNew';
import SubMenuEdit from './subMenus/subMenuEdit';
import SubMenuCopy from './subMenus/subMenuCopy';
import SubMenuFiles from './subMenus/subMenuFiles';
import PsInput from './opoio/psInput';
import { stdValue } from '../Resources/setNumber';
import { checkReal } from './opoio/calcPS'
import { listaDNECods, inputCods} from '../Resources/Constantes'
import SubMenuCharts from './subMenus/subMenuCharts';
import { usePsCalcs } from '../BME/Unidades/Geral/usePsCalcs';
import psHooks from '../Hooks/psHooks';
import PsOutput from './opoio/psOutput';
import ModalConfirm from '../components/ui/ModalConfirm';


function BodyPlanoSafra({sections = null, print = false}) {
  const {isNew, isEdit, isSaveAs, isOpen, isChart, psData, psDataReal, editingMonth, setPsData, psIsPrinting, psCalculating} = useContext(PSContext)
  const {site, setMenu, setArea} = useContext(GlobalStateContext)
  const psCalcs = usePsCalcs() 
  const [groupData, setGroupData] = useState(null)
  const [headData, setHeadData] = useState(null)
  const stdPlanoVars = getStdPlanoSafra(site)
  const {getReal} = psHooks()
  

  useEffect( () => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
    setMenu('ps')
    setArea('planoSafra')
    if (psData){
      setHeadData(getHeadData())
    }
    
  }, []);


  const COLORS = UseColors()

  useEffect(() => {
    // console.log('Update Plano Safra Screen', psData)
    if (psData){
      const groupedItems = stdPlanoVars.reduce((acc, item) => {
        // if (item.show !== false || item.ipt === true){
          if (!acc[item.grp]) {
              acc[item.grp] = [];
          }
          let psItem = psData.filter(row => row.codigo === item.cod)

          if (psItem.length > 0){
            acc[item.grp].push(psItem[0])
          }else{
            let rev = psData[0].revisao
            let safra = psData[0].safra
            let ssr = psData[0].ssr
            let siteId = psData[0].siteId

            let blankItem = {abr: 0, acu: 0, ago: 0, dez: 0, fev: 0, jan: 0, jul: 0, jun: 0, mai: 0, mar: 0, nov: 0, out: 0, set: 0,
            ssr: ssr, input: item.ipt ? item.ipt : null, safra: safra, secao: item.grp, codigo: item.cod, siteId: siteId, revisao: rev,
            unidade: item.unid, descricao: item.desc}
            acc[item.grp].push(blankItem)
          }
        // }
        return acc;
    }, {});
    // console.log('groupedItems', groupedItems)
    setGroupData(groupedItems)
    setHeadData(getHeadData())
    } 
  }, [psData, editingMonth, stdPlanoVars]);

  useEffect(() => {
    let temp = psData
    if (psData && psData.length > stdPlanoVars.length){
      psData.map(item => {
        if (stdPlanoVars.filter(row => row.cod === item.codigo).length === 0){
          temp = temp.filter(row => row.codigo !== item.codigo)
        }
      })
    }

    if (psData && psData.length < stdPlanoVars.length){
      stdPlanoVars.map(item => {
        if (psData.filter(row => row.codigo === item.cod).length === 0){
          let rev = psData[0].revisao
          let safra = psData[0].safra
          let ssr = psData[0].ssr
          let siteId = psData[0].siteId

          let blankItem = {abr: 0, acu: 0, ago: 0, dez: 0, fev: 0, jan: 0, jul: 0, jun: 0, mai: 0, mar: 0, nov: 0, out: 0, set: 0,
            ssr: ssr, input: item.ipt ? item.ipt : null, safra: safra, secao: item.grp, codigo: item.cod, siteId: siteId, revisao: rev,
            unidade: item.unid, descricao: item.desc}

          temp.push(blankItem)
        }
      })
    }
    setPsData(temp)
  }, [stdPlanoVars]);

  let nwSites = ['usJbAlcoolquimica']

  let months = nwSites.includes(site) ? ['set', 'out', 'nov', 'dez', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago'] : ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez']

  function getHeadData(){
    let psAlerts = psData?.filter(item => item.codigo === 'psAlerts')
    let safra = psData[0]?.safra
    let ano0 = safra.split('/')[0]
    let ano1 = safra.split('/')[1]

    

    if (psAlerts && psData && safra !== 'Estudo' && !nwSites.includes(site)){
      
      let alerts = [
        {status: psCalcs.checkReal(psData, 'jan') ? 'Real' : 'Plano', mes: `Jan/${ano0}`, alert: psAlerts[0]?.jan === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'fev') ? 'Real' : 'Plano', mes: `Fev/${ano0}`, alert: psAlerts[0]?.fev === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mar') ? 'Real' : 'Plano', mes: `Mar/${ano0}`, alert: psAlerts[0]?.mar === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'abr') ? 'Real' : 'Plano', mes: `Abr/${ano0}`, alert: psAlerts[0]?.abr === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mai') ? 'Real' : 'Plano', mes: `Mai/${ano0}`, alert: psAlerts[0]?.mai === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jun') ? 'Real' : 'Plano', mes: `Jun/${ano0}`, alert: psAlerts[0]?.jun === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jul') ? 'Real' : 'Plano', mes: `Jul/${ano0}`, alert: psAlerts[0]?.jul === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'ago') ? 'Real' : 'Plano', mes: `Ago/${ano0}`, alert: psAlerts[0]?.ago === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'set') ? 'Real' : 'Plano', mes: `Set/${ano0}`, alert: psAlerts[0]?.set === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'out') ? 'Real' : 'Plano', mes: `Out/${ano0}`, alert: psAlerts[0]?.out === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'nov') ? 'Real' : 'Plano', mes: `Nov/${ano0}`, alert: psAlerts[0]?.nov === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'dez') ? 'Real' : 'Plano', mes: `Dez/${ano0}`, alert: psAlerts[0]?.dez === 1 ? false : true},
        
      ]
      return alerts
    }else if (psAlerts && psData && safra !== 'Estudo' && nwSites.includes(site)){
      
      let alerts = [
        {status: psCalcs.checkReal(psData, 'set') ? 'Real' : 'Plano', mes: `Set/${ano0}`, alert: psAlerts[0]?.set === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'out') ? 'Real' : 'Plano', mes: `Out/${ano0}`, alert: psAlerts[0]?.out === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'nov') ? 'Real' : 'Plano', mes: `Nov/${ano0}`, alert: psAlerts[0]?.nov === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'dez') ? 'Real' : 'Plano', mes: `Dez/${ano0}`, alert: psAlerts[0]?.dez === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jan') ? 'Real' : 'Plano', mes: `Jan/${ano1}`, alert: psAlerts[0]?.jan === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'fev') ? 'Real' : 'Plano', mes: `Fev/${ano1}`, alert: psAlerts[0]?.fev === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mar') ? 'Real' : 'Plano', mes: `Mar/${ano1}`, alert: psAlerts[0]?.mar === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'abr') ? 'Real' : 'Plano', mes: `Abr/${ano1}`, alert: psAlerts[0]?.abr === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'mai') ? 'Real' : 'Plano', mes: `Mai/${ano1}`, alert: psAlerts[0]?.mai === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jun') ? 'Real' : 'Plano', mes: `Jun/${ano1}`, alert: psAlerts[0]?.jun === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'jul') ? 'Real' : 'Plano', mes: `Jul/${ano1}`, alert: psAlerts[0]?.jul === 1 ? false : true},
        {status: psCalcs.checkReal(psData, 'ago') ? 'Real' : 'Plano', mes: `Ago/${ano1}`, alert: psAlerts[0]?.ago === 1 ? false : true},
        
      ]
      return alerts
    }else if (nwSites.includes(site)){
      let blkAlerts = [
        {status: 'Plano', mes: 'Set', alert: true},
        {status: 'Plano', mes: 'Out', alert: true},
        {status: 'Plano', mes: 'Nov', alert: true},
        {status: 'Plano', mes: 'Dez', alert: true},
        {status: 'Plano', mes: 'Jan', alert: true},
        {status: 'Plano', mes: 'Fev', alert: true},
        {status: 'Plano', mes: 'Mar', alert: true},
        {status: 'Plano', mes: 'Abr', alert: true},
        {status: 'Plano', mes: 'Mai', alert: true},
        {status: 'Plano', mes: 'Jun', alert: true},
        {status: 'Plano', mes: 'Jul', alert: true},
        {status: 'Plano', mes: 'Ago', alert: true},
      ]
      return blkAlerts
    } else{
      let blkAlerts = [
        {status: 'Plano', mes: 'Jan', alert: true},
        {status: 'Plano', mes: 'Fev', alert: true},
        {status: 'Plano', mes: 'Mar', alert: true},
        {status: 'Plano', mes: 'Abr', alert: true},
        {status: 'Plano', mes: 'Mai', alert: true},
        {status: 'Plano', mes: 'Jun', alert: true},
        {status: 'Plano', mes: 'Jul', alert: true},
        {status: 'Plano', mes: 'Ago', alert: true},
        {status: 'Plano', mes: 'Set', alert: true},
        {status: 'Plano', mes: 'Out', alert: true},
        {status: 'Plano', mes: 'Nov', alert: true},
        {status: 'Plano', mes: 'Dez', alert: true},
       
        
      ]
      return blkAlerts
    } 
  } 

  function getRealItem(cod){
    let item = psDataReal[psData[0].safra]['13_Realizado'][cod][0]
    return item
  }

  function getGroup(group){
    let items  = stdPlanoVars.filter(row => row.grp === group)
    return(
      items
      .filter(row => row.show !== false)
      .map((item, index) => {
        let psItem = groupData[group].filter(row => row.codigo === item.cod)
        return(
          <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
          <div style={{width: '15%', textAlign: 'left'}}>{item.desc}</div>
          <div style={{width: '5%', textAlign: 'left'}}>{item.unid}</div>

          {months.map((mes,index) => {
            let v = psItem.length > 0 ?  stdValue(psItem[0][mes]) : 0
            return  <div style={{width: '5%'}} key={`${group}-${mes}-${index}-0`}>
              
              {v}
              </div>
          })}
          <div style={{width: '10%'}}>{psItem.length > 0 ?  stdValue(psItem[0].acu) : 0}</div>
        </div>
        )
    })
    )
  }

  function getEdittingGroup(group){
    let items  = stdPlanoVars.filter(row => row.grp === group)
    return(
      items
      .filter(row => row.show !== false)
      .map((item, index) => {
        let psItem = groupData[group].filter(row => row.codigo === item.cod)
        return(
          <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
          <div style={{width: '15%', textAlign: 'left'}}>{item.desc}</div>
          <div style={{width: '5%', textAlign: 'left'}}>{item.unid}</div>
  
          {months.map(mes => {
            let v = psItem.length > 0 ?  stdValue(psItem[0][mes]) : 0
            // console.log('v', mes, v, item.cod)
              return <div style={{width: '5%', textAlign: 'left'}} key={`${group}-${mes}-${index}-1`}>{!psCalcs.checkReal(psData, mes) && item.ipt === true  ?
                <PsInput cod={item.cod} mes={mes} valor={v}/> : v
                // <PsOutput cod={item.cod} mes={mes} valor={v}/> 
                }</div>
          })}
          <div style={{width: '10%'}}>{psItem.length > 0 ?  stdValue(psItem[0].acu) : 0}</div>
        </div>
        )
    })
    )
  }

  function getEdittingRealGroup(group){
    return(
      stdPlanoVars.filter(row => row.grp === group)
      .filter(row => row.show !== false)
      .map((item, index) => {
        return(
          <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
          <div style={{width: '15%', textAlign: 'left'}}><span>{item.desc}</span></div>
          <div style={{width: '5%', textAlign: 'left'}}>{item.unid}</div>

          {months.map(mes => {
              return (
                <div style={{width: '5%', textAlign: 'left'}} key={`${group}-${mes}-${index}-2`}>{
                  setMonth(editingMonth) === mes && 
                  item.iptReal ?
                  <PsInput cod={item.cod} mes={mes} valor={groupData[group].filter(row => row.codigo === item.cod).length > 0 ?  stdValue(groupData[group].filter(row => row.codigo === item.cod)[0][mes]) : 0}/> :
                  groupData[group].filter(row => row.codigo === item.cod).length > 0 ?
                  stdValue(groupData[group].filter(row => row.codigo === item.cod)[0][mes]) : 0
                }</div>
              )
          })}
        
          <div style={{width: '10%'}}>{stdValue(item.acu)}</div>
        </div>
        )
    })
    )
  }

  return (
    <div className='psMainBody'>
      
        {psData && groupData && !isOpen && !isChart && <div className='psTableHead'>
            <div style={{width: '15%', textAlign: 'left'}}>Descrição</div>
            <div style={{width: '5%', textAlign: 'left'}}>Un.</div>
            {headData.map((item, index) => {
                return(
                    <div className='psHeadElement' style={{width: '5%'}} key={`${item.mes}-${index}`}>
                        <div>{item.status}</div>
                        <div>{item.mes}</div>
                        {/* {item.alert === true && 
                        <div className='psAlert'>!</div>} */}
                    </div>
                )
            })}
            <div style={{width: '10%', textAlign: 'center'}}>Acumulado</div>
        </div>}
      
        {isChart && <SubMenuCharts/>}
        {isOpen && <SubMenuFiles/>}
        <div  style={isEdit ? {maxHeight: '67.5vh'} : !print ? {maxHeight: '78vh'} :  {}}>
        {psData &&  groupData && !isOpen && !isChart &&
            Object.keys(groupData)
            .filter(secao => secao !== 'settings')
            .filter(secao => sections === null || sections.includes(secao))
            .map(group => {
            if (psData[0].revisao === '13_Realizado' && group === 'DIAS NÃO EFETIVOS'){
            }else{
            return(
                <div key={group}>
                <div className='psSectionHead'>{group}</div>

                {isEdit && psData[0].revisao !== '13_Realizado'&&

                getEdittingGroup(group)
                // <EdittingGroup group={group} stdPlanoVars={stdPlanoVars} groupData={groupData} months={months} psCalcs={psCalcs} psData={psData}/>
                }

                {isEdit && psData[0].revisao === '13_Realizado' &&
                getEdittingRealGroup(group)}

                {!isEdit && 
                getGroup(group)}
                
                </div>
            )
            }
        })}
        </div>
        <ModalConfirm show={psCalculating} title="Alerta" style={{width: '20px'}}>
        <div className='modal-title' style={{marginTop: '3vh', padding: '2vh'}}>Calculando ...</div>
        </ModalConfirm>
    </div>
  )
}

export default BodyPlanoSafra

function setMonth(fullMonth){
  let month = fullMonth.substring(0, 3).toLowerCase()
  return month
}

function EdittingGroup({group, stdPlanoVars, groupData, months, psCalcs, psData}){
  let items  = stdPlanoVars.filter(row => row.grp === group)
  return(
    items
    .filter(row => row.show !== false || row.ipt)
    .map((item, index) => {
      let psItem = groupData[group].filter(row => row.codigo === item.cod)
      return(
        <div className={`psLine ${index%2 === 0 && "psLineImp"}`}>
        <div className='colDesc'>{item.desc}</div>
        <div className='colUnit'>{item.unid}</div>

        {months.map(mes => {
          let v = psItem.length > 0 ?  stdValue(psItem[0][mes]) : 0
          // console.log('v', mes, v, item.cod)
            return <div className='colValuesIpt' key={`${group}-${mes}-${index}-1`}>{!psCalcs.checkReal(psData, mes) && item.ipt === true && mes !== 'acu' && mes !== 'acu2' ?
              <PsInput cod={item.cod} mes={mes} valor={v}/> : 
              mes === 'acu' || mes === 'acu2' ? 
              <div style={{fontWeight: 'bold'}}>{v}</div> : 
              <PsOutput cod={item.cod} mes={mes} valor={v}/> 
              }</div>
        })}
        {/* <div className='colValues'>{psItem.length > 0 ?  stdValue(psItem[0].acu) : 0}</div> */}
      </div>
      )
  })
  )
}



