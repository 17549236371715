const useUSLDataFetch = () => {

    const getToken = async () => {
        const username = "PMS";
        const password = "JZ8CS2DXETTF4";

        const credentials = btoa(`${username}:${password}`);

        try {
            const response = await fetch('https://api.usinalins.com.br/api/wslins/boletim/oauth/token', {
                method: 'POST',
                headers: {
                    'Authorization': `Basic ${credentials}`, // Basic Auth no cabeçalho
                    'Content-Type': 'application/x-www-form-urlencoded' // Algumas APIs esperam esse formato
                },
                body: new URLSearchParams({ grant_type: "client_credentials" }), // Envia grant_type, se necessário
                credentials: 'include', // Mantém cookies e sessões
                mode: 'cors'
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Erro ${response.status}: ${errorText}`);
            }

            const data = await response.json();
            return { token: data.access_token, error: null };
        } catch (error) {
            console.error("Erro ao buscar o token:", error);
            return { token: null, error: `Erro ao buscar o token: ${error}` };
        }
    }

    const getUslSiteData = async (date) => {
        try {
            const API_KEY = await getToken()
            const API_URL = 'https://api.usinalins.com.br/api/wslins/boletim/pms'

            const response = await fetch(API_URL, {
                method: "GET",
                headers: 
                    {
                        "Content-Type": "application/json",
                        "data": date,
                        "access_token": API_KEY.token
                    }
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Erro ${response.status}: ${errorText}`);
            }
            const jsonData = await response.json();
            // console.log('jsonData', date, jsonData)
            const filteredData = jsonData.result.filter(item => item.data_ref === dateToNumeric(date))
            return { siteData: filteredData, error: null }
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
            return { siteData: null, error: `Erro ao buscar dados: ${error}` };
        }
    }

    return { getUslSiteData }
}

export default useUSLDataFetch

function dateToNumeric(dateString) {
    // Converte dd/MM/yyyy para YYYY-MM-DD e depois para numérico
    const [dia, mes, ano] = dateString.split('/');
    
    // Garante que todos os componentes existem
    if (!dia || !mes || !ano) {
        return null;
    }
    
    // Monta a data no formato YYYYMMDD e adiciona 000000
    const numericDate = `${ano}${mes.padStart(2, '0')}${dia.padStart(2, '0')}000000`;
    return parseInt(numericDate);
}