import React, { useContext } from 'react'
import BtNewPS from "./botoes/btNewPS";
import BtSaveAsPS from './botoes/btSaveAsPS';
import BtEditPS from './botoes/btEditPS';
import BtOpenPS from './botoes/btOpenPS';
import BtExportPdfPS from './botoes/btExportPdfPS';
import BtChartsPS from './botoes/btChartsPS';
import PSContext from './opoio/PSContext';
import BtImportPsOld from './botoes/btImportPsOld';
import GlobalStateContext from '../components/GlobalStateContext';
import BtExportPsToXlsx from './botoes/btExportPsToXlsx';
import BtImportPsReal from './botoes/btImportPsReal';
import Box from '@mui/material/Box';

function MenuPlanoSafra() {
  const { psData } = useContext(PSContext)
  const {site} = useContext(GlobalStateContext)
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div className="psMainMenu">
                <BtOpenPS/>
                <BtNewPS/>
                <BtSaveAsPS/>
                <BtEditPS/>
                <BtChartsPS/>
                <BtExportPdfPS/>
                {site === 'usLins' &&
                <BtImportPsOld/>}
                <BtExportPsToXlsx/>
                {site === 'usLins' && psData && psData[0].revisao === '13_Realizado' &&
                <BtImportPsReal/>}
            </div>
            {psData && 
            <Box p={1.5} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <Box style={{fontSize: '14px', fontWeight: 'bold'}}>
                    {psData[0].safra} - {psData[0].revisao}
                </Box>
                {psData[0].revisao === '13_Realizado' && 
                <Box style={{fontSize: '12px', color: 'green'}}>
                    Última Atualizaçao: {psData.filter(item => item.codigo === 'psLastUpdate')[0]['lastUpdate'] || ''}
                </Box>
                }
            </Box>}
         </div> 
    )
}

export default MenuPlanoSafra