import React, { useContext, useEffect, useState} from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import GlobalStateContext from '../../../components/GlobalStateContext';
import { stdValue, stdValue2 } from '../../../Resources/setNumber';
import { Circle } from '@mui/icons-material';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import AnalisesContext from '../../../ANALISES/AnalisesContext';
import { getSiteGroupTable } from '../tables/getSiteTable';
import { resolverEquacao, temOperadorMatematico, getBltValue } from '../dataFuncs/transformData';

const RealDataxBme = () => {
    const {site, data} = useContext(GlobalStateContext)
    const {newAction} = AnalyticsHooks()
    const {siteData, siteTable, setSiteTable, tolerancia, showAllBmeBoletim} = useContext(AnalisesContext)
    const columns = ['REAL', 'BME', 'DESVIO'];

    useEffect(() => {
        const tabela = getSiteGroupTable(site)
        setSiteTable(tabela)
        // console.log(tabela, siteData)
    }, [siteData, data])


    // const table = getSiteTable(site)
    // // Obter grupos únicos
    // const uniqueGroups = [...new Set(table.map(item => item.grp))]

   
    
  
    return (
        <TableContainer component={Paper} sx={{marginTop: '2px', width: "100%", overflowX: "auto", maxHeight: 'calc(92vh - 100px)' }}>
          <Table stickyHeader >
            <TableHead >
              <TableRow >
                <TableCell sx={{color: 'white', backgroundColor: 'primary.main',  fontWeight: "bold", padding: '1px', fontSize: '12px', paddingLeft: '10px', textAlign: 'left'}}>DESCRIÇÃO</TableCell>
                <TableCell sx={{color: 'white', backgroundColor: 'primary.main',  fontWeight: "bold", padding: '1px' , fontSize: '12px', textAlign: 'left'}}>UNID.</TableCell>
                {columns.map((col, idx) => (
                  <TableCell key={`${col}-${idx}`} sx={{ color: 'white', backgroundColor: 'primary.main', textAlign: "center", fontWeight: "bold" , padding: '1px', fontSize: '12px' }}>
                    {col}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {siteData && siteTable && Object.keys(siteTable).map((group, index) => (
                <React.Fragment key={`${group}-${index}`}>
                  {/* Cabeçalho da seção */}
                   {siteTable[group].filter(i => !showAllBmeBoletim || i.filter === false).length > 0 &&
                     <TableRow>
                     <TableCell colSpan={17} sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", padding: '1px', paddingLeft: '10px', fontSize: '12px', position: 'sticky', top: '26px' }}>
                       {group}
                     </TableCell>
                   </TableRow>
                   }
                  {/* Dados da seção */}
                  {siteTable[group]
                    .filter(i => i.show !== false)
                    .filter(i => !showAllBmeBoletim || i.filter === false)
                    .map((item, idx) => (
                      <TableRow key={`${item.bmeCod}-${idx}`}>
                        <TableCell sx={{ padding: '1px', fontSize: '12px', textAlign: 'left', paddingLeft: '10px', }}>{data[item.bmeCod]?.descricao || item.name}</TableCell>
                        <TableCell sx={{ padding: '1px', fontSize: '12px' , textAlign: 'left' }}>{data[item.bmeCod]?.unidade || item?.unid}</TableCell>
                        {columns.map((col, idx) => {
                          
                          let bltValue = getBltValue(item.bltCod, siteData)
                            
                          let values = [bltValue, data[item.bmeCod]?.valor || 0, 0]//getValues(line.bmeCod, bltProd, bltAna, data)
                          return (
                            <TableCell key={`${item.bmeCod}-${col}-${idx}`} 
                            sx={{  
                              textAlign: "center",  
                              padding: '1px', 
                              fontSize: '11px', 
                              width: '20%',
                              color: item.ipt && idx == 1 ? 'primary.main' : 'inherit',
                              fontWeight: item.ipt && idx == 1 ? 'bold' : 'normal'
                            }}>
                              {idx < 2 ? stdValue2(values[idx]) : item.ipt && idx == 2 ? '' : <Desvio realValue={values[0]} planValue={values[1]} tolerancia={tolerancia}/>}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
};

export default RealDataxBme;

// function getBmeUnit(cod, data){
//   let unit = data[cod]?.unidade 
//   switch (cod){
//     case 'gerVaporVACaldeiras':
//     case 'consVaporVATgCdVA':
//     case 'consVaporVARebVaVm':
//     case 'flowTorta':
//       unit = 'ton'
//       break
//   }
//   return unit || ''
// }

// function getBmeDesc(cod, data){
//   let desc = data[cod]?.descricao 
//   switch (cod){
//     case 'flowMelFinal2':
//       desc = 'Produção Mel Final'
//       break
//   }
//   return desc || ''
// }

// function getValues(cod, bltProd, bltAna, data){
//   let realItem = BltsXBmeVars.find(i => i.bmeCod === cod)
//   let realValue = 0

//   switch (cod){
//     case 'flowAcucarScsdia':
//       realValue = getDayProdValue(realItem.linha, bltProd) + getDayProdValue(4010, bltProd) + getDayProdValue(4510, bltProd) + getDayProdValue(930, bltProd) - getDayProdValue(925, bltProd)
//       break
//     case 'flowAcucarScsdiaPro':
//       realValue = getDayProdValue(realItem.linha, bltProd) + getDayProdValue(4010, bltProd) + getDayProdValue(4510, bltProd)
//       break
//     case 'flowEtanolDestDia':
//       realValue = getDayProdValue(realItem.linha, bltProd) + getDayProdValue(5015, bltProd)
//       break
//     default:
//       realValue = realItem.blt === 'PROD' ? getDayProdValue(realItem.linha, bltProd) : getDayAnalValue(realItem.linha, bltAna)
//   }

//   let bmeValue = data[cod]?.valor || 0 
//   switch (cod){
//     case 'gerVaporVACaldeiras':
//     case 'consVaporVATgCdVA':
//     case 'consVaporVARebVaVm':
//     case 'flowTorta':
//       let horasEfetivas = getDayProdValue(750, bltProd)
//       bmeValue = data[cod]?.valor * horasEfetivas
//       break
//     // case 'efFermentacao':
//     //   bmeValue = getDayProdValue(realItem.linha, bltProd)
//     //   console.log('efFermentacao', bmeValue)
//     //   if(bmeValue > 92){bmeValue = 92}
//     //   console.log('efFermentacao', bmeValue)
//     //   break
//   }
//   return [realValue, bmeValue]
// }

function getDesvio(realValue, planValue, tolerancia){
  try{
    if (isNaN(parseFloat(realValue)) || isNaN(parseFloat(planValue))){
      // console.log(realValue, planValue)
      return null
    }
    let desv = (realValue - planValue)
    const percent = () => {
      const real = realValue //parseNumber(realValue);
    
      if (planValue === 0 && real > 0) {
        return 100;
      } else if (planValue > 0 && real === 0) {
        return -100;
      } else if (planValue === 0 && real === 0) {
        return 0;
      } else if (planValue !== 0) {
        return ((real - planValue) * 100) / planValue;
      } else {
        return 0; // Caso inesperado, retorna 0 como fallback
      }
    };
    let status = realValue > planValue*(1+tolerancia/100) || realValue < planValue*(1-tolerancia/100) ? 'red' : 'green'
    if (desv === 0){
      status = "gray"
    }
    let result = {valor: stdValue(desv), percent: stdValue(percent()), status: status}
    return result
  }catch(error){
    console.log('Error', error, realValue, planValue)
    return null
  }
}

const Desvio = ({realValue, planValue, tolerancia}) => {
  let desv = getDesvio(realValue, planValue, tolerancia)
  if (!desv){
    return (<div>-</div>)
  }else{
    return(
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '60%', textAlign: 'right'}}>{`${desv.valor} (${desv.percent}%)`}</div>
        <div style={{width: '40%'}}>
          <SemaforoKPI status={desv.status}/>
        </div>
        
      </div>
    )
  }
  
}

const SemaforoKPI = ({ status }) => {
  return <Circle style={{ color: status, fontSize: 16}} />;
};
