import React, { createContext, useState, useEffect } from 'react';
import { useContext } from 'react';
import GlobalStateContext from '../components/GlobalStateContext';

// Criando o contexto
export const AnalisesContext = createContext();

// Provedor de contexto
export const AnalisesContextProvider = ({ children }) => {
  const {site} = useContext(GlobalStateContext)
  const [showImportBoletim, setShowImportBoletim] = useState(false); 
  const [showAnaliseDia, setShowAnaliseDia] = useState(true); 
  const [showBltxPlano, setShowBltxPlano] = useState(false); 
  const [isDataComplete, setIsDataComplete] = useState(false); 
  const [selectedDate, setSelectedDate] = useState(null); 
  const [bltData, setBltData] = useState(() => {
    const savedData = localStorage.getItem(`bltData-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });
  const [bltProd, setBltProd] = useState(() => {
    const savedData = localStorage.getItem(`bltProd-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });
  const [bltAna, setBltAna] = useState(() => {
    const savedData = localStorage.getItem(`bltAna-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });
  const [tempPsData, setTempPsData] = useState(() => {
    const savedData = localStorage.getItem(`tempPsData-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });
  const [tolerancia, setTolerancia] = useState(() => {
    const savedData = localStorage.getItem(`tolerancia-${site}`);
    return savedData ? JSON.parse(savedData) : 5;
  });
  const [psAnData, setPsAnData] = useState(null)
  const [anDate, setAnDate] = useState('')
  const [showAllBmeBoletim, setShowAllBmeBoletim] = useState(true); 

  //NOVOS
  const [date, setDate] = useState(() => {
    const savedData = localStorage.getItem(`date-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });

  const [siteData, setSiteData] = useState(() => {
    const savedData = localStorage.getItem(`siteData-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });

  const [siteTable, setSiteTable] = useState(() => {
    const savedData = localStorage.getItem(`siteTable-${site}`);
    return savedData ? JSON.parse(savedData) : null;
  });
  
  // Carregar dados do localStorage quando site mudar
  useEffect(() => {
    if (site) {
      const savedBltData = localStorage.getItem(`bltData-${site}`);
      const savedBltProd = localStorage.getItem(`bltProd-${site}`);
      const savedBltAna = localStorage.getItem(`bltAna-${site}`);
      const savedTempPsData = localStorage.getItem(`tempPsData-${site}`);
      const savedTolerancia = localStorage.getItem(`tolerancia-${site}`);

      if (savedBltData) setBltData(JSON.parse(savedBltData));
      if (savedBltProd) setBltProd(JSON.parse(savedBltProd));
      if (savedBltAna) setBltAna(JSON.parse(savedBltAna));
      if (savedTempPsData) setTempPsData(JSON.parse(savedTempPsData));
      if (savedTolerancia) setTolerancia(JSON.parse(savedTolerancia));

      const savedDate = localStorage.getItem(`date-${site}`);
      if (savedDate) setDate(JSON.parse(savedDate));

      const savedSiteData = localStorage.getItem(`siteData-${site}`);
      if (savedSiteData) setSiteData(JSON.parse(savedSiteData));

      const savedSiteTable = localStorage.getItem(`siteTable-${site}`);
      if (savedSiteTable) setSiteTable(JSON.parse(savedSiteTable));

      console.log('Carregando dados do localStorage para site:', site);
    }
  }, [site]);

  // Salvar no localStorage quando os valores mudarem
  useEffect(() => {
    if (bltData && site) {
      localStorage.setItem(`bltData-${site}`, JSON.stringify(bltData));
    }
  }, [bltData, site]);

  useEffect(() => {
    if (bltProd && site) {
      localStorage.setItem(`bltProd-${site}`, JSON.stringify(bltProd));
    }
  }, [bltProd, site]);

  useEffect(() => {
    if (bltAna && site) {
      localStorage.setItem(`bltAna-${site}`, JSON.stringify(bltAna));
    }
  }, [bltAna, site]);

  useEffect(() => {
    if (tempPsData && site) {
      localStorage.setItem(`tempPsData-${site}`, JSON.stringify(tempPsData));
    }
  }, [tempPsData, site]);

  useEffect(() => {
    if (date && site) {
      localStorage.setItem(`date-${site}`, JSON.stringify(date));
    }
  }, [date, site]);

  useEffect(() => {
    if (siteData && site) {
      localStorage.setItem(`siteData-${site}`, JSON.stringify(siteData));
    }
  }, [siteData, site]);

  useEffect(() => {
    if (siteTable && site) {
      localStorage.setItem(`siteTable-${site}`, JSON.stringify(siteTable));
    }
  }, [siteTable, site]);
  
  return (
    <AnalisesContext.Provider value={{ 
        showImportBoletim, setShowImportBoletim, showAnaliseDia, setShowAnaliseDia, isDataComplete, setIsDataComplete, showBltxPlano, setShowBltxPlano,
        selectedDate, setSelectedDate, bltData, setBltData, psAnData, setPsAnData, anDate, setAnDate, bltProd, setBltProd, bltAna, setBltAna,
        tempPsData, setTempPsData, tolerancia, setTolerancia, showAllBmeBoletim, setShowAllBmeBoletim, date, setDate, siteData, setSiteData, siteTable, setSiteTable
        }}>
      {children}
    </AnalisesContext.Provider>
  )
}

export default AnalisesContext;