import GlobalStateContext from '../../../components/GlobalStateContext';
import { useContext, useState, useRef } from 'react';
import { Box, MenuItem } from '@mui/material';
import { FaFileArrowDown } from "react-icons/fa6"
import { LuFileSearch } from "react-icons/lu";
import { UseColors } from '../../../components/ui/colors';
import CryptoJS from 'crypto-js';
import { error } from 'jquery';
import AnalyticsHooks from '../../../Hooks/analyticsHooks';
import BmeHooks from '../../../Hooks/bmeHooks';

const FileUploaderOld = ({handleClose}) => {
  const {setData, data, user, site, setAlert, setBmeFileName} = useContext(GlobalStateContext); 
  const [jsonData, setJsonData] = useState(null);
  const fileInputRef = useRef(null)

  const COLORS = UseColors()
  const {newAction} = AnalyticsHooks()
  const {calcBME} = BmeHooks()

    const handleFileChange = (e) => {
      newAction('abrirBME', site, user.email || '')
      const fileInput = document.getElementById('fileInputOld');
      const file = e.target.files[0];

      if (file) {
        const reader = new FileReader();
  
        reader.onload = (event) => {
          const text = event.target.result;
  
          // Dividindo o texto por linhas
          const lines = text.split('\n');
  
          // Remover as duas primeiras linhas
          const dataLines = lines.slice(2);
  
          // Inicializa um objeto para armazenar os dados JSON
          const jsonObj = {};
  
          // Para cada linha, dividir por vírgula e armazenar no objeto JSON
          dataLines.forEach((line) => {
            const [key, value] = line.split(',');
            if (key && value) {
              jsonObj[key] = parseFloat(value); // Parseia o valor para número
            }
          });
  
          // Define o estado com os dados JSON
          console.log('Imported', jsonObj)
          updateData(jsonObj)
          setAlert(true)
          const fileNameWithExtension = fileInput.value.split('\\').pop()
        const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.')
        // console.log('File Name:', fileNameWithExtension, fileName)
        setBmeFileName(fileName)
        // calcBME()
        if (fileInputRef.current) {
            fileInputRef.current.value = null; // Limpa o campo de input
          }
        };
  
        // Ler o arquivo como texto
        reader.readAsText(file);
      }
      handleClose()
    };

  function updateData(importedData) {
    let updatedData = { ...data };
    console.log('Data', data)
    for (const key in updatedData) {
       
      if (updatedData.hasOwnProperty(key) && importedData.hasOwnProperty(getEquivalentKey(key))) {
        updatedData[key] = {
          ...updatedData[key],
          valor: importedData[getEquivalentKey(key)]
        };
      }
    }
    setData(updatedData);
  }


  function getEquivalentKey(key){
    switch (key){
        case 'gerVaporVMCaldeiras': return 'gerVaporV22Caldeira1'
        case 'consEspTgCpVM': return 'consEspTG1'
        case 'consEspTBCaldeirasVM': return 'consEspTurboBomba'
        case 'tempVETBCaldeirasVM': return 'tempVaporEscapeTB'
        case 'ptTBCaldeirasVM': return 'ptTurboBomba'
        case 'rendCaldeirasVM': return 'rendCaldeira1'
        case 'purgaDesCaldeirasVM': return 'relPurgaCaldeiras'
        case 'tempEscTgCpVM': return 'tempVaporEscapeTG1'
        case 'ptTgCpVM': return 'ptGerTG1'
        case 'gerVaporVETgCpVM': return 'gerVaporVETG1'

        case 'tempVaporVEPic1M1': return 'tempVaporVEPicador'
        case 'consEspEnergiaPic1M1': return 'consEspEnergiaPicador'
        case 'consEspVaporPic1M1': return 'consEspVaporPicador'
        case 'consVaporVMPic1M1': return 'consVaporVMPicador'
        case 'ptPic1M1': return 'ptPicador'

        case 'tempVaporVEDesfM1': return 'tempVaporVEDesfibrador'
        case 'consEspEnergiaDesfM1': return 'consEspEnergiaDesfibrador'
        case 'consEspVaporDesfM1': return 'consEspVaporDesfibrador'
        case 'consVaporVMDesfM1': return 'consVaporVMDesfibrador'
        case 'ptDesfM1': return 'ptDesfibrador'

        case 'tempVaporVEAc12TM1': return 'tempVaporVEAcion12T'
        case 'consEspEnergiaAc12TM1': return 'consEspEnergiaAcion12T'
        case 'consEspVaporAc12TM1': return 'consEspVaporAcion12T'

        case 'tempVaporVEAc34TM1': return 'tempVaporVEAcion34T'
        case 'consEspEnergiaAc34TM1': return 'consEspEnergiaAcion34T'
        case 'consEspVaporAc34TM1': return 'consEspVaporAcion34T'

        case 'tempVaporVEAc56TM1': return 'tempVaporVEAcion56T'
        case 'consEspEnergiaAc56TM1': return 'consEspEnergiaAcion56T'
        case 'consEspVaporAc56TM1': return 'consEspVaporAcion56T'
       
        case 'consEspVMAnidro': return 'consEspV22Anidro'
        case 'consVaporVEPenMol': return 'consVaporVEDesidratacao'
        case 'consVaporVMDesidratacao': return 'consVaporV22Desidratacao'

        case 'consEspVaporVMSeclevedura': return 'consEspVaporV22Seclevedura'
        case 'aprovVFLAcucar': return 'utilVFLAcucar'

        case 'pressureVM': return 'pressureV21'
        case 'consVaporVMEtMilho': return 'ConsVaporV22EtMilho'
        case 'perdasVM': return 'perdasV22'
        case 'tempVaporVM': return 'tempVaporV22'
        case 'aprovVFLAcucar': return 'utilVFLAcucar'
        case 'aprovVFLAcucar': return 'utilVFLAcucar'
        default: return key
    }
  }


  const styles = {
    menuButton: {
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '1.5vh',
      color: COLORS.primaryColor,
      height: '80%',
      alignSelf: 'center',
      width: '6vh',
      margin: '1.5vh',
      // border: '1px solid red'
    }
  };

    return (
      <Box 
      component="label"
      htmlFor="fileInputOld"
      >
      <MenuItem  disableRipple sx={{width: '100%'}}>
      <LuFileSearch style={{marginLeft: '2px', marginRight: '12px'}}/>
      Abrir .usl
    </MenuItem>
    <input id="fileInputOld" type="file" accept={'.usl'} onChange={handleFileChange} style={{ display: 'none'}} ref={fileInputRef}/>
    </Box>
    );
  };
  
  export default FileUploaderOld;

  


