import styles from './BarraSuperior.module.css'
import { getTitleFromArea } from '../../Funcoes/TextFuncs'
import React, { useContext } from 'react'
import GlobalStateContext from '../GlobalStateContext'
import PSContext from '../../PLANO SAFRA/opoio/PSContext'
import MenuBME from '../../BME/menuResProd/MenuBME';
import { UseColors } from '../ui/colors';
import { IoMdSwap } from "react-icons/io";
import BtCalcularBME from '../../BME/menuResProd/btCalcularBME';

function BarraSuperior() {
  const { area, user, menu, site, setSite, setData } = useContext(GlobalStateContext);
  const {setPsData} = useContext(PSContext)
  const COLORS = UseColors()

  const handleSite = () =>{
    setSite(null)
    setData(null)
    setPsData(null)
  }

  return (
    <div className={styles.barra_Superior}>
      <div className={styles.menu_BarraSup}>
    <div style={{marginTop: '2.5vh'}}>
    {area !== 'resumoProd' && menu === 'bme' &&
    <BtCalcularBME/>}
    </div>
      </div>
      <h2 className={styles.title_BarraSup}>{getTitleFromArea(area)}</h2>
      
      <div className={styles.logos_BarraSup}>
        <div style={{padding: '2vh', fontSize: '1.7vh', color: COLORS.primaryColor, fontWeight: 'bold'}}>
          <div>{user.userName}</div>
          <div>{user.sites[site].name}</div>
        </div>
        {Object.keys(user.sites).length > 1 &&
          <div style={{alignContent: 'center', marginRight: '10px', cursor: 'pointer'}} onClick={handleSite}><IoMdSwap color={COLORS.primaryColor}/></div>
        }
        <img className={styles.logo_BarraSup} src="/logo512.png" alt="logo pms"/>
      </div>
    </div> 
  )
}

export default BarraSuperior

