import React, {useContext, useState } from 'react';
import GlobalStateContext from '../../components/GlobalStateContext';
import { LuFileCheck2, LuFileX2 } from "react-icons/lu";
import Modal from '../../components/BarraSuperior/Modal';
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import { Badge, Button, Box } from "@mui/material";
import LoadingDialog from '../../components/ui/LoadingDialog';

export default function BtCalcularBME() {
    // const [isLoading, setIsLoading] = useState(false);
    // const [progress, setProgress] = useState(0);
    // const [modalText, setModalText] = useState('Calculando...');
    const { isAlertOn, setAlert, setData, data, area, user, site } = useContext(GlobalStateContext);
    const {newAction} = AnalyticsHooks()
    const [openLoading, setOpenLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');

    const hanldeAlert = (value) => {
        setAlert(value)
      }

  const fetchData2 = async () => {
    newAction('calcularBME', site, user.email)
    setOpenLoading(true)
    setLoadingMessage('Calculando...')
    // let progressValue = 0;
    // const interval = setInterval(() => {
    //   progressValue += 1;
    //   if (progressValue <= 100) {
    //     setProgress(progressValue)
    //   } else {
    //     clearInterval(interval)
    //     setLoadingMessage('Atualizando...')
    //   }
    // }, 0.5);

    try {
      const response = await fetch(''+global.endPoint+'/'+site+'/'+user?.uid+'/calcAllLoc', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({data: data})
      });
      if (!response.ok) {
        console.log('response', response)
        throw new Error('Erro ao buscar dados da API');
      }
      const jsonData = await response.json()
      setOpenLoading(false)
      hanldeAlert(false)
      // console.log('Data', jsonData)
      setData(jsonData)
      console.log('Data Updated', jsonData)
    } catch (error) {
      console.error('Erro ao buscar dados da API:', error);
      setOpenLoading(false)
    }
  }

  const calcBME = async () => {
     await fetchData2()
  }

  return (
    <Box
      sx={{
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
      }}
    >
        <Button  
          variant={isAlertOn ? 'contained' : 'outlined'} 
          onClick={calcBME} 
          color={isAlertOn ? 'error' : 'primary'}
          startIcon={!isAlertOn ? <LuFileCheck2/> : <LuFileX2/>}
          size='medium'
          sx={{fontWeight: 'bold', }}>
          
          CALCULAR
        </Button>

      <LoadingDialog open={openLoading} statusMessage={loadingMessage}/>

    </Box>
    
 
   )
 }

