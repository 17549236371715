import React, {useContext, useEffect, useState, useRef } from 'react';
import PSContext from '../opoio/PSContext';
import GlobalStateContext from '../../components/GlobalStateContext';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import Modal from '../../components/BarraSuperior/Modal';
import Headlandscape from '../../components/ui/HeadLandscape';
import PsTest from '../PsTest';
import { UseColors } from '../../components/ui/colors';
import { FaRegFilePdf } from "react-icons/fa6";
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import MenuBtDivider from '../../components/ui/menuBtDivider';
import SubMenuCharts from '../subMenus/subMenuCharts';
import BodyPlanoSafra from '../bodyPlanoSafra';
import LoadingDialog from '../../components/ui/LoadingDialog';

export default function BtExportPdfPS() {
  const divRefs = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [modalText, setModalText] = useState('...');
  const { site, user } = useContext(GlobalStateContext)
  const {isSave, isNew, isSaveAs, isOpen, isEdit, isChart, psData, setPsIsPrinting} = useContext(PSContext)
  const [isDisable, setIsDisable] = useState(false)

  const [openLoading, setOpenLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  
  const {newAction} = AnalyticsHooks()

  const COLORS = UseColors()

  useEffect(() => {
    if(isOpen || !psData){
      setIsDisable(true)
    }else{
      setIsDisable(false)
    }
  }, [isSave, isNew, isSaveAs, isOpen, isEdit, isChart])

  const addToRefs = (el) => {

    if (el && !divRefs.current.includes(el)) {
      divRefs.current.push(el);
    }
  };

   
    async function exportPDF(){
      
      newAction('expPDFPS', site, user.email || '')
      setPsIsPrinting(true)
      // setIsLoading(true)
      // setProgress(0)
      // setModalText('Gerando PDF...')
      // let progressValue = 0;
      // const interval = setInterval(() => {
      //   progressValue += 5;
      //   if (progressValue <= 100) {
      //     setProgress(progressValue)
      //   } else {
      //     clearInterval(interval)
      //     setIsLoading(false)
      //     setPsIsPrinting(false)
      //   }
      // }, 6);

    setLoadingMessage('Carregando Elementos...')
    setOpenLoading(true)
    
    await new Promise(resolve => setTimeout(resolve, 3000));

    setLoadingMessage('Gerando PDF...')
     
      const pdf = new jsPDF('l', 'mm', 'a4', true);
      
      let promiseChain = Promise.resolve();
  
      divRefs.current.forEach((div, index) => {
        console.log('index: '+index)
        
        promiseChain = promiseChain.then(async () => {
          const canvas = await html2canvas(div, {
            scale: 3, // Aumente o valor para melhorar a qualidade
            useCORS: true, // Garante que imagens externas sejam capturadas
            allowTaint: true, // Permite capturar imagens de outras origens
            logging: false, // Reduz o log no console
        });
          const imgData = canvas.toDataURL('image/png');
          const imgWidth = 277; // largura em mm para A4
          const imgHeight = 190; //(canvas.height * imgWidth) / canvas.width;

          pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);
          if (index < divRefs.current.length - 1){
            pdf.addPage("a4", "l");
          }
          

          
        });

      });
  
  
  
      promiseChain.then(() => {
        pdf.save('PlanoSafra_'+psData[0].safra+'_'+psData[0].revisao+'.pdf')
        setOpenLoading(false)
      }).catch(err => {
        console.error('Erro ao gerar PDF:', err);
        setOpenLoading(false)
      });
    
      
    };

    if (!isOpen && !isEdit){
  return (
    <div style={{display: 'flex', flexDirection: 'row'}}>
      {psData && 
      <div style={{ position: 'absolute', left: '-9999px' , top: '9999px'}}>
    
      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: ' page='1' pages='6'/>
            <div style={{height: '630px', width: '1000px', position: 'relative', lineHeight: '1' , top: '0', left: '0'}}>
                <BodyPlanoSafra sections={['MATÉRIA PRIMA', 'UPTIME', 'MOAGEM', 'MIX', 'EFICIÊNCIAS']}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: '  page='2' pages='6'/>
            <div style={{height: '630px', width: '100%', position: 'relative', lineHeight: '1', top: '0', left: '0'}}>
                <BodyPlanoSafra sections={['PERDAS', 'AÇÚCAR', 'ETANOL']} print={true}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: ' page='3' pages='6'/>
            <div style={{height: '630px', width: '100%', position: 'relative', lineHeight: '1', top: '0', left: '0'}}>
                <BodyPlanoSafra sections={['ENERGIA', 'LEVEDURA', 'VAPOR', 'BIOMASSA']}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: ' page='4' pages='6'/>
            <div style={{height: '630px', width: '100%', position: 'relative', lineHeight: '1', top: '0', left: '0'}}>
                <BodyPlanoSafra sections={['DIAS NÃO EFETIVOS', 'OUTROS']}/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: ' page='5' pages='6'/>
            <div style={{height: '630px', width: '100%', position: 'relative', lineHeight: '1', top: '0', left: '0'}}>
                    <SubMenuCharts page='PAGE1' h='210px'/>
             </div>
      </div>

      <div ref={addToRefs} style={{margin: '10px', backgroundColor: '#ffffff', height: '794px', position: 'relative', border: '1px solid black'}}>
            <Headlandscape title='PLANO SAFRA' subtitle={''+psData[0].safra+' - '+psData[0].revisao} fileName={`${psData[0].safra} - ${psData[0].revisao}`} nomeField='Revisão: ' page='6' pages='6'/>
            <div style={{height: '630px', width: '100%', position: 'relative', lineHeight: '1', top: '0', left: '0'}}>
            <SubMenuCharts page='PAGE2' h='210px'/>
             </div>
      </div>

    </div>
      }
      
      <MenuBtDivider/>
      <button className='psButtom' onClick={exportPDF}
        disabled={isDisable}>
        <div className='psMenuButtom' >
        <FaRegFilePdf  className='psMenuIcon' />
        <div className='psMenuText'>EXPORTAR</div></div>
      </button>
      <LoadingDialog open={openLoading} statusMessage={loadingMessage} />
      <Modal show={isLoading} progress={progress} texto={modalText}/>
    
  
    </div>
  )
}
}