// components


import {useEffect, useContext, useState } from 'react';

import './App.css';
import Main from './components/Main';
import Login from './components/Login'
import { GlobalStateContext }  from './components/GlobalStateContext'
import { getAlertList } from './Funcoes/GetAletrsList';
import { useFetch } from './Hooks/useFetch';
import ListaSites from './components/ListaSites';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UseColors } from './components/ui/colors';
import CustomTheme from './css/CustomTheme';

function App() {
  const {setData, data, setlistAlerts, setUser, user, site} = useContext(GlobalStateContext)
  const [upd, setUpd] = useState(false)

  const COLORS = UseColors()
  const theme = CustomTheme(COLORS)

  const userDev = {
    profile: 'developer',
    id: 1,
    email: 'emalmeida@gmail.com',
    firstName: 'Eduardo',
    lastName: 'Almeida',
    units: ['all']
  }

  // global.endPoint = 'https://pms-api-santaelisa.onrender.com'
  // global.endPoint = 'HTTP://localhost:7096'

  useEffect(() => {

  }, [site])

  // const theme = createTheme({
  //   components: {
  //     MuiTabs: {
  //       styleOverrides: {
  //         root: {
  //           height: "30px !Important",
  //           minHeight: "30px !Important",
            
  //           // border: "1px solid red"
  //           // backgroundColor: "#f5f5f5",
  //           // borderRadius: "8px",
  //         },
  //         indicator: {
  //           backgroundColor: COLORS.primaryColor, // Cor do indicador (linha ativa)
           
  //         },
  //       },
  //     },
  //     MuiTab: {
  //       styleOverrides: {
  //         root: {
  //           fontWeight: "bold",
  //           fontSize: '12px',
  //           color: "#555", // Cor padrão das abas inativas
  //           flexGrow: 1,
  //           paddingTop: "0 !Important",
  //           "&.Mui-selected": {
  //             // color: 'white', // Força a cor da aba ativa
  //             color: COLORS.primaryColor
  //           },
  //           "&:hover": {
  //             color: COLORS.primaryColor, // Cor ao passar o mouse
  //           },
  //         },
  //       },
  //       defaultProps: {
  //         disableRipple: true, // Evita efeitos que possam interferir na personalização
  //       },
  //     },
  //     MuiOutlinedInput: {
  //       styleOverrides: {
  //         root: {
  //           height: "36px", // Ajusta a altura do TextField
  //           "& .MuiInputBase-input": {
  //             padding: "10px 14px", // Ajusta o espaçamento interno do input
  //           },
  //         },
  //       },
  //     },
  //     MuiInputLabel: {
  //       styleOverrides: {
  //         root: {
  //           padding: "0",
  //           // fontSize: "1.1rem", // Tamanho da fonte do label
  //           // fontWeight: "bold", // Negrito opcional
  //           // transform: "translate(14px, 12px) scale(1)", // Ajusta a posição inicial
  //           // color: "#333", // Cor opcional
            
  //         },
  //         shrink: {
  //           // transform: "translate(11px, -10px) scale(0.75)", // Ajusta quando o label sobe
  //         },
  //       },
  //     },
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           height: 34, // Define a altura do botão
  //           minHeight: 34, // Garante altura mínima
  //           padding: "8px 16px", // Ajuste interno
  //         },
  //       },
  //     },
  //   },
  // });

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className='container'>
          {/* {user ? <Main/> : 
            <Login/>} */}
            {user ? 
            site ? <Main/> : <ListaSites/>  : 
            <Login/>}
          {/* <Main/> */}
        </div>
      </div>
      </ThemeProvider>
  )
}

export default App;

