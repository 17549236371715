import React, { useState, useContext, useEffect, useRef } from 'react'
import '../../css/InputLine.css'
import GlobalStateContext from '../GlobalStateContext'
import SwitchControl from "../../components/ui/Switch";
import ModalUpdateValue from '../ui/ModalUpdateValue';


function SwitchLine({cod, handleCountChange}) {
  const { setAlert, data, user, showUpdating, setData } = useContext(GlobalStateContext);
 
  const [selectedValue1, setSelectedValue1] = useState(parseInt(data[cod]?.valor))
  const [showMoadal, setShowModal] = useState(false)

  useEffect(() => {
    console.log('switch er', cod)
  }, [ ]);

  useEffect(() => {
    setSelectedValue1(`${parseInt(data[cod]?.valor)}`) 
  }, [ cod, data]);


  const updateValue = async (val) => {
    // console.log('show', showUpdating)
      setSelectedValue1(val)
      // setShowModal(true)
    //   console.log('show', showUpdating)
    //   console.log(''+val + ': '+cod)
    //   await fetch(''+global.endPoint+'/'+user?.uid+'/setValue', {
    //   method: 'PUT',
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({codigo: cod, newValue: val})
    // })
    // .then(response => {
    //   if (!response.ok) {
    //     setShowModal(false)
    //     throw new Error('Erro de requisição.');
    //   }else{
        console.log('updated: '+val)
        setData(prevData => ({
          ...prevData,
          [cod]: {
            ...prevData[cod],
            valor: val,
          },
        }));

        // data[cod].valor = val
        setAlert(true)
        handleCountChange(cod)
        // setShowModal(false)
    //   }
    // })
    // .catch(error => {
    //   setShowModal(false)
    //   // Lida com o erro da requisição
    // })
  }

  return (
   <>
    <SwitchControl
      descricao={data[cod].descricao}
        name="group-1"
        callback={(val) => updateValue(val)}
        controlRef={useRef()}
        defaultIndex={selectedValue1}
        segments={[
            {
              label: "0",
              value: 0,
              ref: useRef()
            },
            {
              label: "1",
              value: 1,
              ref: useRef()
            }
          ]}
      />
      {/* <ModalUpdateValue show={showMoadal}><p>Atualizando...</p></ModalUpdateValue> */}
   </>
  )
}

export default SwitchLine

