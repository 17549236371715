module.exports = {
    stdValue,
    stdValue2,
    iptValue
}

function stdValue(stringValue){
    if (stringValue === undefined || stringValue === null){
        return ''
    }
    let doubelValue = Math.abs(parseFloat(stringValue))
    let value = parseFloat(stringValue)
    let n = 0
    if (doubelValue > 10000){
        n = 0
    }else if (doubelValue < 10000 && doubelValue >= 1000){
        n = 0
    }else if (doubelValue < 1000 && doubelValue >= 100){
        n = 1
    }else if (doubelValue < 100 && doubelValue >= 10){
        n = 2
    }else if (doubelValue < 10 && doubelValue >= 1){
        n = 2
    }else if (doubelValue < 1 && doubelValue >= 0.1){
        n = 2
    }else if (doubelValue < 0.1 && doubelValue >= 0.01){
        n = 3
    }else{
        n = 3
    }
    let newValue = value.toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: n
    })

    return newValue
}

function stdValue2(stringValue){
    if (stringValue === undefined || stringValue === null){
        return 0
    }
    let doubelValue = Math.abs(parseFloat(stringValue))
    let value = parseFloat(stringValue)
    let n = 0
    if (doubelValue > 10000){
        n = 1
    }else if (doubelValue < 10000 && doubelValue >= 1000){
        n = 1
    }else if (doubelValue < 1000 && doubelValue >= 100){
        n = 2
    }else if (doubelValue < 100 && doubelValue >= 10){
        n = 2
    }else if (doubelValue < 10 && doubelValue >= 1){
        n = 2
    }else if (doubelValue < 1 && doubelValue >= 0.1){
        n = 2
    }else if (doubelValue < 0.1 && doubelValue >= 0.01){
        n = 3
    }else{
        n = 4
    }
    let newValue = value.toLocaleString('pt-BR', {
        minimumFractionDigits: 0,
        maximumFractionDigits: n
    })

    return newValue
}

function iptValue(stringValue){
    if (stringValue === undefined || stringValue === null){
        return 0
    }
    let doubelValue = Math.abs(parseFloat(stringValue))
    let value = parseFloat(stringValue)
    let n = 0
    if (doubelValue > 10000 || doubelValue === 0){
        n = 0
    }else if (doubelValue < 10000 && doubelValue >= 1000){
        n = 1
    }else if (doubelValue < 1000 && doubelValue >= 100){
        n = 1
    }else if (doubelValue < 100 && doubelValue >= 10){
        n = 2
    }else if (doubelValue < 10 && doubelValue >= 1){
        n = 3
    }else if (doubelValue < 1 && doubelValue >= 0.1){
        n = 4
    }else if (doubelValue < 0.1 && doubelValue >= 0.01){
        n = 5
    }else{
        n = 6
    }
    // let newValue = value.toFixed(n)

    let newValue = value.toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: n
    })

    return newValue
}