import { useContext, useState } from 'react';
import axios from 'axios';
import GlobalStateContext from '../../../components/GlobalStateContext';
// import usePsHooks from '../../../Hooks/psHooks';

const useJBAHooks = () => {
    const [tokenInfo, setTokenInfo] = useState({ token: null, expiry: null });
    const {site, user} = useContext(GlobalStateContext)
    // const {openPS} = usePsHooks()

    const getToken = async () => {
        // Verifica se o token existe e se ainda não expirou (com margem de 5 minutos)
        if (tokenInfo.token && Date.now() < tokenInfo.expiry) {
            return tokenInfo.token;
        }

        try {
            console.log('Gerando Token...')
        //   setStatusMessage("Gerando Token...");
          const authResponse = await axios.get('https://app.grupojb.com.br/cs0241p/agricola/api/auth', {
              headers: {
                'username': 'PMSAPI',
                'password': 'IntegracaoPMS2025#'
              }
            });

          const newToken = authResponse.data.auth[0].access_token;
          const expiryTime = Date.now() + authResponse.data.auth[0].expires_in * 1000; // 1 hora (3600 segundos)

            setTokenInfo({ token: newToken, expiry: expiryTime });
            return newToken;
        } catch (error) {
            console.error('Erro ao obter token:', error);
            throw error;
        }
    }

    const getBltsJBA = async (date) => {
        console.log('BLTS', date)
        try{
            const token = await getToken();
            const formattedDate = date.toLocaleDateString('pt-BR'); // Formato "DD/MM/YYYY"
            console.log('BLTS', formattedDate)

            // Criando os dois request bodies
            const requestBody1 = {"sql": { "id": 1214 },
                "params": [
                { "name": "pn_relatorio", "value": 20 },
                { "name": "pn_safra", "value": 20 },
                { "name": "pd_data", "value": formattedDate }]
            };

            const requestBody2 = { "sql": { "id": 1214 },
                "params": [
                { "name": "pn_relatorio", "value": 21 },
                { "name": "pn_safra", "value": 21 },
                { "name": "pd_data", "value": formattedDate }]
            };

            const [dataResponse1, dataResponse2] = await Promise.all([
                axios.post('https://app.grupojb.com.br/cs0241p/agricola/api/query', requestBody1, {
                headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' }
            }),
                axios.post('https://app.grupojb.com.br/cs0241p/agricola/api/query', requestBody2, {
                headers: { 'Authorization': `${token}`, 'Content-Type': 'application/json' }
            })
            ]);
            console.log('BLTS', date,  {bltProd: dataResponse1.data.dataset, bltAna: dataResponse2.data.dataset})
            return {bltProd: dataResponse1.data.dataset, bltAna: dataResponse2.data.dataset}
        }catch(error){
            console.log('Erro ao buscar Boletins', error)
            return null
        }
      }

      async function updateRealMonth(psReal, month, lastDay) {
        console.log('Atualizando... ', month, psReal.find(i => i.codigo === 'psRealUpdated')[month])
        let realVars = await getRealVarsList()
        let temp = psReal
        let bltsData = null
        let tentativas = 0
        const maxTentativas = 6
        
        try {
            while (tentativas < maxTentativas) {
                bltsData = await getBltsJBA(lastDay);
                console.log('Tentativa', tentativas + 1, 'bltsData:', bltsData)
                
                if (!bltsData || !bltsData.bltProd || !bltsData.bltAna) {
                    console.log(`Tentativa ${tentativas + 1} falhou, aguardando 5 segundos...`)
                    await new Promise(resolve => setTimeout(resolve, 5000));
                    tentativas++;
                    continue;
                }

                for (const item of realVars) { 
                    let realValue = await getRealValues(item, bltsData.bltProd, bltsData.bltAna, 'MES')
                    temp.find(i => i.codigo === item)[month] = realValue
                }
                temp.find(i => i.codigo === 'psRealUpdated')[month] = 1
                await updatePS(temp, user.userEmail);
                return temp;
            }
            
            if (tentativas >= maxTentativas) {
                console.error('Número máximo de tentativas atingido para o mês:', month);
                return psReal;
            }
        } catch (error) {
            console.error('Erro ao atualizar mês:', error);
            return psReal;
        }
    }

    async function updateRealUsJbAlcoolquimica(safra) {
        let psReal = await openPS(`${site}-${safra}-13_Realizado`);
        let temp = psReal
        let months = ['set', 'out', 'nov', 'dez', 'jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago'];
        let psRealItem = temp.find(i => i.codigo === 'psRealUpdated')
        if (!psRealItem) {
            temp.push({codigo: 'psRealUpdated', jan: 0.0, fev: 0.0, mar: 0.0, abr: 0.0, mai: 0.0, jun: 0.0, jul: 0.0, ago: 0.0, set: 0.0, out: 0.0, nov: 0.0, dez: 0.0, desc: '', unid: '', show: true})
            psRealItem = temp.find(i => i.codigo === 'psRealUpdated')
        }
    
        for (const month of months) { 
            let prevMonth = isPreviousMonth(safra, month);
            // if (!psRealItem.hasOwnProperty(month)) {
            //     psRealItem[month] = 0.0;
            // }else{
                psRealItem[month] = 0
            // }
            console.log('Atualizar??? ', safra, month, psRealItem[month])
            if (prevMonth.update && psRealItem[month] !== 1){
                psReal = await updateRealMonth(temp, month, prevMonth.lastDay)
            }
        }
        
    }

    const openPS = async (ssr) => {
        try{
            const response  = await fetch(`${global.endPoint}/getps?ssr=${ssr}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            })
            if (!response.ok) {
                throw new Error('Erro ao buscar dados da API');
            }
            const jsonData = await response.json();
            return jsonData
            }catch (error){
            console.error('Erro ao Importar Arquivo:', error);
            return null
            }
    }

    const updatePS = async (ps, userEmail = '') => {
        // console.log('Update PS', ps)
      try{
          const response  = await fetch(`${global.endPoint}/updateps?userEmail=${userEmail}`, {
              method: 'PUT',
              headers: {
              'Content-Type': 'application/json'
              },
              body: JSON.stringify(ps)
          })
          if (!response.ok) {
              throw new Error('Erro ao buscar dados da API');
          }
          const jsonData = await response.json();
          return jsonData
          }catch (error){
          console.error('Erro ao Importar Arquivo:', error);
          return null
          }
    }

  

    return {getBltsJBA, updateRealUsJbAlcoolquimica}
}

export default useJBAHooks

function getRealValues(cod, bltProd, bltAna, dm){
    try{
      switch (cod){
        case 'psAtrPcts': return bltAna.filter(i => i.LINHA === 1024)[0][dm] || 0
        case 'psAtrDigestor': return bltAna.filter(i => i.LINHA === 1022)[0][dm] || 0
        case 'psFibraCana': return bltProd.filter(i => i.LINHA === 6010)[0][dm] || 0
        // case 'psFibraBagaco': return bltAna.filter(i => i.LINHA === 1024)[0][dm] || 0
        // case 'psBagacoCana': return bltAna.filter(i => i.LINHA === 1024)[0][dm] || 0
        case 'psUptimeGeral': return  bltProd.filter(i => i.LINHA === 755)[0][dm] || 0
        case 'psDiasSafra': return bltProd.filter(i => i.LINHA === 15)[0][dm] || 0
        case 'psEficExtracao': return bltProd.filter(i => i.LINHA === 5545)[0][dm] || 0
        case 'psTaxaMoagem': return bltProd.filter(i => i.LINHA === 760)[0][dm] || 0
        case 'psTaxaArt': return bltProd.filter(i => i.LINHA === 760)[0][dm]*bltAna.filter(i => i.LINHA === 1022)[0][dm]/100 || 0
        case 'psTaxaAtr': return bltProd.filter(i => i.LINHA === 760)[0][dm]*bltAna.filter(i => i.LINHA === 1024)[0][dm]/1000 || 0
        case 'psTaxaFibra': return bltProd.filter(i => i.LINHA === 760)[0][dm]*bltProd.filter(i => i.LINHA === 6010)[0][dm]/100 || 0
        case 'psMoagemTotal': return bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        case 'psMoagemDiaria': return bltProd.filter(i => i.LINHA === 705)[0][dm]/bltProd.filter(i => i.LINHA === 15)[0][dm] || 0
        case 'psMoagemDiariaEf': return bltProd.filter(i => i.LINHA === 705)[0][dm]/bltProd.filter(i => i.LINHA === 15)[0][dm]/(bltProd.filter(i => i.LINHA === 755)[0][dm]/100) || 0
        // case 'psMixAcucar': return bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        // case 'psMixEtanol': return bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        case 'psCanaFabrica': return bltProd.filter(i => i.LINHA === 710)[0][dm]*100/bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        case 'psCanaEtanol': return bltProd.filter(i => i.LINHA === 715)[0][dm]*100/bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        case 'psArtEntrado': return bltProd.filter(i => i.LINHA === 705)[0][dm]*bltAna.filter(i => i.LINHA === 1022)[0][dm]/100 || 0
        // case 'psArtRecuperado': return bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        // case 'psEficienciaIndustrial': return bltProd.filter(i => i.LINHA === 705)[0][dm] || 0
        case 'psRTC': return bltProd.filter(i => i.LINHA === 5580)[0][dm] || 0
        case 'psRecSJM': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaLavavem': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaBagaco': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaTorta': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaMultijato': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaFermentacao': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaResiduaria': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psPerdaIndeterminada': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        case 'psProdAcucarTotalSC': return bltProd.filter(i => i.LINHA === 3510)[0][dm] + bltProd.filter(i => i.LINHA === 4010)[0][dm] + bltProd.filter(i => i.LINHA === 4510)[0][dm] || 0
        // case 'psProdAcucarTotal': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psMixProdAcucarVHP': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        // case 'psProdAcucarVHP': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        case 'psProdAcucarVHPSC': return bltProd.filter(i => i.LINHA === 3510)[0][dm] || 0
        // case 'psProdAcucarCristal': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        case 'psProdAcucarCristalSC': return bltProd.filter(i => i.LINHA === 4510)[0][dm] || 0
        case 'psProdAcucarDemeraraSC': return bltProd.filter(i => i.LINHA === 4010)[0][dm] || 0
        // case 'psTaxaEfProdAcucar': return bltProd.filter(i => i.LINHA === 5585)[0][dm] || 0
        case 'psTaxaEfProdAcucarScs': return (gV(3510, bltProd, dm) + gV(4010, bltProd, dm) + gV(4510, bltProd, dm))/gV(15, bltProd, dm)/(gV(755, bltProd, dm)/100) || 0
        case 'psProdTotalEtanol100': return gV(5525, bltProd, dm)/1000 || 0
        case 'psProdEtanol100MelTerc': return gV(5530, bltProd, dm)/1000 || 0
        case 'psProdEtanolTotalHid': return gV(2015, bltProd, dm)/1000 || 0
        case 'psProdEtanolAnidro': return gV(1010, bltProd, dm)/1000 || 0
        case 'psProdEtanolNeutro': return gV(3020, bltProd, dm)/1000 || 0
        case 'psProdEtanolDest': return gV(1515, bltProd, dm)/1000 || 0
        case 'psProdEtanolTotal': return gV(5510, bltProd, dm)/1000 || 0
        case 'psRGD': return gV(5555, bltProd, dm)*gV(5560, bltProd, dm)/100 || 0
        case 'psFlowMelTerc': return gV(6535, bltProd, dm) || 0
        case 'psEnergiaGerada': return gV(8530, bltProd, dm)/1000 || 0
        case 'psEnergiaExportada': return gV(8560, bltProd, dm)/1000 || 0
        case 'psEnergiaConsumida': return gV(8570, bltProd, dm)/1000 || 0
        case 'psPtExportadaTC': return gV(8560, bltProd, dm)/gV(705, bltProd, dm) || 0
        case 'psPtConsumidaTC': return gV(8570, bltProd, dm)/gV(705, bltProd, dm) || 0
        case 'psProdVapor': return gV(9510, bltProd, dm)+gV(9520, bltProd, dm) || 0
        case 'psConsVaporTcTotal': return gV(9580, bltProd, dm) || 0
        case 'psVaporTgCond': return gV(9530, bltProd, dm) || 0
        case 'psVaporRebaixado': return gV(9560, bltProd, dm) || 0
        case 'psVendaBagaco': return gV(7020, bltProd, dm) || 0
        case 'psProdTortaTc': return gV(7100, bltProd, dm) || 0
        default: return 0
      }
    }catch{
      return 0
    }
  }

  function gV(l, blt, dm){
    try{
      let valor = blt.filter(i => i.LINHA === l)[0][dm]
      return !valor ? 0 : valor
    }catch{
      return 0
    }
  
  }

  async function getRealVarsList(){
    return [
         'psAtrPcts','psAtrDigestor','psFibraCana','psUptimeGeral','psDiasSafra',
         'psEficExtracao','psTaxaMoagem','psTaxaArt','psTaxaAtr','psTaxaFibra',
         'psMoagemTotal','psMoagemDiaria','psMoagemDiariaEf','psCanaFabrica','psCanaEtanol',
         'psArtEntrado','psRTC','psRecSJM','psProdAcucarTotalSC','psProdAcucarVHPSC','psProdAcucarCristalSC',
         'psProdAcucarDemeraraSC','psTaxaEfProdAcucarScs','psProdTotalEtanol100','psProdEtanol100MelTerc',
         'psProdEtanolTotalHid','psProdEtanolAnidro','psProdEtanolNeutro','psProdEtanolDest',
         'psProdEtanolTotal','psRGD','psFlowMelTerc','psEnergiaGerada','psEnergiaExportada','psEnergiaConsumida',
         'psPtExportadaTC','psPtConsumidaTC','psProdVapor','psConsVaporTcTotal','psVaporTgCond','psVaporRebaixado',
         'psVendaBagaco','psProdTortaTc'
    ]
  }

  function getBMEVarsList(){
    return [
      {bmeCod: 'flowCana', blt: 'PROD', linha: 760},
      {bmeCod: 'horasEfetivas', blt: 'PROD', linha: 750},
      {bmeCod: 'artCana', blt: 'ANAL', linha: 1022},
      {bmeCod: 'purezaCana', blt: 'ANAL', linha: 1060},
      {bmeCod: 'fibraCana', blt: 'ANAL', linha: 1070},
      {bmeCod: 'arCana', blt: 'ANAL', linha: 1090},
      {bmeCod: 'embCana', blt: 'PROD', linha: 6050},
      // {bmeCod: 'fibraBagaco', blt: 'ANAL', linha: 000},
      {bmeCod: 'umidBagaco', blt: 'ANAL', linha: 1310},
      {bmeCod: 'polBagaco', blt: 'ANAL', linha: 1300},
      {bmeCod: 'efExtMoenda', blt: 'PROD', linha: 5545},
      {bmeCod: 'rtc', blt: 'PROD', linha: 5580},
      {bmeCod: 'efFermentacao', blt: 'PROD', linha: 5555},
      {bmeCod: 'efDestilaria', blt: 'PROD', linha: 5560},
      {bmeCod: 'flowMelTerceiros', blt: 'PROD', linha: 6535},
    ]
  }

  function isPreviousMonth(safra, mes) {
    const meses = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
    
    // Obtém o índice do mês informado
    const mesIndex = meses.indexOf(mes);
    if (mesIndex === -1) {
        throw new Error('Mês inválido');
    }

    // Obtém os anos da safra
    const [anoInicio, anoFim] = safra.split('/').map(s => `20${s}`);

    // Determina o ano do mês informado
    const anoReferencia = mesIndex < 8 ? parseInt(anoFim) : parseInt(anoInicio);
    // Obtém o último dia do mês informado
    const ultimoDia = new Date(anoReferencia, mesIndex + 1, 0);

    // Obtém a data atual
    const hoje = new Date();
    const anoAtual = hoje.getFullYear();
    const mesAtual = hoje.getMonth(); // Janeiro é 0, Fevereiro é 1...

    // Cria um objeto Date para a data da safra informada
    const dataInformada = new Date(anoReferencia, mesIndex, 1);
    // console.log('DATAS', ultimoDia, hoje, ultimoDia < hoje)

    // Compara as datas
    return {update: ultimoDia < hoje, lastDay: ultimoDia}
}

