import { BltsXBmeVars } from './tables';

export async function setBmeValuesFromReal(bltProd, bltAna, data){
    let tempData = {...data}
    BltsXBmeVars
    .filter(i => i.iptBme === true)
    .forEach(i => {
        if (data[i.bmeCod]){
            tempData[i.bmeCod].valor = i.blt === 'PROD' ? getDayProdValue(i.linha, bltProd) : getDayAnalValue(i.linha, bltAna)
        }
    })
    return tempData
}

export async function setBmeBoletimValuesFromReal(bltProd, bltAna, data){
    let tempData = {...data}
    BltsXBmeVars
    .filter(i => i.iptBmeBol === true)
    .forEach(i => {
        switch (i.bmeCod){
            case 'mixEtanolAnidro':
                tempData[i.bmeCod].valor = getDayProdValue(1010, bltProd) * 100 / getDayProdValue(5510, bltProd)
                break
            case 'mixEtanolNeutro':
                tempData[i.bmeCod].valor = getDayProdValue(3020, bltProd) * 100 / getDayProdValue(5510, bltProd)
                break
            case 'mixEtanolDest':
                tempData[i.bmeCod].valor = getDayProdValue(5010, bltProd) * 100 / getDayProdValue(5510, bltProd)
                break
            // case 'efFermentacao':
            //     tempData[i.bmeCod].valor = getDayProdValue(i.linha, bltProd)
            //     if(tempData[i.bmeCod].valor > 92){tempData[i.bmeCod].valor = 92}
            //     break
            default:
                tempData[i.bmeCod].valor = i.blt === 'PROD' ? getDayProdValue(i.linha, bltProd) : getDayAnalValue(i.linha, bltAna)
        }
        // if (i.bmeCod === 'efExtMoenda'){
        //     tempData['perdaArtExtracao'].valor = 100 - getDayProdValue(i.linha, bltProd)
        //     tempData[i.bmeCod].valor = i.blt === 'PROD' ? getDayProdValue(i.linha, bltProd) : getDayAnalValue(i.linha, bltAna)
        // }else 
        // if (i.bmeCod === 'flowTorta'){
        //     let horasEfetivas = getDayProdValue(750, bltProd)
        //     tempData['flowTorta'].valor = tempData['flowTorta'].valor * horasEfetivas
        // }else if (data[i.bmeCod]){
        //     tempData[i.bmeCod].valor = i.blt === 'PROD' ? getDayProdValue(i.linha, bltProd) : getDayAnalValue(i.linha, bltAna)
        // }
    })
    tempData['caldoFiltToAcucar'].valor = 100
    tempData['caldoSecToAcucar'].valor = 20
    return tempData
}

export function getDayProdValue(linha, bltProd){
    let cod = BltsXBmeVars.find(i => i.linha === linha)?.bmeCod
    let value = bltProd?.find(i => i.LINHA === parseInt(linha))?.DIA || 0

    
    if (linha === 10001){
        let consumoEnergia = bltProd?.find(i => i.LINHA === 8570)?.DIA || 0
        let flowCanaDia = bltProd?.find(i => i.LINHA === 705)?.DIA || 1
        console.log('consumoEnergia', consumoEnergia, 'flowCanaDia', flowCanaDia)
        value = consumoEnergia/flowCanaDia
    }else if (linha === 10002){
        let consumoMel = bltProd?.find(i => i.LINHA === 6540)?.DIA + bltProd?.find(i => i.LINHA === 6535)?.DIA 
        let horasEfetivas = bltProd?.find(i => i.LINHA === 810)?.DIA || 1
        value = consumoMel/horasEfetivas
    }else if (linha === 10003){
        let consumoMel = bltProd?.find(i => i.LINHA === 6540)?.DIA + bltProd?.find(i => i.LINHA === 6535)?.DIA 
        let producaoMel = bltProd?.find(i => i.LINHA === 6510)?.DIA
        let compraMel = bltProd?.find(i => i.LINHA === 6515)?.DIA || 0
        let horasEfetivas = bltProd?.find(i => i.LINHA === 810)?.DIA || 1
        value = (producaoMel + compraMel - consumoMel ) < 0 ? 0 : (producaoMel + compraMel - consumoMel )/horasEfetivas
    }else if (linha === 10004){
        let consumoMel = bltProd?.find(i => i.LINHA === 6540)?.DIA + bltProd?.find(i => i.LINHA === 6535)?.DIA 
        let producaoMel = bltProd?.find(i => i.LINHA === 6510)?.DIA
        let compraMel = bltProd?.find(i => i.LINHA === 6515)?.DIA || 0
        let horasEfetivas = bltProd?.find(i => i.LINHA === 810)?.DIA || 1
        value = (producaoMel + compraMel - consumoMel ) < 0 ? -(producaoMel + compraMel - consumoMel )/horasEfetivas : (producaoMel + compraMel - consumoMel )/horasEfetivas
    }else if (horasEfetivasDestList.includes(cod)){
        let horasEfetivas = bltProd?.find(i => i.LINHA === 810)?.DIA || 1
        value = value/horasEfetivas
    }
    
    return by1000List.includes(cod) ? value/1000 : value
  }
  
export function getDayAnalValue(linha, bltAna){
    return bltAna?.find(i => i.LINHA === parseInt(linha))?.DIA || 0
  }

export function getMonthProdValue(linha, bltProd){
    return bltProd?.find(i => i.LINHA === parseInt(linha))?.MES || 0
  }

export function getMonthAnalValue(linha, bltAna){
    return bltAna?.find(i => i.LINHA === parseInt(linha))?.MES || 0
  }

export function getYearProdValue(linha, bltProd){
    return bltProd?.find(i => i.LINHA === parseInt(linha))?.ANO || 0
  }

export function getYearAnalValue(linha, bltAna){
    return bltAna?.find(i => i.LINHA === parseInt(linha))?.ANO || 0
  }

let by1000List = ['flowEtanolAnidroDia', 'flowEtanolDestDia', 'flowEtanolHidDia', 'flowEtanolNeutroDia', 'expEnergiaDia',
    'flowEtanolTotal', 'flowEtanolTotalCana', 'flowEtanolTotalMelTerc', 'energiaGerada', 'consEnergiaDia', 'processoDestilariaOntem', 'processoDestilariaHoje'
]

let horasEfetivasDestList = ['flowMelFinal2', 'flowMelTerceirosComprado', 'flowMelTerceiros', 'flowMelpEstoque', 'flowMelEstoque']
  
export function convertDateToISO(dateString) {
    // Verifica se dateString existe e é string
    if (!dateString || typeof dateString !== 'string') {
        return null;
    }

    // Separa a data de forma segura
    const parts = dateString.split('/');
    if (parts.length !== 3) {
        return null;
    }

    const [dia, mes, ano] = parts;
    return `${ano}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
}