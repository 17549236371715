export function transformData(site, data) {
    switch (site) {
        case 'usLins':
            return uslDataTransform(data)
        default:
            return data
    }
}

function uslDataTransform(array) {
    return array.reduce((acc, item) => {
        const tag = item.mkmnr.slice(0, -1);
        const tipo = item.mkmnr.slice(-1);

        if (!acc[tag]) {
            acc[tag] = { tag };
        }
        acc[tag].subGrupo = item.txt1
        acc[tag].desc = item.txt2

        switch (tipo) {
            case 'D':
                acc[tag].dia = item.valor
                break;
            case 'M':
                acc[tag].mes = item.valor
                break;
            case 'A':
                acc[tag].safra = item.valor
                break;
        }
        return acc;
    }, {});
}

export async function setRealToBme(siteData, table, data){
    let temp = {...data}
    table?.map(item => {
        if (item.ipt && temp[item.bmeCod]){
            let bltValue = getBltValue(item.bltCod, siteData)
            if (!bltValue) return temp
            if (bltValue || bltValue === 0){
                temp[item.bmeCod].valor = bltValue
            }
        }
    })
    return temp
}

export function getBltValue(bltCod, siteData){
    
    let bltValue = temOperadorMatematico(bltCod) ? resolverEquacao(bltCod, siteData) : siteData[bltCod]?.dia
    // if (bltValue === 0){
    //     bltValue = null
    // }
    if (listZeraIfNegative.includes(bltCod)){
        bltValue = bltValue < 0 ? 0 : bltValue
    }
    return bltValue
}

export function getMtdValue(bltCod, siteData){
    
    let bltValue = temOperadorMatematico(bltCod) ? resolverEquacao(bltCod, siteData, 'MTD') : siteData[bltCod]?.mes || 0
    // if (bltValue === 0){
    //     bltValue = null
    // }
    if (listZeraIfNegative.includes(bltCod)){
        bltValue = bltValue < 0 ? 0 : bltValue
    }
    return bltValue
}


export function resolverEquacao(equacao, siteData, dm = 'DIA') {
    // Extrai as variáveis da string (agora incluindo % como parte do nome)
    const variaveis = equacao.match(/[a-zA-Z%]+[a-zA-Z0-9%]*/g) || [];
    
    const valores = {};
    variaveis.forEach(variavel => {
        if (dm === 'MTD'){
            valores[variavel] = siteData[variavel]?.mes || 0;
        }else{
            valores[variavel] = siteData[variavel]?.dia || 0;
        }
    });
    
    // Substitui as variáveis pelos valores na equação
    let equacaoResolvida = equacao;
    Object.entries(valores).forEach(([variavel, valor]) => {
        // Escapa o % para evitar problemas com regex
        const variavelEscapada = variavel.replace('%', '\\%');
        equacaoResolvida = equacaoResolvida.replace(new RegExp(variavelEscapada, 'g'), valor);
    });
    // Avalia a expressão matemática
    try {
        return eval(equacaoResolvida);
    } catch (error) {
        return 0;
    }
}


export function temOperadorMatematico(string) {
    if (!string || listExecption.includes(string)) return false
    const operadores = ['+', '-', '*', '/'];
    return operadores.some(op => string.includes(op)) || false
}

let listExecption = [
    'SCAC/TC', 'KTOR/T', 'CONA/TC', 'AFIM3/H'
]

//ESTOQUE MEL
let listZeraIfNegative = [
    '(KMELPRO-KMELCON)/1000/(KGMOIMT/KMOIHMT)', '(KMELCON-KMELPRO)/1000/(KGMOIMT/KMOIHMT)'
]