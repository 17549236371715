import React, {useContext, useEffect, useState } from 'react';
import { FaFileCirclePlus } from "react-icons/fa6";
import PSContext from '../opoio/PSContext';
import { listaSafras, listaRevs, listaRevs2 } from '../listasPlanoSafra';
// import { stdPlanoVars } from '../stdPlanoSafra'
import ModalAlert from '../../components/ui/ModalAlert';
import { UseColors } from '../../components/ui/colors';
import { calcPS } from '../opoio/calcPS';
import GlobalStateContext from '../../components/GlobalStateContext';
import AnalyticsHooks from '../../Hooks/analyticsHooks';
import psHooks from '../../Hooks/psHooks';
import ModalConfirm from '../../components/ui/ModalConfirm';
import { usePsCalcs } from '../../BME/Unidades/Geral/usePsCalcs';

export default function SubMenuCopy() {
  const {site, user} = useContext(GlobalStateContext)
  const psCalcs = usePsCalcs()
  const {psData, setPsData, psDataReal, setAllPsData, setIsSaveAs} = useContext(PSContext)
  const [selectedSafra, setSelectedSafra] = useState(listaSafras[0]);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedRev, setSelectedRev] = useState(listaRevs[0]);
  const [revName, setRevName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('')
  const [showModalMessage, setShowModalMessage] = useState(false)
  const {newAction} = AnalyticsHooks()
  const {openPS, newPS, updatePS, resumeAllPS} = psHooks()

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', COLORS.primaryColor);
  }, []);

  const COLORS = UseColors()

  const handleChangeSafra = (event) => {
    setSelectedSafra(event.target.value);
  };

  const handleChangeOption = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChangeRev = (event) => {
    setSelectedRev(event.target.value);
  };

  const handleChangeName = (event) => {
    setRevName(event.target.value);
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  async function copiarPS(){
    setModalMessage('Analisando...')
    setShowModalMessage(true)
    newAction('copiarPS', site, user.email || '')
    let rev = selectedOption === 'opcao1' ? selectedRev : revName
    let ssr = `${site}-${psData[0].safra}-${psData[0].revisao}`
    let ps = await openPS(ssr)
    let temp = []
    // console.log('Copiar PS', ps)
    
    for (const item of ps){
      let newItem = item
      newItem['safra'] = selectedSafra
      newItem['revisao'] = rev
      newItem['ssr'] = `${site}-${selectedSafra}-${rev}`
      temp.push(newItem)
      
    }
    // console.log('Ps to Copy', ps, site, psData[0].safra, rev, temp)
    let newps = await newPS(temp, user.email)
    // console.log('New PS', newps)
    if (newps.existe === true){
      setShowModalMessage(false)
      handleOpenModal()
    }else{
      setModalMessage('Copiando Plano...')
      let tempData = await psCalcs.calcPS(newps, site)
      await updatePS(tempData)
      setPsData(tempData)
    }
    let allPs = await resumeAllPS(site)
    setAllPsData(allPs)
    setShowModalMessage(false)
  }

  const listRev = site === 'usJbAlcoolquimica' ? listaRevs2 : listaRevs

  return (
    <>
     <div style={{fontSize: '1.6vh', textAlign: 'left', color: 'black', fontWeight: 'bold', alignSelf: 'left', paddingLeft: '2vh', paddingTop: '1vh'}}>COPIAR PLANO ({psData[0].safra} - {psData[0].revisao}) PARA</div>
    <div style={{display: 'flex', flexDirection: 'row', paddingLeft: '2vh'}}>
       
       <div style={{textAlign: 'left', marginRight: '3vh', paddingBottom: '2vh'}}>
      <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>SAFRA:</label>
      <select id="comboBox" value={selectedSafra} onChange={handleChangeSafra} style={{color: 'black'}}>
        {listaSafras.map((item) => {
            return (
                <option value={item} key={item}>{item}</option>
            )
        })}
      </select>
    </div>
   
    <div style={{textAlign: 'left',marginRight: '2vh'}}>
    <label style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>OPÇÃO:</label>
    <div style={{display: 'flex', flexDirection: 'row'}}>
      <div style={{fontSize: '1.8vh', marginRight: '1vh'}}>
        <input style={{accentColor: COLORS.primaryColor}}
          type="radio"
          id="option1"
          name="options"
          value="opcao1"
          checked={selectedOption === 'opcao1'}
          onChange={handleChangeOption}
          
        />
        <label htmlFor="option1">Revisão</label>
      </div>
      <div style={{fontSize: '1.8vh', marginRight: '1vh'}}>
        <input style={{accentColor: COLORS.primaryColor}}
          type="radio"
          id="option2"
          name="options"
          value="opcao2"
          checked={selectedOption === 'opcao2'}
          onChange={handleChangeOption}
        />
        <label htmlFor="option2">Estudo</label>
      </div>
      </div> 
    </div>
    {selectedOption === 'opcao1' && 
     <div style={{textAlign: 'left', marginRight: '3vh'}}>
     <label htmlFor="comboBox" style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>REVISÃO:</label>
     <select id="comboBox" value={selectedRev} onChange={handleChangeRev} style={{color: 'black'}}>
       {listRev.map((item) => {
           return (
               <option value={item} key={item}>{item}</option>
           )
       })}
     </select>
   </div>
    }

{selectedOption === 'opcao2' && 
     <div style={{display: 'flex', flexDirection: 'column', textAlign: 'left', marginRight: '3vh', marginTop: '1vh', width: '30vh'}}>
     <label  style={{fontSize: '1.2vh', textAlign: 'left', color: '#5c5c5c', fontWeight: 'bold'}}>NOME:</label>
     <input id="" value={revName} onChange={handleChangeName} style={{padding: '0.4vh', border: '1px solid lightGray'}}/>
   </div>
    }
  {selectedOption !== '' &&
  <button className="green-button" style={{width: '5vh', height: '50%', alignSelf: 'center'}} onClick={copiarPS}>OK</button>}
</div>
<ModalAlert show={showModal} onClose={handleCloseModal} title="Alerta">
<p>Plano já Existe!!!</p>

</ModalAlert>
<ModalConfirm show={showModalMessage} title="Alerta">
<div className='modal-title' style={{marginTop: '3vh', padding: '2vh'
}}>{modalMessage}</div>
</ModalConfirm>
</>
  )
  
}