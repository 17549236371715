import { createTheme } from "@mui/material/styles";

const getTheme = (COLORS) => createTheme({
  palette: {
    primary: {
      main: COLORS.primaryColor,
    },
    customGray: {
      main: "#555555", // Verde personalizado
    },
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          height: "30px !Important",
          minHeight: "30px !Important",
          
          // border: "1px solid red"
          // backgroundColor: "#f5f5f5",
          // borderRadius: "8px",
        },
        // indicator: {
        //   backgroundColor: COLORS.primaryColor, // Cor do indicador (linha ativa)
         
        // },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          fontSize: '12px',
          // color: "#555", // Cor padrão das abas inativas
          // flexGrow: 1,
          paddingTop: "0 !Important",
          // "&.Mui-selected": {
          //   // color: 'white', // Força a cor da aba ativa
          //   color: COLORS.primaryColor
          // },
          // "&:hover": {
          //   color: COLORS.primaryColor, // Cor ao passar o mouse
          // },
        },
      },
      defaultProps: {
        disableRipple: true, // Evita efeitos que possam interferir na personalização
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "36px", // Ajusta a altura do TextField
          "& .MuiInputBase-input": {
            padding: "10px 14px", // Ajusta o espaçamento interno do input
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          padding: "0",
          // fontSize: "1.1rem", // Tamanho da fonte do label
          // fontWeight: "bold", // Negrito opcional
          // transform: "translate(14px, 12px) scale(1)", // Ajusta a posição inicial
          // color: "#333", // Cor opcional
          
        },
        shrink: {
          // transform: "translate(11px, -10px) scale(0.75)", // Ajusta quando o label sobe
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: 34, // Define a altura do botão
          minHeight: 34, // Garante altura mínima
          padding: "8px 16px", // Ajuste interno
        },
      },
      variants: [
        {
          props: { size: "small" }, // 🔹 Aplica apenas para botões `small`
          style: {
            minWidth: "40px", // Largura mínima menor
            height: "18px", // 🔹 Define a altura corretamente
            minHeight: "16px", // 🔹 Força altura mínima (evita substituição pelo MUI)
            fontSize: "10px", // Fonte menor
            padding: "4px 8px", // Ajuste interno
            lineHeight: "normal", // 🔹 Evita que o texto aumente o tamanho do botão
            
          },
          
        },
        {
          props: { size: "medium"}, // 🔹 Aplica apenas para botões `small`
          style: {
            minWidth: "60px", // Largura mínima menor
            height: "26px", // 🔹 Define a altura corretamente
            minHeight: "16px", // 🔹 Força altura mínima (evita substituição pelo MUI)
            fontSize: "12px", // Fonte menor
            padding: "6px 10px", // Ajuste interno
            lineHeight: "normal", // 🔹 Evita que o texto aumente o tamanho do botão
          },
        },
        {
          props: { size: "large" }, // 🔹 Aplica apenas para botões `small`
          style: {
            minWidth: "60px", // Largura mínima menor
            height: "48px", // 🔹 Define a altura corretamente
            minHeight: "16px", // 🔹 Força altura mínima (evita substituição pelo MUI)
            fontSize: "11px", // Fonte menor
            padding: "6px 10px", // Ajuste interno
            lineHeight: "normal", // 🔹 Evita que o texto aumente o tamanho do botão
          },
        },
        {
          props: { variant: "outlined"}, // 🔹 Aplica apenas para botões `small`
          style: {
            fontWeight: "bold",
          },
        },
      ],
    },
  },
});

export default getTheme;
