import { IconButton as MuiIconButton } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

const IconButton = ({ activeIcon, inactiveIcon = null, setActive, active }) => {
  const theme = useTheme();
  return (
    <MuiIconButton 
        onClick={() => setActive(!active)} 
        color={active ? 'primary' : 'default'} 
        size='small'
        sx={{
        backgroundColor: alpha(theme.palette.primary.main, 0.08),
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.16)
        }
        }}
    >
      {active ? activeIcon : inactiveIcon || activeIcon}
    </MuiIconButton>
  );
};

export default IconButton;