import uslTable from './uslTable'

export function getSiteGroupTable (site) {
    switch (site){
        case 'usLins':
            let data = agruparPorGrp(uslTable.uslTable)
            return data
        default:
            return null
    }
}

export async function getSiteTable (site) {
    switch (site){
        case 'usLins':
            return uslTable.uslTable
        default:
            return null
    }
}



function agruparPorGrp(array) {
    const grupos = new Map();
    array.forEach(item => {
        if (!grupos.has(item.grp)) {
            grupos.set(item.grp, []);
        }
        grupos.get(item.grp).push(item);
    });
    return Object.fromEntries(grupos);
}