import React, { useContext, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { getStdPlanoSafra } from '../../Geral/getStdPlanoSafra';
import GlobalStateContext from '../../../../components/GlobalStateContext';
import { stdValue } from '../../../../Resources/setNumber';
import { Circle } from '@mui/icons-material';
import AnalisesContext from '../../../../ANALISES/AnalisesContext';

const PrevXReal = () => {
    const {site} = useContext(GlobalStateContext)
    const {bltProd, bltAna, tempPsData, setTempPsData, bltData} = useContext(AnalisesContext)
    const [groupedData, setGroupedData] = useState(null)
    const stdPlanoVars = getStdPlanoSafra(site)
    const [mes, setMes] = useState(null);
    const meses = [mes, 'real', 'desv', 'planMes', 'realMes', 'desvMes'];
    const monthlyVars = ['psDiasSafra', 'psMoagemTotal', 'psArtEntrado', 'psArtRecuperado', 'psProdUnieqs', 'psProdAcucarTotal', 'psProdAcucarTotalSC',
        'psProdAcucarVHP', 'psProdAcucarVHPSC', 'psProdAcucarCristal', 'psProdAcucarCristalSC', 'psProdTotalEtanol100', 'psProdEtanol100MelTerc',
        'psProdEtanolTotalHid', 'psProdEtanolAnidro', 'psProdEtanolNeutro', 'psProdEtanolDest', 'psProdEtanolTotal', 'psFlowMelTerc', 'psEnergiaGerada',
        'psEnergiaExportada', 'psEnergiaConsumida', 'psProdVapor', 'psProdBagacao', 'psConsBagacao', 'psSobraBagaco', 'psVendaBagaco', 'psProdAcucarDemeraraSC',
        'psVaporTgCond', 'psVaporRebaixado'
    ]

    const diasSafra = () =>{
      try{
        return tempPsData.filter(i => i.codigo === 'psDiasSafra')[0][mes] || 1

      }catch{
        return 1
      }
    }

    const diasMTD = () => {
      try{
        return bltProd.filter(i => i.LINHA === 15)[0].MES || 1
      }catch{
        return 1
      }
    }

    useEffect(() => {
      const loadData = () => {
        if (!tempPsData && site) {
          const savedTempPsData = localStorage.getItem(`tempPsData-${site}`)
          if (savedTempPsData) {
            const parsedData = JSON.parse(savedTempPsData)
            setTempPsData(parsedData)
            const newGroupedData = GetGroupedData(parsedData, stdPlanoVars)
            setGroupedData(newGroupedData)
          }
        } else if (tempPsData) {
          const newGroupedData = GetGroupedData(tempPsData, stdPlanoVars)
          setGroupedData(newGroupedData)
        }
        const months = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
        const mesAbreviado = months[new Date(bltData).getMonth()];
        setMes(mesAbreviado.toLowerCase())
      }

      loadData()

    }, []) // sem dependências, roda apenas uma vez ao montar

    if (!tempPsData) return <h3>Plano Safra não foi importado</h3>
    if (!groupedData) return <h3>Carregando dados...</h3>

    return (
         <TableContainer component={Paper} sx={{ width: "100%", overflowX: "auto", maxHeight: 'calc(89vh - 100px)' }}>
          <Table stickyHeader >
            <TableHead>
              <TableRow>
                <TableCell sx={{  fontWeight: "bold", padding: '1px', fontSize: '12px', paddingLeft: '10px', textAlign: 'left', minWidth: 150  }}>DESCRIÇÃO</TableCell>
                <TableCell sx={{  fontWeight: "bold", padding: '1px' , fontSize: '12px', textAlign: 'left'}}>UNID.</TableCell>
                {meses.map((mes, idx) => (
                  <TableCell key={mes} sx={{ textAlign: "center", fontWeight: "bold" , padding: '1px', fontSize: '12px' }}>
                    {idx === 0 && 'PLANO DIA'}
                    {idx === 1 && 'REAL DIA'}
                    {idx === 2 && 'DESVIO DIA'}
                    {idx === 3 && 'PLANO MÊS'}
                    {idx === 4 && 'REAL MÊS'}
                    {idx === 5 && 'DESVIO MÊS'}
                  </TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(groupedData)
              .filter(([secao, itens]) => secao !== 'settings')
              .map(([secao, itens]) => (
                <React.Fragment key={secao}>
                  {/* Cabeçalho da seção */}
                  <TableRow>
                    <TableCell colSpan={17} sx={{ backgroundColor: "#f0f0f0", fontWeight: "bold", padding: '1px', paddingLeft: '10px', fontSize: '12px', position: 'sticky', top: '26px' }}>
                      {secao}
                    </TableCell>
                  </TableRow>
                  {/* Dados da seção */}
                  {itens.map((item, index) => {
                    return(
                    <TableRow key={`${secao}-${index}`}>
                      <TableCell sx={{ padding: '1px', fontSize: '12px', textAlign: 'left', paddingLeft: '10px', }}>{item.descricao}</TableCell>
                      <TableCell  sx={{ padding: '1px', fontSize: '12px' , textAlign: 'left' }}>{item.unidade}</TableCell>
                      {meses.map((m, idx) => {
                        return (
                        <TableCell key={`${secao}-${index}-${m}`} sx={{  textAlign: "center", minWidth: 55, padding: '1px', fontSize: '11px', 
                        backgroundColor: `${idx < 3 ? '#f7f7f7' : 'white'}` }}>
                          {idx === 0 && (monthlyVars.includes(item.codigo) ? stdValue(item[m]/diasSafra()) : stdValue(item[m]) ?? 0)}
                          {idx === 1 && getRealValues(item.codigo, bltProd, bltAna, 'DIA')}
                          {idx === 2 && <Desvio realValue={getRealValues(item.codigo, bltProd, bltAna, 'DIA')} 
                          planValue={(monthlyVars.includes(item.codigo) ? item[mes]/diasSafra() : item[mes])}
                          cod={item.codigo}/>}
                          {idx === 3 && (monthlyVars.includes(item.codigo) ? stdValue(item[mes]*diasMTD()/diasSafra()) : stdValue(item[mes]) ?? 0)}
                          {idx === 4 && getRealValues(item.codigo, bltProd, bltAna, 'MES')}
                          {idx === 5 && <Desvio realValue={getRealValues(item.codigo, bltProd, bltAna, 'MES')} 
                          planValue={(monthlyVars.includes(item.codigo) ? item[mes]*diasMTD()/diasSafra() : item[mes])}
                          cod={item.codigo}/>}
                        </TableCell>
                      )})}
                     
                    </TableRow>
                  )})}
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    );
};

export default PrevXReal;


function GetGroupedData(psData, stdPlanoVars) {
  let dontShowList = ['psEstoqueInicialBag', 'psEstoqueFinalBag', 'psProdUnieqs', 'psProdUnieqsTc', 'psProdAcucarTotal', 'psProdAcucarDemerara', 
    'psMixProdAcucarDemerara', 'psMixProdAcucarVHP', 'psProdAcucarVHP', 'psProdAcucarCristal', 'psTaxaEfProdAcucar',
   'psProdEfEtanol100', 'psProdEfEtanol100MelTerc', 'psProdEfEtanolHidpTq', 'psProdEfEtanolAnidro', 'psProdEfEtanolNeutro', 'psProdEfEtanolDest',
  'psFlowEfMelTerc', 'psPtGerEnergia', 'psPtExportada', 'psPtConsumidaInd', 'psUpTimeVapor', 'psProdEfVapor', 'psConsVaporTc', 'psProdEfBagacao',
  'psConsEfBagacao', 'psSobraBagaco', 'psAjusteEstoqueBag', 'psProdVinhacapEtanol'
]
    if (psData){
        const groupedItems = stdPlanoVars.reduce((acc, item) => {
          if (item.grp !== 'DIAS NÃO EFETIVOS'){
            if (!acc[item.grp]) {
                acc[item.grp] = [];
            }
            let psItem = psData.filter(row => row.codigo === item.cod)
  
            if (item.show === false || dontShowList.includes(item.cod)){
            }else if(psItem.length > 0){
                psItem[0].descricao = item.desc
                psItem[0].unidade = item.unid
                acc[item.grp].push(psItem[0])
            }else{
                let rev = psData[0].revisao
                let safra = psData[0].safra
                let ssr = psData[0].ssr
                let siteId = psData[0].siteId
  
                let blankItem = {abr: 0, acu: 0, ago: 0, dez: 0, fev: 0, jan: 0, jul: 0, jun: 0, mai: 0, mar: 0, nov: 0, out: 0, set: 0,
                ssr: ssr, input: item.ipt ? item.ipt : null, safra: safra, secao: item.grp, codigo: item.cod, siteId: siteId, revisao: rev,
                unidade: item.unid, descricao: item.desc}
                acc[item.grp].push(blankItem)
            }
          }
          return acc;
      }, {});
    //   console.log('group', groupedItems)
        return groupedItems
    }
    return null    
} 

const execptVars = ['psMixEtanol', 'psCanaEtanol', 'psPtConsumidaTC', 'psEnergiaConsumida', 'psConsVaporTcTotal', 'psVaporRebaixado']

function getDesvio(realValue, planValue, cod){
  try{
    if (isNaN(parseFloat(realValue)) || isNaN(parseFloat(planValue))){
      return null
    }
    let desv = (parseNumber(realValue) - planValue)
    const percent = () => {
      const real = parseNumber(realValue);
    
      if (planValue === 0 && real > 0) {
        return 100;
      } else if (planValue > 0 && real === 0) {
        return -100;
      } else if (planValue === 0 && real === 0) {
        return 0;
      } else if (planValue !== 0) {
        return ((real - planValue) * 100) / planValue;
      } else {
        return 0; // Caso inesperado, retorna 0 como fallback
      }
    };
    let status = desv > 0 ? 'green' : 'red'
    if (desv === 0){
      status = "gray"
    }
    if (execptVars.includes(cod)){
        status = desv > 0 ? 'red' : 'green'
    }
    return {valor: stdValue(desv), percent: stdValue(percent()), status: status}
  }catch{
    return null
  }
}



const Desvio = ({realValue, planValue, cod}) => {
  let desv = getDesvio(realValue, planValue, cod)
  if (!desv){
    return (<div>-</div>)
  }else{
    return(
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{width: '60%', textAlign: 'right'}}>{`${desv.valor} (${desv.percent}%)`}</div>
        <div style={{width: '40%'}}>
          <SemaforoKPI status={desv.status}/>
        </div>
        
      </div>
    )
  }
  
}

function parseNumber(str) {
  // Verifica se há vírgula na string (indica que vírgula é separador decimal)
  if (str.includes(",")) {
      // Remove os pontos (separadores de milhares) e troca vírgula por ponto (decimal)
      return parseFloat(str.replace(/\./g, "").replace(",", "."));
  } else {
      // Apenas remove os pontos (separadores de milhares)
      return parseFloat(str.replace(/\./g, ""));
  }
}


function getRealValues(cod, bltProd, bltAna, dm){
  try{
    switch (cod){
      case 'psAtrPcts': return stdValue(bltAna.filter(i => i.LINHA === 1024)[0][dm]) || '-'
      case 'psAtrDigestor': return stdValue(bltAna.filter(i => i.LINHA === 1022)[0][dm]) || '-'
      case 'psFibraCana': return stdValue(bltProd.filter(i => i.LINHA === 6010)[0][dm]) || '-'
      // case 'psFibraBagaco': return bltAna.filter(i => i.LINHA === 1024)[0][dm] || '-'
      // case 'psBagacoCana': return bltAna.filter(i => i.LINHA === 1024)[0][dm] || '-'
      case 'psUptimeGeral': return  stdValue(bltProd.filter(i => i.LINHA === 755)[0][dm]) || '-'
      case 'psDiasSafra': return stdValue(bltProd.filter(i => i.LINHA === 15)[0][dm]) || '-'
      case 'psEficExtracao': return stdValue(bltProd.filter(i => i.LINHA === 5545)[0][dm]) || '-'
      case 'psTaxaMoagem': return stdValue(bltProd.filter(i => i.LINHA === 760)[0][dm]) || '-'
      case 'psTaxaArt': return stdValue(bltProd.filter(i => i.LINHA === 760)[0][dm]*bltAna.filter(i => i.LINHA === 1022)[0][dm]/100) || '-'
      case 'psTaxaAtr': return stdValue(bltProd.filter(i => i.LINHA === 760)[0][dm]*bltAna.filter(i => i.LINHA === 1024)[0][dm]/1000) || '-'
      case 'psTaxaFibra': return stdValue(bltProd.filter(i => i.LINHA === 760)[0][dm]*bltProd.filter(i => i.LINHA === 6010)[0][dm]/100) || '-'
      case 'psMoagemTotal': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      case 'psMoagemDiaria': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]/bltProd.filter(i => i.LINHA === 15)[0][dm]) || '-'
      case 'psMoagemDiariaEf': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]/bltProd.filter(i => i.LINHA === 15)[0][dm]/(bltProd.filter(i => i.LINHA === 755)[0][dm]/100)) || '-'
      // case 'psMixAcucar': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      // case 'psMixEtanol': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      case 'psCanaFabrica': return stdValue(bltProd.filter(i => i.LINHA === 710)[0][dm]*100/bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      case 'psCanaEtanol': return stdValue(bltProd.filter(i => i.LINHA === 715)[0][dm]*100/bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      case 'psArtEntrado': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]*bltAna.filter(i => i.LINHA === 1022)[0][dm]/100) || '-'
      // case 'psArtRecuperado': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      // case 'psEficienciaIndustrial': return stdValue(bltProd.filter(i => i.LINHA === 705)[0][dm]) || '-'
      case 'psRTC': return stdValue(bltProd.filter(i => i.LINHA === 5580)[0][dm]) || '-'
      case 'psRecSJM': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaLavavem': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaBagaco': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaTorta': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaMultijato': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaFermentacao': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaResiduaria': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psPerdaIndeterminada': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      case 'psProdAcucarTotalSC': return stdValue(bltProd.filter(i => i.LINHA === 3510)[0][dm] + bltProd.filter(i => i.LINHA === 4010)[0][dm] + bltProd.filter(i => i.LINHA === 4510)[0][dm]) || '-'
      // case 'psProdAcucarTotal': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psMixProdAcucarVHP': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      // case 'psProdAcucarVHP': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      case 'psProdAcucarVHPSC': return stdValue(bltProd.filter(i => i.LINHA === 3510)[0][dm]) || '-'
      // case 'psProdAcucarCristal': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      case 'psProdAcucarCristalSC': return stdValue(bltProd.filter(i => i.LINHA === 4510)[0][dm]) || '-'
      case 'psProdAcucarDemeraraSC': return stdValue(bltProd.filter(i => i.LINHA === 4010)[0][dm]) || '-'
      // case 'psTaxaEfProdAcucar': return stdValue(bltProd.filter(i => i.LINHA === 5585)[0][dm]) || '-'
      case 'psTaxaEfProdAcucarScs': return stdValue((gV(3510, bltProd, dm) + gV(4010, bltProd, dm) + gV(4510, bltProd, dm))/gV(15, bltProd, dm)/(gV(755, bltProd, dm)/100)) || '-'
      case 'psProdTotalEtanol100': return stdValue(gV(5525, bltProd, dm)/1000) || '-'
      case 'psProdEtanol100MelTerc': return stdValue(gV(5530, bltProd, dm)/1000) || '-'
      case 'psProdEtanolTotalHid': return stdValue(gV(2015, bltProd, dm)/1000) || '-'
      case 'psProdEtanolAnidro': return stdValue(gV(1010, bltProd, dm)/1000) || '-'
      case 'psProdEtanolNeutro': return stdValue(gV(3020, bltProd, dm)/1000) || '-'
      case 'psProdEtanolDest': return stdValue(gV(1515, bltProd, dm)/1000) || '-'
      case 'psProdEtanolTotal': return stdValue(gV(5510, bltProd, dm)/1000) || '-'
      case 'psRGD': return stdValue(gV(5555, bltProd, dm)*gV(5560, bltProd, dm)/100) || '-'
      case 'psFlowMelTerc': return stdValue(gV(6535, bltProd, dm)) || '-'
      case 'psEnergiaGerada': return stdValue(gV(8530, bltProd, dm)/1000) || '-'
      case 'psEnergiaExportada': return stdValue(gV(8560, bltProd, dm)/1000) || '-'
      case 'psEnergiaConsumida': return stdValue(gV(8570, bltProd, dm)/1000) || '-'
      case 'psPtExportadaTC': return stdValue(gV(8560, bltProd, dm)/gV(705, bltProd, dm)) || '-'
      case 'psPtConsumidaTC': return stdValue(gV(8570, bltProd, dm)/gV(705, bltProd, dm)) || '-'
      case 'psProdVapor': return stdValue(gV(9510, bltProd, dm)+gV(9520, bltProd, dm)) || '-'
      case 'psConsVaporTcTotal': return stdValue(gV(9580, bltProd, dm)) || '-'
      case 'psVaporTgCond': return stdValue(gV(9530, bltProd, dm)) || '-'
      case 'psVaporRebaixado': return stdValue(gV(9560, bltProd, dm)) || '-'
      case 'psVendaBagaco': return stdValue(gV(7020, bltProd, dm)) || '-'
      case 'psProdTortaTc': return stdValue(gV(7100, bltProd, dm)) || '-'
      default: return '-'
    }
  }catch{
    return '-'
  }
}

function gV(l, blt, dm){
  try{
    let valor = blt.filter(i => i.LINHA === l)[0][dm]
    return !valor ? 0 : valor
  }catch{
    return 0
  }

}

const SemaforoKPI = ({ status }) => {
  return <Circle style={{ color: status, fontSize: 16}} />;
};
