let uslTable = [
    {grp: 'MATÉRIA PRIMA', bltCod: 'FIBCNDG', bmeCod: 'fibraCana', psCod: 'psFibraCana', ipt: true},
    {grp: 'MATÉRIA PRIMA', bltCod: 'BXCNDG', bmeCod: 'brixCana'},
    {grp: 'MATÉRIA PRIMA', bltCod: 'POLCNDG', bmeCod: 'polCana'},
    {grp: 'MATÉRIA PRIMA', bltCod: 'PURCNDG', bmeCod: 'purezaCana', ipt: true},
    {grp: 'MATÉRIA PRIMA', bltCod: 'ARTDIG', bmeCod: 'artCana', psCod: 'psAtrDigestor', ipt: true},
    {grp: 'MATÉRIA PRIMA', bltCod: 'ARDIG', bmeCod: 'arCana', ipt: true},
    {grp: 'MATÉRIA PRIMA', bltCod: 'ATRCANA', bmeCod: 'atrCanaPCTS', psCod: 'psAtrPcts', show: false},

    {grp: 'UPTIME', name: 'Dias Safra', unid: 'dias', bltCod: 'DIASAFR', bmeCod: '', psCod: 'psDiasSafra'},
    {grp: 'UPTIME', name: 'Uptime Geral', unid: '%', bltCod: 'EFICIMT', bmeCod: 'uptimeGeral', psCod: 'psUptimeGeral'},
    // {grp: 'UPTIME', bltCod: '', bmeCod: 'uptimeVapor', psCod: 'psUpTimeVapor'},

    // {grp: 'MOAGEM', name: 'Cana Moída Moenda A', bltCod: '', bmeCod: '', show: false},
    // {grp: 'MOAGEM', name: 'Moagem Horária Moenda A', bltCod: '', bmeCod: 'flowCanaM1', show: false},
    // {grp: 'MOAGEM', name: 'Cana Moída Moenda B', bltCod: '', bmeCod: '', show: false},
    // {grp: 'MOAGEM', name: 'Moagem Horária Moenda B', rbltCod: '', bmeCod: '', show: false},

    {grp: 'MOAGEM', bltCod: 'KGMOIMT/1000', bmeCod: 'flowCanaDia', psCod: 'psMoagemTotal', ipt: true},
    {grp: 'MOAGEM', bltCod: 'KMOIHMT/1000', bmeCod: 'flowCana', psCod: 'psTaxaMoagem', ipt: true},
    {grp: 'MOAGEM', bltCod: 'KGMOIMT/KMOIHMT', bmeCod: 'horasEfetivas', ipt: true},
    {grp: 'MOAGEM', bltCod: 'EM%FIMT', bmeCod: 'fibraEmbeb', psCod: '', ipt: true},
    {grp: 'MOAGEM', bltCod: 'EM%CNMT', bmeCod: 'embCana', psCod: '', ipt: true},
    {grp: 'MOAGEM', bltCod: '100-PEREXTR', bmeCod: 'efExtMoenda', psCod: 'psEficExtracao', ipt: true},

    {grp: 'MIX / RENDIMENTOS', bltCod: '%ARTACU', bmeCod: 'mixAcucar', psCod: 'psMixAcucar'},
    
    
    // {grp: 'MIX / RENDIMENTOS', name: 'Kg Açúcar p/ Ton Cana', bltCod: '', bmeCod: '', show: false},
    // {grp: 'MIX / RENDIMENTOS', name: 'Lt Etanol p/ Ton Cana', bltCod: '', bmeCod: '', show: false},
    // {grp: 'MIX / RENDIMENTOS', name: 'Rendimento Fermentação p/ Subp.', rbltCod: 'RFERSUB', bmeCod: '', show: false},
    {grp: 'MIX / RENDIMENTOS', name: 'Rendimento Fermentação', bltCod: 'RENDFER', bmeCod: 'efFermentacao', ipt: true},
    {grp: 'MIX / RENDIMENTOS', name: 'Rendimento Destilação', bltCod: 'RENDEST', bmeCod: 'efDestilaria'},
    {grp: 'MIX / RENDIMENTOS', name: 'Rendimento Geral Destilaria', bltCod: 'REGEDES', bmeCod: 'rgd', psCod: 'psRGD'},
    {grp: 'MIX / RENDIMENTOS', name: 'ART Recuperado Total', bltCod: 'RECUPED', bmeCod: 'efIndustrial', psCod: 'psEficienciaIndustrial'},
    {grp: 'MIX / RENDIMENTOS', name: 'Global Digestor', bltCod: 'GLODIGED', bmeCod: '', show: false},
    {grp: 'MIX / RENDIMENTOS', name: 'Global Prensa', bltCod: 'GLOPREND', bmeCod: '', show: false},
    {grp: 'MIX / RENDIMENTOS', name: 'RTC', bltCod: 'RTC', bmeCod: 'rtc', psCod: 'psRTC'},	
    
    {grp: 'PERDAS', name: 'Total', bltCod: 'PERDTOT', bmeCod: 'perdaTotalArt', psCod: ''},
    {grp: 'PERDAS', name: 'Água Res Moendas', bltCod: 'PERDALC', bmeCod: 'perdaArtLavagemCana', psCod: 'psPerdaLavavem', ipt: true},
    {grp: 'PERDAS', name: 'Extração', bltCod: 'PEREXTR', bmeCod: 'perdaArtExtracao', psCod: 'psPerdaBagaco'},
    {grp: 'PERDAS', name: 'Torta de Filtro', bltCod: '%ARTOR', bmeCod: 'perdaArtTorta', psCod: 'psPerdaTorta', ipt: true},
    {grp: 'PERDAS', name: 'Saída Evaporação', bltCod: 'PERSAEV', bmeCod: 'perdaArtSaidaEvap', psCod: 'psPerdaArtSaidaEvap', ipt: true},
    {grp: 'PERDAS', name: 'Saída Vácuo', bltCod: 'PERSAVA', bmeCod: 'perdaArtSaidaVacuo', psCod: 'psPerdaArtSaidaVacuo', ipt: true},
    {grp: 'PERDAS', name: 'Multijatos Filtros', bltCod: 'PERDAMJ', bmeCod: 'perdaArtMultijatoFabrica', psCod: 'psPerdaMultijato', ipt: true},
    {grp: 'PERDAS', name: 'Água Residual', bltCod: 'PERARES', bmeCod: 'perdaArtAguaResiduaria', psCod: 'psPerdaResiduaria', ipt: true},
    {grp: 'PERDAS', name: 'ART da Destilaria', bltCod: 'ARTDEST', bmeCod: 'perdaFermDest', psCod: 'psPerdaFermentacao', ipt: true},
    {grp: 'PERDAS', name: 'Indeterminadas', bltCod: 'PERINDE', bmeCod: 'perdaArtIndeterminda', psCod: 'psPerdaIndeterminada', ipt: true},
    

    
    {grp: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar 100%', bltCod: 'ACU100%', bmeCod: '', filter: false},
    {grp: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Prod. Total', bltCod: 'PROTOAC', bmeCod: 'flowAcucarScsdia', psCod: 'psTaxaEfProdAcucarScs', filter: false},
    // {grp: 'PRODUÇÃO AÇÚCAR', name: 'Açúcar Prod. + ΔProcesso', row: 0, col: 0, bmeCod: '', psCod: 'psTaxaEfProdAcucarScs'},
    
    {grp: 'PRODUÇÃO AÇÚCAR', name: 'Sc.Ton.Moida', bltCod: 'SCAC/TC', bmeCod: 'flowAcucarScsTc', filter: false},

    {grp: 'PRODUÇÃO ETANOL', name: 'Produção Etanol 100%', bltCod: '', bmeCod: 'flowVolEtanolProd100Dia', psCod:'psProdEfEtanol100', filter: false},
    {grp: 'PRODUÇÃO ETANOL', name: 'Produção Anidro', bltCod: 'ANPRD/1000', bmeCod: 'flowEtanolAnidroDia', psCod:'psProdEfEtanolAnidro', ipt: true, filter: false},
    {grp: 'PRODUÇÃO ETANOL', name: 'Produção Hidratado', bltCod: 'PROETHD/1000', bmeCod: 'flowEtanolHidDia', psCod:'psProdEfEtanolHid', filter: false},
    {grp: 'PRODUÇÃO ETANOL', name: 'Produção Total Etanol', bltCod: '(ANPRD+PROETHD)/1000', bmeCod: 'flowEtanolHidAnidro', psCod:'psProdEtanolTotal', filter: false},
    // {grp: 'PRODUÇÃO ETANOL', name: 'Prod. Total Etanol + ΔProcesso', bltCod: '', bmeCod: '', psCod: 'psProdEtanolTotal'},
    // {grp: 'PRODUÇÃO ETANOL', name: 'Etanol no Processo', bltCod: '', bmeCod: ''},

    {grp: 'TRATAMENTO DO CALDO', name: 'Brix Caldo Filtrado', bltCod: 'BXCLFIL', bmeCod: 'brixCaldoFiltrado'},
    {grp: 'TRATAMENTO DO CALDO', name: 'Brix Caldo Açúcar', bltCod: 'BX%CMF', bmeCod: 'brixCaldoAcucar', filter: false},
    {grp: 'TRATAMENTO DO CALDO', name: 'Brix Caldo Claro Açúcar', bltCod: 'BX%CDF', bmeCod: 'brixCaldoClarifAcucar', filter: false},
    {grp: 'TRATAMENTO DO CALDO', name: 'Brix Caldo Etanol', bltCod: 'BX%CMD', bmeCod: 'brixCaldoEtanol'},
    {grp: 'TRATAMENTO DO CALDO', name: 'Brix Caldo Claro Etanol', bltCod: 'BX%CDD', bmeCod: 'brixCaldoClarifEtanol'},

    // {grp: 'TRATAMENTO DO CALDO', name: 'Art Caldo Filtrado', bltCod: 'ARTCMF', bmeCod: 'artCaldoFiltrado', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Art Caldo Açúcar', bltCod: 'ARTCMF', bmeCod: 'artCaldoAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Art Caldo Claro Açúcar', bltCod: '', bmeCod: 'artCaldoClarifAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Art Caldo Etanol', bltCod: '"ARTCMD', bmeCod: 'artCaldoEtanol', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Art Caldo Claro Etanol', bltCod: 'ARTCDD', bmeCod: 'artCaldoClarifEtanol', show: false},

    // {grp: 'TRATAMENTO DO CALDO', name: 'Pol Caldo Filtrado', bltCod: 'POLCLFIL', bmeCod: 'polCaldoFiltrado', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pol Caldo Açúcar', bltCod: 'POLCMF', bmeCod: 'polCaldoAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pol Caldo Claro Açúcar', bltCod: '', bmeCod: 'polCaldoClarifAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pol Caldo Etanol', bltCod: 'POLCMD', bmeCod: 'polCaldoEtanol', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pol Caldo Claro Etanol', bltCod: 'POLCDD', bmeCod: 'polCaldoClarifEtanol', show: false},

    // {grp: 'TRATAMENTO DO CALDO', name: 'Pureza Caldo Filtrado', bltCod: 'PURCMF', bmeCod: 'purezaCaldoFiltrado', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pureza Caldo Açúcar', bltCod: 'PURCMF', bmeCod: 'purezaCaldoAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pureza Caldo Claro Açúcar', bltCod: '', bmeCod: 'purezaCaldoClarifAcucar', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pureza Caldo Etanol', bltCod: 'PURCMD', bmeCod: 'purezaCaldoEtanol', show: false},
    // {grp: 'TRATAMENTO DO CALDO', name: 'Pureza Caldo Claro Etanol', bltCod: 'PURCDD', bmeCod: 'purezaCaldoClarifEtanol', show: false},



    
    // {grp: 'PROCESSO AÇÚCAR', name: 'Xarope Fabrica', row: 225, col: 5, bmeCod: 'flowXaropeToFab'},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Xarope Destilaria', row: 226, col: 5, bmeCod: 'flowXaropeToFerm'},
    {grp: 'PROCESSO AÇÚCAR', name: 'Açúcar Processo', bltCod: 'ACPROCE', bmeCod: '' , filter: false},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Xarope', bltCod: 'BX%XAR', bmeCod: 'brixXarope', ipt: true},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Brix Xarope Flotado', bltCod: 'BXFLXAR', bmeCod: 'brixXaropeFlotado', show: false, filter: false},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Massa A', bltCod: 'BX%MSA', bmeCod: 'brixMassaA', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Massa B', bltCod: 'BX%MSB', bmeCod: 'brixMassaB', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Magma', bltCod: 'BX%MAG', bmeCod: 'brixMagma', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Mel Rico', bltCod: 'BX%MRIC', bmeCod: 'brixMelARico', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Mel Pobre', bltCod: 'BX%MPOB', bmeCod: 'brixMelAPobre', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Brix Mel Final', bltCod: 'BX%MFIN', bmeCod: 'brixMelFinal', ipt: true},
    
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Xarope', bltCod: 'PURXAR', bmeCod: 'purezaXarope', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Massa A', bltCod: 'PURMSA', bmeCod: 'purezaMassaA', filter: false},	
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Massa B', bltCod: 'PURMSB', bmeCod: 'purezaMassaB', filter: false},
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Magma', bltCod: '', bmeCod: 'purezaMagma', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Rico', bltCod: 'PURMRIC', bmeCod: 'purezaMelARico', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Pobre', bltCod: 'PURMPOB', bmeCod: 'purezaMelAPobre', ipt: true},
    {grp: 'PROCESSO AÇÚCAR', name: 'Pureza Mel Final', bltCod: 'PURMFIN', bmeCod: 'purezaMelFinal', ipt: true},
    
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Xarope', bltCod: 'POLXAR', bmeCod: '', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Massa A', bltCod: 'POLMSA', bmeCod: 'polMassaA', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Massa B', bltCod: 'POLMSB', bmeCod: 'polMassaB', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Magma', bltCod: 'POLMAG', bmeCod: 'polMagma', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Mel Rico', bltCod: 'POLMRIC', bmeCod: 'polMelARico', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Mel Pobre', bltCod: 'POLMPOB', bmeCod: 'polMAPDiluido', show: false},
    // {grp: 'PROCESSO AÇÚCAR', name: 'Pol Mel Final', bltCod: 'POLMFIN', bmeCod: 'polMelFinal', show: false},

    {grp: 'MEL', name: 'Mel Produzido', bltCod: 'KMELPRO/1000', bmeCod: '', filter: false},
    {grp: 'MEL', name: 'Mel Consumido', bltCod: 'KMELCON/1000', bmeCod: '', filter: false},
    {grp: 'MEL', name: 'Mel Estoque p/ Produção', bltCod: '(KMELCON-KMELPRO)/1000/(KGMOIMT/KMOIHMT)', bmeCod: 'flowMelEstoque', ipt: true},
    {grp: 'MEL', name: 'Mel Produção p/ Estoque', bltCod: '(KMELPRO-KMELCON)/1000/(KGMOIMT/KMOIHMT)', bmeCod: 'flowMelpEstoque', ipt: true},
    
    // {grp: 'PROCESSO ETANOL', name: 'Etanol Processo', bltCod: '', bmeCod: '' , filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Brix Mosto', bltCod: 'BXMOSTO', bmeCod: 'brixMosto', ipt: true},
    // {grp: 'PROCESSO ETANOL', name: 'Pol Mosto', bltCod: 'POLMOST', bmeCod: 'polMosto', show: false},
    // {grp: 'PROCESSO ETANOL', name: 'Pureza Mosto', bltCod: 'PURMOST', bmeCod: 'purezaMosto', ipt: true, show: false},

    {grp: 'PROCESSO ETANOL', name: 'Mel % Mosto', bltCod: '%MELMOS', bmeCod: '', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Cel. Vinho Bruto', bltCod: '%LEVVIN', bmeCod: 'concCelVinhoBruto', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Etanol % Vinho Bruto', bltCod: '%ETVINH', bmeCod: 'concEtanolVinhoBruto', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Cel. Lev. Tratado', bltCod: '%LEVCUB', bmeCod: 'concCelFermTratato', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Etanol Lev. Tratado', bltCod: '%ETCUBA', bmeCod: 'concEtanolFermTratado', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Cel. Lev. Leite', bltCod: '%LECRCE', bmeCod: 'concCelCreme', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'Conc. Cel. Lev. Vinho Cent.', bltCod: '%LEVICE', bmeCod: 'concCelVinhoCent', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'GL Hidratado', bltCod: 'ET100HI', bmeCod: 'glHidratado', filter: false},
    {grp: 'PROCESSO ETANOL', name: 'GL Anidro', bltCod: 'ET100AN', bmeCod: 'glAnidro', filter: false},
    
    
    {grp: 'TEMPERATURAS', name: 'Temp. Água Embebição', bltCod: 'TEMAEMB', bmeCod: 'tempEmbeb', filter: false},
    {grp: 'TEMPERATURAS', name: 'Temp. Caldo Aquecido Etanol', bltCod: 'TADESME', bmeCod: 'tempCaldoAquecEtanol', filter: false},
    {grp: 'TEMPERATURAS', name: 'Temp. Caldo Aquecido Açúcar', bltCod: 'TAFABME', bmeCod: 'tempCaldoAquecAcucar', filter: false},
    // {grp: 'TEMPERATURAS', name: 'Temp. Caldo Decantado Etanol', bltCod: 'TEDESE', bmeCod: 'tempCaldoDecEtanol'},
    {grp: 'TEMPERATURAS', name: 'Temp. Caldo Decantado Açúcar', bltCod: 'TSDEDES', bmeCod: 'tempCaldoDecAcucar', filter: false},
    {grp: 'TEMPERATURAS', name: 'Temperatura Mosto', bltCod: 'TEMMOST', bmeCod: 'tempMostoResf', filter: false},

    {grp: 'VAPOR E ENERGIA', name: 'Disponibilidade Caldeiras', unid: 'hrs', bltCod: 'HECAMED', bmeCod: ''},
    {grp: 'VAPOR E ENERGIA', name: 'Consumo Vapor', bltCod: 'TVAPCAL', bmeCod: 'gerVaporVMCaldeiras', psCod: 'psProdVapor', filter: false},
    {grp: 'VAPOR E ENERGIA', name: 'Geração de Energia', bltCod: 'TOTGER/1000', bmeCod: 'consEnergiaDia' ,psCod: 'psEnergiaConsumida'},
    {grp: 'VAPOR E ENERGIA', name: 'Consumo Esp. Vapor', bltCod: 'TVAPCAL*1000/(KGMOIMT/1000)', bmeCod: 'consVaporTC', psCod: 'psConsVaporTcTotal', filter: false},
    {grp: 'VAPOR E ENERGIA', name: 'Consumo Esp. Energia', bltCod: 'TOTGER*1000/KGMOIMT', bmeCod: 'consEspEnergiaTC', ipt: true},


    // {grp: 'BAGAÇO', name: 'Umidade Bagaço', bltCod: 'UMIBAGA', bmeCod: 'umidBagaco', ipt: true},
    // {grp: 'BAGAÇO', name: 'Brix Bagaço', bltCod: 'BX%BAGA', bmeCod: 'brixBagaco'},
    // {grp: 'BAGAÇO', name: 'Pol % Bagaço', bltCod: 'POLBAGA', bmeCod: 'polBagaco'},
    {grp: 'BAGAÇO', name: 'Fibra % Bagaço', bltCod: 'FIBCNDG*100/BG%CNMT', bmeCod: 'fibraBagaco', psCod: 'psFibraBagaco', ipt: true},
    {grp: 'BAGAÇO', name: 'Bagaço % Cana', bltCod: 'BG%CNMT', bmeCod: 'bagacoCana', psCod: 'psBagacoCana'},
    {grp: 'BAGAÇO', name: 'Bagaço Produzido', bltCod: 'TBAGPRO', bmeCod: 'flowBagaco', psCod: 'psProdBagacao'},
    {grp: 'BAGAÇO', name: 'Bagaço Consumido', bltCod: 'TBAGCON', bmeCod: 'consTotalBagaco', psCod: 'psConsBagacao', filter: false},
    // {grp: 'BAGAÇO', name: 'Bagaço Cru Vendido', row: 81, col: 5, bmeCod: '', psCod: 'psVendaBagaco'},
    // {grp: 'BAGAÇO', name: 'Bagaço p/ Esoque', bltCod: 'SOBRABAG', bmeCod: 'sobraBagaco', psCod: 'psSobraBagaco'},

    {grp: 'TORTA DE FILTRO', name: 'Torta p/Ton.',  bltCod: 'KTOR/T', bmeCod: 'prodTorta', psCod: 'psProdTortaTc', ipt: true},
    {grp: 'TORTA DE FILTRO', name: 'Vazão Água Filtros',  bltCod: 'VAGFIM3', bmeCod: ''},
    // {grp: 'TORTA DE FILTRO', name: 'Pol Torta Filtro', row: 232, col: 0, bmeCod: ''},
    // {grp: 'TORTA DE FILTRO', name: 'Umidade Torta Filtro', row: 236, col: 0, bmeCod: ''},
    // {grp: 'TORTA DE FILTRO', name: 'Torta Produzida', bltCod: '', bmeCod: 'flowTorta'},

    {grp: 'ÁGUAS / VINHAÇA', name: 'Consumo Água/t Can',  bltCod: 'CONA/TC', bmeCod: '', filter: false},
    {grp: 'ÁGUAS / VINHAÇA', name: 'Vol. Água Residual',  bltCod: 'VOLARES/(KGMOIMT/KMOIHMT)', bmeCod: ''},
    {grp: 'ÁGUAS / VINHAÇA', name: 'Volume Vinhaça',  bltCod: 'VLVINHA/(KGMOIMT/KMOIHMT)', bmeCod: ''},
    
    //COMPLEMENTO PLANO SAFRA
    {grp: '', bltCod: 'KMOIHMT/1000*ARTDIG/100', psCod: 'psTaxaArt', show: false},
    {grp: '', bltCod: '', psCod: 'psTaxaAtr', show: false},
    {grp: '', bltCod: 'KMOIHMT/1000*FIBCNDG/100', psCod: 'psTaxaFibra', show: false},
    {grp: '', bltCod: '100-MIXACUC', psCod: 'psMixEtanol', show: false},
    {grp: '', bltCod: 'KGMOIMT/1000*ARTDIG/100', psCod: 'psArtEntrado', show: false},
    {grp: '', bltCod: 'KGMOIMT/1000*ARTDIG/100*RECUPED/100', psCod: 'psArtRecuperado', show: false},
    {grp: '', bltCod: 'KGMOIMT/1000/17', psCod: 'psMoagemDiaria', show: false}, //Dividir pelo diasMTD real
    {grp: '', bltCod: 'KGMOIMT/1000/(KGMOIMT/KMOIHMT/24)', psCod: 'psMoagemDiariaEf', show: false}, //Multipolicar pelos Dias Safra
    {grp: '', bltCod: '', psCod: 'psRecSJM', show: false},
    {grp: '', bltCod: 'PROTOAC', psCod: 'psProdAcucarTotalSC', show: false},

    {grp: '', bltCod: 'PROVHP+PROVVHP', psCod: 'psProdAcucarVHPSC', show: false},
    {grp: '', bltCod: 'PROD150+PROD180+PROD200+PROD300+PROD400', psCod: 'psProdAcucarCristalSC', show: false},
    
    {grp: '',  bltCod: 'ANPRD/1000', psCod:'psProdEtanolAnidro', show: false},
    {grp: '',  bltCod: 'PROETHD/1000', psCod:'psProdEtanolTotalHid', show: false},

    {grp: '',  bltCod: 'KBAGVEN/1000', psCod:'psVendaBagaco', show: false},
]

module.exports = {
    uslTable
}